import React from "react";
import { Col, Row } from "react-bootstrap";
import ShopCollection from '../../components/shop-collection';

const NoPageFound = () => {
    return (
      <section className="no-page-found">
        <div className="container">
          <Row className="justify-content-center ptb-50">
            <Col xl="12" sm="12">
              <h1>Whoops... this page is not available</h1>
              <h5>
                The page you are trying to search has been removed or is not
                available. Kindly navigate back to home.
              </h5>
            </Col>
          </Row>

          {/* SHOP COLLECTION */}
          <ShopCollection />
        </div>
      </section>
    );
};

export default NoPageFound;