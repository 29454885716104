import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from 'react-bootstrap';
import { Provider } from "react-redux";
import { SnackbarProvider } from 'notistack';
import store from '../redux/store'

/* PAGES */
import Layout from '../layout/index';
import Homepage from '../pages/home/new-index';
import Press from "../pages/press";
import Affiliate from "../pages/affiliate";
import Returns from "../pages/returns";
import TermsOfUse from "../pages/terms-of-use";
import PrivacyPolicy from "../pages/privacy-policy";
import ContactUs from "../pages/contact-us";
import NewArrivals from "../pages/new-arrivals";
import ShopProducts from "../pages/shop";
import ShopBrows from '../pages/brow';
import ShopEyes from '../pages/eye';
import ShopLips from '../pages/lips';
import ShopFace from '../pages/face';
import ShopSets from "../pages/sets";
import ShopBestsellers from '../pages/bestsellers';
import NewDiyBrows from "../pages/diy-brows/new-index";
import Account from "../pages/account";
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import AboutUs from "../pages/about-us";
import UserDashboard from '../pages/dashboard';
import Wishlist from "../pages/wishlist";
import ProductDetail from "../pages/product-detail";
import ReturnOrders from "../pages/return-orders";
import ThankYou from '../pages/thank-you';
import BrowQuiz from "../pages/brow-quiz";
import OrderCancelled from '../pages/order-cancelled';
import NoPageFound from "../pages/404";
import OrderDetail from "../pages/orderDetail";
import NewHomepage from "../pages/home/new-index";
import SalonRegistration from "../pages/salon-registration/index";
import BrowKit from "../pages/brow-kit";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const WebsiteRoutes = () => {
    return (
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={1}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <ThemeProvider
            breakpoints={["xl", "lg", "md", "sm"]}
            minBreakpoint="sm"
          >
            <div className="app">
              <Router>
                <ScrollToTop />
                <Layout>
                  <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/new" element={<NewHomepage />} />
                    <Route path="/product-detail" element={<ProductDetail />} />
                    <Route path="/press" element={<Press />} />
                    <Route path="/affiliate-sign-up" element={<Affiliate />} />
                    <Route path="/returns" element={<Returns />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/new-arrivals" element={<NewArrivals />} />
                    <Route path="/shop" element={<ShopProducts />} />
                    <Route path="/products/:id" element={<ShopProducts />} />
                    <Route path="/brow-collection" element={<ShopBrows />} />
                    <Route path="/eye-collection" element={<ShopEyes />} />
                    <Route path="/lips-collection" element={<ShopLips />} />
                    <Route path="/face-collection" element={<ShopFace />} />
                    <Route path="/sets-collection" element={<ShopSets />} />
                    <Route path="/bestsellers" element={<ShopBestsellers />} />
                    <Route path="/diy-brows" element={<NewDiyBrows />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/cart" element={<Cart />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path="/dashboard" element={<UserDashboard />} />
                    <Route path="/wishlist" element={<Wishlist />} />
                    <Route path="/return-orders" element={<ReturnOrders />} />
                    <Route path="/thank-you/:id" element={<ThankYou />} />
                    <Route path="/brow-quiz" element={<BrowQuiz />} />
                    <Route path="/order-cancel" element={<OrderCancelled />} />
                    <Route path="/404" element={<NoPageFound />} />
                    <Route path="*" exact={true} element={<NoPageFound />} />
                    <Route path="/order-detail/:id" element={<OrderDetail />} />
                    <Route path="/sales-rep" element={<SalonRegistration />} />
                    <Route path="/brow-kit" element={<BrowKit />} />
                  </Routes>
                </Layout>
              </Router>
            </div>
          </ThemeProvider>
        </SnackbarProvider>
      </Provider>
    );
};

export default WebsiteRoutes;