import React from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import ShopCollection from "../../components/shop-collection";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <section className="about-us ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>About | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <Row className="align-items-center mt-3 international-artist">
          {/* <h5>REEMA - #1 INTERNATIONAL BROW ARTIST</h5> */}
          <Col lg="6" sm="12" className="video-col">
            <h5>REEMA - #1 INTERNATIONAL BROW ARTIST</h5>
            <video controls>
              <source
                src={require("../../assets/banners/about-us-video.mp4")}
                type="video/mp4"
              />
            </video>
          </Col>
          <Col lg="6" sm="12" className="content">
            <div className="about-content" style={{ paddingLeft: "20px" }}>
              <p>
                Women go to great pains for a perfect brow - brushing,
                plucking, waxing, trimming, cutting, and shaping through blood
                sweat, and tears. All small tortures that can irritate and
                damage women’s natural beauty.
              </p>
              <p>
                Threading – the oldest and most natural art handed down
                through generations of Indian cultures is the gentlest of all.
              </p>
              <p>
                Reema Khan fell in love with the art of threading alongside
                her mother and aunties in Hyderabad, India when she was a
                little girl. When her IT employer went bust in 2001 she turned
                back to her first love and true talent -- Threading. She maxed
                out her credit cards, borrowed $30,000 and opened up a. little
                studio on the south side of Chicago. "I was known as the Brow
                Girl!"
              </p>
              <p>She was all in. And a well-deserved miracle happened.</p>
              <p>
                “The threading word spread quickly, “ Reema says, "People
                realized that shaping their brows gave them an instant eye
                lift!. It opened up their eyes, accentuated their cheekbones.
                Their faces looked completely transformed."
              </p>
              <p>
                By 2018, Reema’s brow threading enterprise had expanded to the
                chain of seventy-five Shapes Brow Bars with 550 employees. She
                now has one million regular customers. Eight spas. A full
                range of beauty products, selling more than 2 million units a
                year. Reema also built and now leads a community of women
                helping women.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-3 second-row">
          <Col lg="6" sm="12" className="order-md-2 video-col">
            <video controls>
              <source
                src={require("../../assets/banners/gma-deals-and-steals.mp4")}
                type="video/mp4"
              />
            </video>
          </Col>
          <Col lg="6" sm="12" className="order-md-1">
            <div className="about-content-2" style={{ paddingRight: "20px" }}>
              <p>
                And Reema loves to create the products that empower women to
                tell their stories.
              </p>
              <p>
                During the pandemic, when all her brow salons were forced to
                closed Reema made it her mission to help clients learn how to
                take their brow shaping into "their own hands" - creating a
                go-to library of online brow tutorials.
              </p>
              <p>
                Famous for her all-in-one brow kits, her signature DIY BROW
                KIT COLLECTION has become a leader in the DIY beauty
                revolution!
              </p>
              <p style={{ fontSize: '22px', fontWeight: '600' }}>
                "Brow jobs shouldn't be intimidating. I'd like everyone to
                learn to be their own arch queen."
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center third-row">
          <Col lg="3" sm="12" className="reema-img">
            <div className="section">
              <img
                src={require("../../assets/banners/about_us_reema.png")}
                alt="About Reema"
              />
            </div>
          </Col>
          <Col lg="9" sm="12">
            <div className="about-content" style={{ paddingLeft: "20px" }}>
              <h4>REEMA - THE ART OF THE BROW</h4>
              <h5>
                Eyes are the windows to your soul," Reema says, " but it's
                your brows that tell the world what you're really thinking.
              </h5>
              <p>
                Reema Khan built an empire on brows -- seventy-five salons,
                one million customers -- in Europe and right here in the USA.
                Reema re-introduced the centuries-old art of threading, a
                craft handed down through generations of her Indian ancestors
                and watched its popularity explode throughout the nation.
              </p>
              <p>
                A flawless brow says it all, and Reema, Queen of Brows, has
                brought her timeless beauty secrets to the heart of Hollywood
                and started a mini-revolution in the world of brow and beauty
                products.
              </p>
              <p>
                Reema knows the power of the brow in the modern world. With
                her 75 salons and 23 years of experience, she has created a
                stunning collection of the most, fool-proof, game-changing
                brow & beauty products in the industry!
              </p>
              <p>
                "Every brow is different," she says. "Every face is unique.
                Our products are designed for all the beautiful, original
                faces that we serve," says. Reema. "No matter what skin color
                you have, no matter what hair color you have I made a product.
                especially for you, to fit your brows. It is to celebrate and
                inspire you.
              </p>
            </div>
          </Col>
        </Row>
        {/* <Row className="align-items-center mt-3">
            <Col lg="4" sm="12" className="order-md-2">
              <video controls>
                <source
                  src={require("../../assets/banners/gma-deals-and-steals.mp4")}
                  type="video/mp4"
                />
              </video>
            </Col>
            <Col lg="8" sm="12" className="order-md-1">
              <div className="about-content-2" style={{ paddingRight: "20px" }}>
                <p>
                  And Reema loves to create the products that empower women to
                  tell their stories.
                </p>
                <p>
                  During the pandemic, when all her brow salons were forced to
                  closed Reema made it her mission to help clients learn how to
                  take their brow shaping into "their own hands" - creating a
                  go-to library of online brow tutorials.
                </p>
                <p>
                  Famous for her all-in-one brow kits, her signature DIY BROW
                  KIT COLLECTION has become a leader in the DIY beauty
                  revolution!
                </p>
                <p>
                  "Brow jobs shouldn't be intimidating. I'd like everyone to
                  learn to be their own arch queen."
                </p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <h5>REEMA - #1 INTERNATIONAL BROW ARTIST</h5>
            <Col lg="4" sm="12">
              <video controls>
                <source
                  src={require("../../assets/banners/about-us-video.mp4")}
                  type="video/mp4"
                />
              </video>
            </Col>
            <Col lg="8" sm="12">
              <div className="about-content" style={{ paddingLeft: "20px" }}>
                <p>
                  Women go to great pains for a perfect brow - brushing,
                  plucking, waxing, trimming, cutting, and shaping through blood
                  sweat, and tears. All small tortures that can irritate and
                  damage women’s natural beauty.
                </p>
                <p>
                  Threading – the oldest and most natural art handed down
                  through generations of Indian cultures is the gentlest of all.
                </p>
                <p>
                  Reema Khan fell in love with the art of threading alongside
                  her mother and aunties in Hyderabad, India when she was a
                  little girl. When her IT employer went bust in 2001 she turned
                  back to her first love and true talent -- Threading. She maxed
                  out her credit cards, borrowed $30,000 and opened up a. little
                  studio on the south side of Chicago. "I was known as the Brow
                  Girl!"
                </p>
                <p>She was all in. And a well-deserved miracle happened.</p>
                <p>
                  “The threading word spread quickly, “ Reema says, "People
                  realized that shaping their brows gave them an instant eye
                  lift!. It opened up their eyes, accentuated their cheekbones.
                  Their faces looked completely transformed."
                </p>
                <p>
                  By 2018, Reema’s brow threading enterprise had expanded to the
                  chain of seventy-five Shapes Brow Bars with 550 employees. She
                  now has one million regular customers. Eight spas. A full
                  range of beauty products, selling more than 2 million units a
                  year. Reema also built and now leads a community of women
                  helping women.
                </p>
              </div>
            </Col>
          </Row> */}
        <Row className="align-items-center mt-3 last-row">
          <Col lg="6" sm="12" className="order-md-2 video-col">
            <video controls>
              <source
                src={require("../../assets/banners/about-us-last-video.mp4")}
                type="video/mp4"
              />
            </video>
          </Col>
          <Col lg="6" sm="12" className="order-md-1">
            <div className='about-content-2' style={{ paddingRight: "20px" }}>
              <p>
                As a woman-owned and operated business, Brow Bar by Reema is a
                great supporter of all women and has trained and employed
                thousands of beauty technicians. Along the way she even fought
                and changed rigid state laws and red tape for her employees to
                earn the right to work in her salons as specifically trained
                beauty technicians in the art of threading. "The. states could
                not deny licenses for something they could not teach."
              </p>
              <p>
                To raise awareness and funds for survivors of domestic
                violence, Reema set a world record by threading 53 pairs of
                eyebrows in one hour May 21, 2016, beating the old record in
                the first 12 minutes, replacing it in the Guinness book of
                world records.
              </p>
              <p>
                Today, Reema’s philanthropy is part of a daily commitment with
                her “Everyone is BeYOUtiful: Women Empowerment Initiative”
                donating up to 10 percent of all Brow Bar by Reema net sales
                to support local and worldwide organizations to assist
                survivors of domestic and emotional violence.
              </p>
              <p>
                "Our charity's aim is to build a community of women helping
                women. I want people to know that when they buy Brow Bar by
                Reema product it has a cause and a purpose, having been
                blessed to do what I love the most!"
              </p>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center explore-tools " >
          <h5>Explore Our Brow Tools</h5>
          <Col lg="6" sm="12" >

            <div className="tools-div" style={{ backgroundImage: 'url(https://storage.googleapis.com/cdn-browbarbyreema/quiz-source/1673687538910.jpg)', backgroundSize: 'cover', height: '375px' }}>


            </div>
            <h5 className="mt-1 fs-4" ><Link to={'/brow-quiz'} style={{textDecoration:'none',color:'black'}}>TAKE THE BROW QUIZ</Link></h5>
            

          </Col>
          <Col lg="6" sm="12" >
            <div className="tools-div" style={{ backgroundImage: 'url(https://wholesale.browbarbyreema.com/static/media/account-benefits.8a2adb02bdd8b8d1df8e.webp)', backgroundSize: 'cover', height: '375px' }}>


            </div>
            <h5 className="mt-1 fs-4"><a href={'https://www.youtube.com/@browbarbyreema'} target={'_blank'} style={{textDecoration:'none',color:'black'}} rel="noreferrer">WATCH THE BROW HOW-TOs</a></h5>
          </Col>
        </Row>

        {/* SHOP COLLECTION */}
        <ShopCollection />
      </div>
    </section>
  );
};

export default AboutUs;