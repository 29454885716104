/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
      <section className="privacy-policy ptb-30">
        <Helmet>
          <title>Privacy Policy | Brow Bar by Reema</title>
        </Helmet>

        <div className="container">
          <h2> PRIVACY POLICY</h2>
          <Row>
            <Col lg="12" sm="12">
              <div className="privacy-policy-content">
                <p>
                  At BROW BAR BY REEMA, we respect your concerns about privacy.
                  Our policies are intended to protect your privacy and ensure
                  that your personal information is handled in a safe and
                  responsible manner. As we continue to develop our website and
                  take advantage of new technologies to improve the services we
                  provide, our policies will continue to evolve. We therefore
                  encourage you to refer to this policy on an ongoing basis to
                  stay abreast of our most current privacy policy practices.
                </p>
                <p>
                  We reserve the right to change this policy, at which time our
                  customers will be notified through our posting online.
                  Information posted on our site may be grouped as aggregate
                  (demographic) or singular (information that pertains to a
                  single individual). We consider personal information to be a
                  customer’s name, mailing address, email address, credit card
                  information or phone number.
                </p>
                <p>
                  The following is considered to be demographic information:
                  gender/gender preferences, age, language, location, income or
                  education. BROW BAR BY REEMA retains the right and option to
                  disclose information for any purpose, but not without the
                  customer’s prior consent. Our privacy policy only covers BROW
                  BAR BY REEMA and does not extend to other websites that link
                  to our SITE or to which BROW BAR BY REEMA may link to.
                </p>
                <h5 className="mt-3">How Collected Information Is Used</h5>
                <p>
                  Customer information may be collected and used in a number of
                  ways while customers visit our SITE. Our goal is to provide
                  our customers with relevant and meaningful user-experiences.
                  We collect information for this purpose as it helps our
                  customers quickly navigate our SITE and find products,
                  information or services. Information gathered can also be used
                  to alert our customers of new services and products being
                  offered.
                </p>
                <h5 className="mt-3">Customer Registration</h5>
                <p>
                  Before ordering products or setting appointments on our SITE,
                  customers are required to register by providing personal
                  information that may include their name, billing/shipping
                  address, credit card information, email address and phone
                  number. We may choose to use personal information for billing,
                  order filling and communicating with customers concerning
                  orders, promotions or customer concerns.
                </p>
                <h5 className="mt-3">Opting Out of Cookies</h5>
                <p>
                  We provide you with choice over the use of cookies as
                  described in this policy.
                </p>
                <p>
                  Please note that our website may not work correctly if you
                  change certain preferences, such as disabling all cookies.
                </p>
                <p>
                  Please also note that after applying these settings, you will
                  continue to see advertising, although it may not be tailored
                  to your likely interests using information collected from
                  cookies and similar technologies on your device.
                </p>
                <p>
                  <b>First Party Cookies:</b> If you’d rather we didn’t use
                  cookies when you visit us, click here for information on how
                  to configure your browser settings to reject cookies. Please
                  note, disabling these types of cookies won’t turn off
                  advertising on our websites and the advertising you see may be
                  less relevant to you.
                </p>
                <p>
                  <b>Third Party Cookies:</b> If you would like to opt-out of
                  third-party cookies, here are some options:
                </p>
                <ul>
                  <li>
                    If you would like to opt-out of receiving personalised ads
                    from third party advertisers and ad networks who are members
                    of the Network Advertising Initiative (NAI), or who follow
                    the Digital Advertising Alliance’s (DAA) Self-Regulatory
                    Principles for Online Behavioral Advertising, you can by
                    visiting the opt-out pages on the{" "}
                    <a
                      href="https://optout.networkadvertising.org/?c=1#!%2F"
                      target="_blank"
                    >
                      NAI website
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://digitaladvertisingalliance.org/"
                      target="_blank"
                    >
                      DAA website
                    </a>{" "}
                    or click here to opt out.
                  </li>
                  <li>
                    Google provides a suite of tools to control its cookies:
                  </li>
                  <ul>
                    <li>
                      Google Analytics, which is a tool used to track and report
                      website traffic, provides an out-out browser add-on that
                      can be downloaded here.
                    </li>
                    <li>
                      Google Safety Centre has a tool where you can control the
                      ads you see on Google and control the data used for
                      serving ads to you. It can be found here.
                    </li>
                  </ul>
                  <li>
                    If you wish to block other cookies, including those being
                    served by companies that are not part of the NAI or the DAA
                    you can use the browser-level cookie controls described
                    below.
                  </li>
                </ul>
                <h5 className="mt-3">Browser-Level Cookie Controls</h5>
                <p>
                  Most browsers allow you to manage cookie settings. These
                  settings can usually be found in the ‘Settings’, ‘Options’ or
                  ‘Preferences’ menu of your browser. The links below are
                  provided to help you find the settings for some common
                  browsers.
                </p>
                <ul>
                  <li>
                    Manage cookie settings in{" "}
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                      target="_blank"
                    >
                      Chrome
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en"
                      target="_blank"
                    >
                      Chrome Android
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&hl=en"
                      target="_blank"
                    >
                      Chrome iOS
                    </a>
                  </li>
                  <li>
                    Manage cookie settings in{" "}
                    <a
                      href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                      target="_blank"
                    >
                      Firefox
                    </a>
                  </li>
                  <li>
                    Manage cookie settings in{" "}
                    <a
                      href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                      target="_blank"
                    >
                      Internet Explorer
                    </a>
                  </li>
                  <li>
                    Manage cookie settings in{" "}
                    <a
                      href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                      target="_blank"
                    >
                      Microsoft Edge
                    </a>
                  </li>
                  <li>
                    Manage cookie settings in{" "}
                    <a
                      href="https://support.apple.com/guide/safari/clear-your-browsing-history-sfri47acf5d6/mac"
                      target="_blank"
                    >
                      Safari
                    </a>
                  </li>
                </ul>
                <h5 className="mt-3">Partnerships</h5>
                <p>
                  We partner with Rakuten Advertising, who may collect personal
                  information when you interact with our site. The collection
                  and use of this information is subject to the privacy policy
                  located here:{" "}
                  <a
                    href="https://rakutenadvertising.com/legal-notices/services-privacy-policy/"
                    target="_blank"
                  >
                    https://rakutenadvertising.com/legal-notices/services-privacy-policy/
                  </a>
                </p>
                <p>
                  You can opt out of it here:{" "}
                  <a href="https://rakutenadvertising.com/legal-notices/services-privacy-rights-request-form/">
                    https://rakutenadvertising.com/legal-notices/services-privacy-rights-request-form/
                  </a>
                </p>
                <h5 className="mt-3">Gathering Personal Information</h5>
                <p>
                  As a person rule, our company does not knowing collect any
                  information from anyone under the age of 18. If under the age
                  of 18, we ask that you please do not submit any information to
                  our SITE.
                </p>
                <h5 className="mt-3">
                  Disclosure Of Customer Information Policy
                </h5>
                <p>
                  We use internal information for such things as improving our
                  site’s usability/architecture, customer outreach and marketing
                  efforts. As such, we do not use customer information to sell,
                  license, rent or trade. Retailers that perform business
                  functions on our behalf may be privy to customer information.
                  We often partner with third-party vendors such as UPS, the
                  U.S. Postal Services and Federal Express. This is to make sure
                  that deliveries are completed on time.
                </p>
                <p>
                  Other times when customer information is shared may be the
                  result of a law enforcement investigation or response to a
                  court order. Your personal information may also be shared to
                  help prevent fraud. However, customer information is not
                  shared on a regular basis.
                </p>
                <h5 className="mt-3">Protecting Customer Information</h5>
                <p>
                  BROW BAR BY REEMA has security safeguards in place to protect
                  a customer’s personal and private information. Access to a
                  customer’s personal information is also limited within our own
                  facilities. Only employees with a true “need-to-know” have
                  access to customer files internally. We do, however, reserve
                  the right to allow third-party service providers to implement
                  security procedures and protocols at our discretion. In the
                  event that any customer’s online personal information is
                  compromised.
                </p>
                <h5 className="mt-3">The Customer’s Right To Opt Out</h5>
                <p>
                  Our customers reserve the right to opt-out. As a result, we’ll
                  stop sending emails, correcting/updating personal information
                  and will disable their account to prevent any further
                  purchases. Information can be requests at [imbed our email
                  address]. Customer information may be collected offline or
                  online and is safeguarded with every relevant safety practice
                  and procedure.
                </p>
                <h5 className="mt-3">Updating Our Privacy Policy</h5>
                <p>
                  Policy updates to BROW BAR BY REEMA are posted to the website
                  about information that is collected, disclosed or used.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
};

export default PrivacyPolicy;