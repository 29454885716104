/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Tab, Nav, Button, Modal, Tabs } from "react-bootstrap";
import Select from "react-select";
import countries from "countries-list";
import { Link, useLocation, useNavigate } from "react-router-dom";
import bestsellers from "../../data/bestsellers";
import ProductBox from "../../components/product-box";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../redux/user/actions";
import productActions from "../../redux/products/actions";
import { useSnackbar } from "notistack";
import {
  postNewAdress,
  editAdress,
  deleteAdress,
  getUserProfie,
  getWishList,
  orderHistory,
  getUserProfile,
  updateUserProfie,
} from "../../service/api";
import { IMG_URL, YOUR_GOOGLE_MAPS_API_KEY } from "../../config";
import Autocomplete from "react-google-autocomplete";
import SpinnerComponent from "../../components/Spinner";
import ProductLoading from "../../components/loading-product";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { TablePagination } from "@mui/material";
import { AiFillEye, AiFillEyeInvisible, AiFillDelete } from "react-icons/ai";
import {
  resendOtp,
  verifyOtp,
  loginVerifyEmail,
  forgotPassword,
} from "../../service/api";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UserDashboard = () => {
  const [defaultKey, setdefaultKey] = useState("my-account");
  //ADDRESS
  const [addressModal, setAddressModal] = useState(false);
  const state = [];
  Object.entries(countries?.countries).map((key) => {
    state.push({ value: key[0], label: key[1].name });
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector((state) => state.user);
  const handleLogout = () => {
    enqueueSnackbar("You have logged out of your account successfully.", {
      variant: "success",
    });
    navigate("/account");
    // localStorage.removeItem("authentication")
    // localStorage.removeItem("token")
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(productActions.logout());
    dispatch(actions.setToken(null));
    dispatch(actions.setLoggedIn(false));
  };

  const [input, setInput] = useState({
    // fullName: "",
    // companyName: "",
    address: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    country: null,
  });

  const [inputError, setInputError] = useState({
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
  });
  const [addressId, setAddressId] = useState();
  const [modalState, setModalState] = useState();
  const [selectAdressId, setselectAdressId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState();
  useEffect(() => {
    getUserProfie().then((res) => {
      console.log("res?.data", res?.data);

      if (res?.data) {
        setUserData(res?.data);
        setselectAdressId(
          res?.data?.address?.length > 0 ? res?.data?.address[0]?._id : null
        );
      }
    });
  }, [""]);

  const addAdress = (e) => {
    e.preventDefault();

    console.log("input", input);
    if (!input?.address) {
      setInputError({ ...inputError, address: true });
      return;
    }
    // if (!input?.addressLine1) {
    //   setInputError({ ...inputError, addressLine1: true })
    //   return
    // }
    if (!input?.city) {
      setInputError({ ...inputError, city: true });
      return;
    }
    if (!input?.state) {
      setInputError({ ...inputError, state: true });
      return;
    }
    if (!input?.zipCode) {
      setInputError({ ...inputError, zipCode: true });
      return;
    }

    if (!input?.country) {
      setInputError({ ...inputError, country: true });
      return;
    }
    if (input?.addressLine1 === "") {
      delete input?.addressLine1;
    }
    console.log("input", input);
    setLoading(true);
    setAddressModal(false);

    if (modalState === "Add") {
      postNewAdress(input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });

          getUserProfie().then((res) => {
            setUserData(res?.data);
            console.log(res?.data);
            setLoading(false);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: null,
            country: null,
          });
        })
        .catch((e) => {
          console.log('e: ', e, e.response);
          enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
        })
        .finally((res) => {
          setLoading(false);
        });
    } else if (modalState === "Edit") {
      editAdress(addressId, input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });
          getUserProfie().then((res) => {
            setUserData(res?.data);
            console.log(res?.data);
            setLoading(false);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: null,
            country: null,
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.response?.data?.message, {
            variant: "error",
          });

        })
        .finally((res) => {
          setLoading(false);
        });
    }
  };

  const deleteAdressFun = (id) => {
    console.log(id);
    setLoading(true);
    deleteAdress(id)
      .then((res) => {
        console.log(res);
        if (res?.status) enqueueSnackbar(res?.message, { variant: "success" });
        getUserProfie().then((res) => {
          setUserData(res?.data);
          setLoading(false);
        });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  //WISHLIST

  const [wishList, setwishList] = useState([]);
  const [totalResults, setTotalResults] = useState(null);
  const [Wloading, setwLoading] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    setwLoading(true);
    getWishList({
      page: page,
      sizePerPage: 8,
    }).then((res) => {
      if (res?.status) {
        setwishList([...wishList, ...res?.data?.docs]);
        setTotalResults(res?.data?.totalDocs);
        dispatch(productActions.setWishList(res?.data?.totalDocs));
        console.log(res?.data?.docs);
        setwLoading(false);
      }
    });

    return () => { };
  }, [page]);

  const reload = () => {
    setwLoading(true);
    setwishList([]);
    getWishList({
      page: 1,
      sizePerPage: 12,
    })
      .then((res) => {
        if (res?.status) {
          setwishList(res?.data?.docs);
          setTotalResults(res?.data?.totalDocs);
          dispatch(productActions.setWishList(res?.data?.totalDocs));
          console.log(res?.data?.docs);
        }
      })
      .finally((res) => {
        setwLoading(false);
      });
  };

  //USER PROFILE

  const [userProfile, setuserProfile] = useState({
    name: null,
    email: null,
    mobile: null,
    dob: null,
  });

  const [file, setFile] = useState(null);
  const [previewUrl, setpreviewUrl] = useState(null);


  const handleFileChange = (event) => {
    const imageFile = event.target.files[0];
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
      'image/bmp',
      // 'image/vnd.microsoft.icon',
      'image/tiff',
      // 'image/svg+xml'
    ]; // Array of allowed MIME types for images

  if (!allowedTypes.includes(imageFile.type)) {
    enqueueSnackbar("Please Upload valid Image file",{variant:"error"});
    event.target.value = '';
    return;
  }

    setFile(event.target.files[0]);
    setpreviewUrl(URL.createObjectURL(event.target.files[0]));
    event.target.value = '';
  };

  // let previewUrl = null;
  // if (file) {
  //   previewUrl = URL.createObjectURL(file);
  // }

  useEffect(() => {
    console.log("user---->", user);
    setuserProfile({
      ...userProfile,
      email: user?.email,
      mobile: user?.mobile,
      name: user?.name,
      dob: user?.dob ? new Date(user?.dob) : new Date(),
    });
    setpreviewUrl(user?.profileImage);
    setFile(user?.profileImage);
  }, [user]);

  const onProfileChange = (e) => {
    setuserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const [profileLoad, setprofileLoad] = useState(false)
  const saveProfile = () => {
    if (!userProfile?.name) {
      enqueueSnackbar("Please enter your name");
      return;
    }
    if (!userProfile?.dob) {
      enqueueSnackbar("Please select your Date of Birth");
      return;
    }
    if (!userProfile?.mobile) {
      enqueueSnackbar("Please enter your phone number");
      return;
    }
    if (!userProfile?.email) {
      enqueueSnackbar("Please enter email");
      return;
    }
    setprofileLoad(true)
    const validPrefix = "user-profile/";
    const formData = new FormData();
    formData.append("name", userProfile?.name);
    formData.append("email", userProfile?.email);
    formData.append("mobile", userProfile?.mobile);
    formData.append("dob", userProfile?.dob);
    if(typeof(file) === 'string')
     {
      formData.append("removeProfileImage", false);
     }
    else if(file)
    {
      formData.append("profileImage", file);
      formData.append("removeProfileImage", false);
    }
    else
    {
      formData.append("removeProfileImage", true);
    }


    console.log(userProfile);

    updateUserProfie(formData)
      .then((res) => {
        if (res?.status) {
          enqueueSnackbar(" User profile updated successfully", {
            variant: "success",
          });
          console.log("res", res);
          dispatch(actions.setUser(res?.data));
        }
      })
      .catch((err) => {
        console.log("err", err);
        enqueueSnackbar(err?.response?.data?.message?.details?.length > 0 ? err?.response?.data?.message?.details[0]?.message : " Something went wrong", { variant: "error" });
      })
      .finally(res => {
        setprofileLoad(false)
      })
  };

  //ORDER LIST

  const [opage, setOPage] = useState(0);
  const [order, setOrder] = useState([]);
  const [totalOResults, setTotalOResults] = useState();
  const [rowsOPerPage, setrowsOPerPage] = useState(8);

  useEffect(() => {
    const obj = {};
    obj.page = opage + 1;
    obj.sizePerPage = rowsOPerPage;

    orderHistory(obj).then((res) => {
      if (res?.status) {
        console.log("order", res?.data);
        setOrder(res?.data?.docs);
        setTotalOResults(res?.data?.totalDocs);
      }
    });
  }, [opage]);
  const handleChangePage = (event, newPage) => {
    setOPage(newPage);
  };

  //RETURN ORDER LIST

  const [returnOPage, setReturnOPage] = useState(0);
  const [returnOrder, setReturnOrder] = useState([]);
  const [returnTotalOResults, setReturnTotalOResults] = useState();
  const [returnRowsOPerPage, setReturnRowsOPerPage] = useState(8);

  useEffect(() => {
    const obj = {};
    obj.page = returnOPage + 1;
    obj.sizePerPage = returnRowsOPerPage;
    obj.status = 6;

    orderHistory(obj).then((res) => {
      if (res?.status) {
        console.log("ReturnOrder", res?.data);
        setReturnOrder(res?.data?.docs);
        setReturnTotalOResults(res?.data?.totalDocs);
      }
    });
  }, [returnOPage]);

  const handleChangeReturnPage = (event, newReturnPage) => {
    setReturnOPage(newReturnPage);
  };

  //UPDATE PASSWORD

  const [defaultAddressModal, setdefaultAddressModal] = useState(false);

  const updateDefaultAddress = (e) => {
    e.preventDefault();

    console.log("input", input);
    if (!input?.address) {
      setInputError({ ...inputError, address: true });
      return;
    }
    // if (!input?.addressLine1) {
    //   setInputError({ ...inputError, addressLine1: true })
    //   return
    // }
    if (!input?.city) {
      setInputError({ ...inputError, city: true });
      return;
    }
    if (!input?.state) {
      setInputError({ ...inputError, state: true });
      return;
    }
    if (!input?.zipCode) {
      setInputError({ ...inputError, zipCode: true });
      return;
    }

    if (!input?.country) {
      setInputError({ ...inputError, country: true });
      return;
    }
    if (input?.addressLine1 === "") {
      delete input?.addressLine1;
    }
    console.log("input", input);
    setLoading(true);
    setdefaultAddressModal(false);

    editAdress(addressId, input)
      .then((res) => {
        console.log(res);
        if (res?.status) enqueueSnackbar(res?.message, { variant: "success" });
        getUserProfie().then((res) => {
          setUserData(res?.data);
          console.log(res?.data);
          dispatch(actions.setUser(res?.data));
          setLoading(false);
        });
        setInput({
          ...input,
          address: "",
          addressLine1: "",
          city: "",
          state: "",
          zipCode: null,
          country: null,
        });
      })
      .catch((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  //PASSWORD CHANGE

  const tabs = {
    HOME: "HOME",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
  };

  const loginTabs = {
    LOGIN: "LOGIN",
    VERIFY: "VERIFY",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    SEND_PASSWORD: "SEND_PASSWORD",
  };

  const [loginInput, setLoginInput] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    setLoginInput({ ...loginInput, email: user?.email });
  }, [user]);

  const [inputLogin, setInputLogin] = useState({
    name: false,
    email: false,
    password: false,
  });

  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const [activeTab, setactiveTab] = useState(tabs.HOME);
  const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);
  const [floading, setFloading] = useState(false);
  const location = useLocation()
  console.log('location: ', location);
  useEffect(() => {
    if (location.search === "?wishlist") {
      setdefaultKey("my-wishlist");
    }
  }, [location])
  const onLogchange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    setInputLogin({ ...inputLogin, [e.target.name]: false });
  };
  function emailValidation(body) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  const resendLoginOtpFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    setFloading(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setFloading(false);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setFloading(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          navigate("/");
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setFloading(false);
      });
  };

  const forgotPasswordFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    setFloading(true);
    forgotPassword(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setactiveTab(tabs.HOME);
          setLoginInput({ ...loginInput, name: "", email: "", password: "" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setFloading(false);
      });
  };

  const [otpMatch, setOtpMatch] = useState(null);
  const sendOtpToMail = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    // if (!loginInput?.password) {
    //   // enqueueSnackbar("Please enter your password");
    //   setInputLogin({ ...inputLogin, password: true })
    //   return
    // }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    // submitForm.append("password", loginInput.password);
    setFloading(true);
    loginVerifyEmail(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setOtpMatch(res?.data);
          setLoginActiveTab(loginTabs.SEND_OTP);
          // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setFloading(false);
      });
  };

  const verifyOtpToChangePassword = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    if (otp === otpMatch) {
      setLoginActiveTab(loginTabs.SEND_PASSWORD);
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
    } else {
      enqueueSnackbar("The OTP enetered is incorrect", { variant: "error" });
    }
  };

  return (
    <section className="my-account ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>User Dashboard | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <Tab.Container defaultActiveKey="my-account" activeKey={defaultKey}>
              <Row className="justify-content-center">
                {/* LEFT TABS */}
                <Col lg="3" sm="12">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => setdefaultKey("my-account")}
                        className="account-tabs"
                        eventKey="my-account"
                      >
                        My Account
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => setdefaultKey("my-orders")}
                        className="account-tabs"
                        eventKey="my-orders"
                      >
                        My Orders
                      </Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link
                        onClick={() => setdefaultKey("my-wishlist")}
                        className="account-tabs"
                        eventKey="my-wishlist"
                      >
                        My Wishlist
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link
                        className="account-tabs"
                        onClick={() => setdefaultKey("address-book")}
                        eventKey="address-book"
                      >
                        Address Book
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="account-tabs"
                        eventKey="account-information"
                        onClick={() => setdefaultKey("account-information")}
                      >
                        Account Information
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        onClick={() => setdefaultKey("my-returns")}
                        className="account-tabs"
                        eventKey="my-returns"
                      >
                        My Returns
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="account-tabs"
                        eventKey="logout"
                        style={{ borderBottom: "0px solid transparent" }}
                        onClick={handleLogout}
                      >
                        Logout
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                {/* RIGHT TABS */}
                <Col lg="9" sm="12">
                  <Tab.Content>
                    {/* <Tabs defaultActiveKey={defaultKey}> */}
                    <Tab.Pane eventKey="my-account">
                      <div className="account-info">
                        <h3>My Account</h3>
                        <Row>
                          {activeTab === tabs.HOME ? (
                            <Col lg="12" sm="12">
                              <div className="info-title">
                                <h5>Account Information</h5>
                              </div>
                              <div className="account-information">
                                <div className="information-detail">
                                  <h5>Contact Information</h5>
                                  <p>{user?.name}</p>
                                  <p>{user?.email}</p>
                                  <div className="d-flex mt-1">
                                    <Link
                                      className="action-link"
                                      onClick={() => {
                                        setdefaultKey("account-information");
                                      }}
                                    >
                                      Edit
                                    </Link>
                                    {activeTab === tabs.HOME ? (
                                      <Link
                                        className="action-link ml-2"
                                        onClick={() => {
                                          setactiveTab(tabs.CHANGE_PASSWORD);
                                          setLoginActiveTab(
                                            loginTabs.FORGOT_PASSWORD
                                          );
                                        }}
                                      >
                                        Change password
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <Col lg="12" sm="12">
                              <div className="info-title">
                                <h5>Change Password</h5>
                              </div>

                              {floading ? (
                                <Col lg="6" sm="12">
                                  <div className="login_wrap">
                                    <div className="account-information">
                                      <div
                                        className="information-detail"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Spinner />
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ) : loginActiveTab ===
                                loginTabs.FORGOT_PASSWORD ? (
                                <Col lg="6" sm="12">
                                  <div className="login_wrap">
                                    <div className="account-information">
                                      <div className="information-detail">
                                        <h5>Your email</h5>
                                        <form className="login-form">
                                          <div className="form-group">
                                            <input
                                              type="email"
                                              name="email"
                                              value={loginInput?.email}
                                              // onChange={onLogchange}
                                              className="form-control"
                                              placeholder="Your Email"
                                            />
                                            <small
                                              className="validation-error"
                                              style={{ color: "red" }}
                                            >
                                              {inputLogin?.email
                                                ? "Email is required"
                                                : ""}
                                            </small>
                                          </div>

                                          <button
                                            className="login-btn"
                                            onClick={sendOtpToMail}
                                          >
                                            Send OTP
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ) : loginActiveTab === loginTabs.SEND_OTP ? (
                                <Col lg="6" sm="12">
                                  <div className="login_wrap">
                                    <div className="account-information">
                                      <div className="information-detail">
                                        <h5>Verify your account</h5>
                                        <form className="login-form">
                                          <div className="form-group">
                                            <input
                                              type="number"
                                              name="otp"
                                              value={loginInput?.otp}
                                              onChange={(e) => {
                                                setOtp(e.target.value);
                                              }}
                                              className="form-control"
                                              placeholder="Enter OTP"
                                            />
                                            <small
                                              className="validation-error"
                                              style={{ color: "red" }}
                                            >
                                              {otp === null
                                                ? "Please enter OTP"
                                                : ""}
                                            </small>
                                          </div>

                                          <span className="forgot-pass">
                                            <p onClick={sendOtpToMail}>
                                              Resend OTP
                                            </p>
                                          </span>

                                          <button
                                            className="login-btn"
                                            onClick={verifyOtpToChangePassword}
                                          >
                                            Verify OTP
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ) : loginActiveTab === loginTabs.SEND_PASSWORD ? (
                                <Col lg="6" sm="12">
                                  <div className="login_wrap">
                                    <div className="account-information">
                                      <div className="information-detail">
                                        <h5>Enter new password</h5>
                                        <form className="login-form">
                                          <div className="form-group">
                                            <input
                                              value={loginInput?.password}
                                              onChange={onLogchange}
                                              type={
                                                showForgotPassword
                                                  ? "text"
                                                  : "password"
                                              }
                                              name="password"
                                              className="form-control"
                                              placeholder="Password"
                                            />
                                            <Button
                                              className="password-icon"
                                              onClick={() =>
                                                setShowForgotPassword(
                                                  !showForgotPassword
                                                )
                                              }
                                            >
                                              {showForgotPassword ? (
                                                <AiFillEyeInvisible />
                                              ) : (
                                                <AiFillEye />
                                              )}
                                            </Button>
                                            <small
                                              className="validation-error"
                                              style={{ color: "red" }}
                                            >
                                              {inputLogin?.password
                                                ? "password is required"
                                                : ""}
                                            </small>
                                          </div>

                                          <button
                                            className="login-btn"
                                            onClick={forgotPasswordFun}
                                          >
                                            Change password
                                          </button>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col lg="6" sm="12">
                                  <div className="login_wrap">
                                    <h3 className="mb-30">Verify Email</h3>

                                    <form className="login-form">
                                      <div className="form-group">
                                        <input
                                          value={otp}
                                          onChange={(e) => {
                                            setOtpError(false);
                                            setOtp(e.target.value);
                                          }}
                                          type="number"
                                          name="otp"
                                          className="form-control"
                                          placeholder="Enter 6 digit OTP"
                                        />
                                        <small
                                          className="validation-error"
                                          style={{ color: "red" }}
                                        >
                                          {otpError ? "Please enter OTP" : ""}
                                        </small>
                                        <span className="forgot-pass">
                                          <p onClick={resendLoginOtpFun}>
                                            Resend OTP
                                          </p>
                                        </span>
                                      </div>
                                      <button
                                        className="login-btn"
                                        onClick={sendOtp}
                                      >
                                        Verify Email
                                      </button>
                                    </form>
                                  </div>
                                </Col>
                              )}

                              <div className="account-information">
                                <div className="information-detail">
                                  <div className="d-flex mt-1">
                                    <Link
                                      className="action-link"
                                      onClick={() => {
                                        setactiveTab(tabs.HOME);
                                      }}
                                    >
                                      Back
                                    </Link>
                                    {activeTab === tabs.HOME ? (
                                      <Link
                                        className="action-link ml-2"
                                        onClick={() => {
                                          setactiveTab(tabs.CHANGE_PASSWORD);
                                          setLoginActiveTab(
                                            loginTabs.FORGOT_PASSWORD
                                          );
                                        }}
                                      >
                                        Change password
                                      </Link>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                          <Col xl="12" sm="12" className="mt-2">
                            {
                              user?.address?.length > 0 ?
                                <div className="info-title">
                                  <h5>Address Book</h5>
                                </div> : null
                            }
                            <div className="account-information">
                              {user?.address?.length > 0 ? (
                                <div className="information-detail">
                                  <h5>Default address</h5>
                                  <p>{user?.address[0]?.address}</p>
                                  <div className="address">
                                    <p>{user?.address[0]?.city}</p>
                                    <p>
                                      {user?.address[0]?.state},{" "}
                                      {user?.address[0]?.zipCode}
                                    </p>
                                    <p>{user?.address[0]?.country}</p>
                                  </div>
                                  <div className="d-flex mt-1">
                                    <Link
                                      className="action-link"
                                      onClick={() => {
                                        setdefaultAddressModal(true);
                                        setAddressId(user?.address[0]?._id);
                                        setInput({
                                          ...input,
                                          address: user?.address[0]?.address,
                                          addressLine1:
                                            user?.address[0]?.addressLine1,
                                          city: user?.address[0]?.city,
                                          state: user?.address[0]?.state,
                                          zipCode: user?.address[0]?.zipCode,
                                          country: user?.address[0]?.country,
                                        });
                                      }}
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Modal
                          size="lg"
                          show={defaultAddressModal}
                          onHide={() =>
                            setdefaultAddressModal(!defaultAddressModal)
                          }
                        >
                          <Modal.Header closeButton>
                            <Modal.Title className="address-modal-title">
                              Update default Address
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <form>
                              <Row>
                                <Col xl="12" sm="12">
                                  <div className="form-group">
                                    <Autocomplete
                                      className="form-control"
                                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                      options={{
                                        types: ["address"],
                                      }}
                                      defaultValue={input?.address}
                                      onChange={(e) => {
                                        console.log(
                                          "address change ",
                                          e.target.value
                                        );
                                        setInput({
                                          ...input,
                                          address: e.target.value,
                                        });
                                        setInputError({
                                          ...inputError,
                                          address: false,
                                        });
                                      }}
                                      onPlaceSelected={(place) => {
                                        console.log(place);
                                        input.address =
                                          place?.formatted_address;
                                        let address = place?.formatted_address;
                                        console.log("address: ", address);
                                        input.address = address;
                                        console.log(
                                          "address",
                                          place?.formatted_address
                                        );
                                        setInput({
                                          ...input,
                                          address: address,
                                        });
                                        setInputError({
                                          ...inputError,
                                          address: false,
                                        });
                                        place?.address_components?.map(
                                          (data) => {
                                            /* COUNTRY */
                                            if (
                                              data?.types?.includes("country")
                                            ) {
                                              input.country = data?.long_name;
                                              // setInput(input);
                                              console.log("input: ", input);
                                              setInputError({
                                                ...inputError,
                                                country: false,
                                              });
                                              console.log(
                                                "Country",
                                                data?.long_name
                                              );
                                            }

                                            /* STATE */
                                            if (
                                              data?.types?.includes(
                                                "administrative_area_level_1"
                                              )
                                            ) {
                                              setInput({
                                                ...input,
                                                state: data?.long_name,
                                              });
                                              setInputError({
                                                ...inputError,
                                                state: false,
                                              });
                                              input.state = data?.long_name;
                                              console.log(
                                                "State",
                                                data?.long_name
                                              );
                                            }

                                            /* CITY */
                                            if (
                                              data?.types?.includes("locality")
                                            ) {
                                              setInputError({
                                                ...inputError,
                                                city: false,
                                              });
                                              input.city = data?.long_name;
                                            }

                                            /* ZIPCODE */
                                            if (
                                              data?.types?.includes(
                                                "postal_code"
                                              )
                                            ) {
                                              setInput({
                                                ...input,
                                                zipCode: data?.long_name,
                                              });
                                              setInputError({
                                                ...inputError,
                                                zipCode: false,
                                              });
                                              input.zipCode = data?.long_name;
                                            }

                                            /* ADDRESS LINE 1 */
                                            /* if (
                                              data?.types?.includes(
                                                "administrative_area_level_3"
                                              ) ||
                                              data?.types?.includes(
                                                "administrative_area_level_2"
                                              )
                                            ) {
                                              setInputError({
                                                ...inputError,
                                                addressLine1: false,
                                              });
                                              input.addressLine1 =
                                                data?.long_name;
                                            } */
                                          }
                                        );
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.address
                                      ? "Address line 1 is required"
                                      : ""}
                                  </small>
                                </Col>
                                <Col xl="12" sm="12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="addressLine1"
                                      name="addressLine1"
                                      value={input?.addressLine1}
                                      className="form-control"
                                      placeholder="Address Line 2"
                                      onChange={(e) => {
                                        console.log(e.target.value);
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.addressLine1
                                      ? "Address line 2 is required"
                                      : ""}
                                  </small>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="city"
                                      name="city"
                                      value={input?.city}
                                      className="form-control"
                                      placeholder="City"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.city
                                      ? "city 1 is required"
                                      : ""}
                                  </small>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="state"
                                      name="state"
                                      value={input?.state}
                                      className="form-control"
                                      placeholder="State"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.state
                                      ? "state is required"
                                      : ""}
                                  </small>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      id="zipCode"
                                      name="zipCode"
                                      value={input?.zipCode}
                                      className="form-control"
                                      placeholder="Zip code"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          [e.target.name]: false,
                                        });
                                        setInput({
                                          ...input,
                                          [e.target.name]: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.zipCode
                                      ? "zip Code is required"
                                      : ""}
                                  </small>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      loadingMessage="Select Country"
                                      placeholder="Select Country"
                                      value={{
                                        label: input?.country,
                                        value: state.filter(
                                          (obj) => obj?.label === input.country
                                        )[0]?.value,
                                      }}
                                      options={state}
                                      name="country"
                                      onChange={(e) => {
                                        setInputError({
                                          ...inputError,
                                          ["country"]: false,
                                        });
                                        setInput({
                                          ...input,
                                          country: e.label,
                                        });
                                      }}
                                    />
                                  </div>
                                  <small
                                    className="validation-error-select"
                                    style={{ color: "red" }}
                                  >
                                    {inputError?.country
                                      ? "country 1 is required"
                                      : ""}
                                  </small>
                                </Col>
                              </Row>
                            </form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              className="add-address-btn"
                              style={{
                                backgroundColor: "#000",
                                borderColor: "#000",
                              }}
                              onClick={updateDefaultAddress}
                            >
                              Update Address
                            </Button>{" "}
                            {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="my-orders">
                      <div className="orders-info">
                        <h3>Your Orders</h3>
                        <div className="table-responsive">
                          <table className="orders-table">
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Product Name</th>
                                <th>Payment Details</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {order?.length === 0 ? <tr>
                                <td colSpan={5} >No Orders Placed</td>
                              </tr> : ''}
                              {order?.map((oData) => {
                                return (
                                  <tr>
                                    <td>#{oData?.orderId}</td>
                                    <td>
                                      {moment(oData?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )}
                                    </td>

                                    <td>{oData?.order[0]?.product?.name}</td>
                                    <td>{oData?.amount?.toFixed(2)}</td>
                                    <td>
                                      <div
                                        style={{
                                          textDecoration: "none",
                                          color: "#005CB9",
                                          cursor: "pointer",
                                          fontWeight: "800",
                                          display: "block",
                                        }}
                                      >
                                        <span
                                          onClick={() => {
                                            navigate(
                                              "/order-detail/" + oData?._id
                                            );
                                          }}
                                        >
                                          View order
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="d-flex mt-3"
                          style={{
                            width: "100%",
                            justifyContent: "flex-end",
                            marginBottom: "1px",
                          }}
                        >
                          <TablePagination
                            rowsPerPageOptions={[rowsOPerPage]}
                            count={totalOResults}
                            rowsPerPage={rowsOPerPage}
                            page={opage}
                            onPageChange={handleChangePage}
                          />
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="my-wishlist">
                      <Row className="resp-margin">
                        {Wloading ? (
                          <ProductLoading />
                        ) : wishList?.length !== 0 ? (
                          <InfiniteScroll
                            dataLength={wishList.length}
                            next={() => {
                              console.log(
                                "wishlistlength---->",
                                wishList?.length
                              );
                              console.log(
                                "wishlistlength--totalResults-->",
                                totalResults
                              );
                              setPage(page + 1);
                            }}
                            hasMore={wishList.length < totalResults}
                            loader={Wloading && <ProductLoading />}
                            style={{
                              overflowX: "hidden",
                              overflowY: "hidden",
                            }}
                          >
                            <Row className="mt-3">
                              {wishList?.map((product) => {
                                return (
                                  <Col lg="3" sm="12" key={product.id}>
                                    <ProductBox
                                      isOutOfStock={product.isOutOfStock}
                                      productFrontImg={
                                        product?.product?.productImage?.filter(
                                          (x) =>
                                            x?.color === product?.color?._id
                                        )[0]?.front[0]
                                      }
                                      productBackImg={
                                        product?.product?.productImage?.filter(
                                          (x) =>
                                            x?.color === product?.color?._id
                                        )[0]?.back[0]
                                      }
                                      productName={product?.product?.name}
                                      productPrice={product.product?.amount}
                                      productId={product?.product?._id}
                                      colorId={product?.color?._id}
                                      wishlistObj={product?.color?._id}
                                      reload={reload}
                                      refresh={true}
                                      product={product?.product}
                                      ratings={product?.productReview?.length}
                                    />
                                  </Col>
                                );
                              })}
                            </Row>
                          </InfiniteScroll>
                        ) : (
                          <Row className="mt-12">
                            <h6
                              style={{ textAlign: "center", marginTop: "5px" }}
                            >
                              Your wishlist is empty
                            </h6>
                          </Row>
                        )}
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="address-book">
                      {!loading ? (
                        <div className="address-information">
                          <h3>Manage Addresses</h3>

                          <div className="shipping-address">
                            <Row>
                              {userData?.address?.map((data, i) => {
                                return (
                                  <Col md="4" sm="12">
                                    <div
                                      className={`address-box  ${selectAdressId === data?._id ||
                                        selectAdressId === i
                                        ? "selected"
                                        : ""
                                        }`}
                                      onClick={() => {
                                        if (selectAdressId === data?._id) {
                                          setselectAdressId(null);
                                          return;
                                        }
                                        setselectAdressId(data?._id);
                                      }}
                                    >
                                      <div className="address-details">
                                        <p>
                                          {data?.address &&
                                            data?.address?.split(",")[0]}
                                        </p>
                                        {data?.addressLine1 && (
                                          <p>{data?.addressLine1}</p>
                                        )}
                                        <div className="address">
                                          <p>{data?.city}</p>
                                          <p>
                                            {data?.state}, {data?.zipCode}
                                          </p>
                                          <p>{data?.country}</p>
                                        </div>
                                        <div className="actions">
                                          <Button
                                            className="edit-btn"
                                            onClick={() => {
                                              setAddressModal(true);
                                              setModalState("Edit");
                                              setAddressId(data?._id);
                                              setInput({
                                                ...input,
                                                address: data?.address,
                                                addressLine1:
                                                  data?.addressLine1,
                                                city: data?.city,
                                                state: data?.state,
                                                zipCode: data?.zipCode,
                                                country: data?.country,
                                              });
                                            }}
                                          >
                                            Edit
                                          </Button>
                                          <Button
                                            className="delete-btn"
                                            onClick={() => {
                                              deleteAdressFun(data?._id);
                                            }}
                                          >
                                            Delete
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="selected-icon">
                                        <img
                                          src={require("../../assets/icons/check.png")}
                                          width="25"
                                          alt="Address Selected"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}

                              <Col md="4" sm="12">
                                <div
                                  className="address-box-add"
                                  onClick={() => {
                                    setModalState("Add");
                                    setAddressModal(!addressModal);
                                    setInput({
                                      ...input,
                                      address: "",
                                      addressLine1: "",
                                      city: "",
                                      state: "",
                                      zipCode: "",
                                      country: "",
                                    });
                                  }}
                                >
                                  <p>Add new address</p>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      ) : (
                        <SpinnerComponent />
                      )}

                      {/* ADD ADDRESS MODAL */}
                      <Modal
                        size="lg"
                        show={addressModal}
                        onHide={() => setAddressModal(!addressModal)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="address-modal-title">
                            {modalState} Address
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <form>
                            <Row>
                              {/* <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="fName"
                                    name="fName"
                                    className="form-control"
                                    placeholder="First Name"
                                  />
                                </div>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="lName"
                                    name="lName"
                                    className="form-control"
                                    placeholder="Last Name"
                                  />
                                </div>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Email Address"
                                  />
                                </div>
                              </Col> 
                              <Col xl="6" sm="12">
                                <PhoneInput
                                  placeholder="Phone"
                                  defaultCountry="US"
                                  className="phone-number-select"
                              /> 
                              </Col> */}
                              <Col xl="12" sm="12">
                                <div className="form-group">
                                  <Autocomplete
                                    className="form-control"
                                    apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                    options={{
                                      types: ["address"],
                                    }}
                                    // defaultValue={input?.address}
                                    onChange={(e) => {
                                      console.log(
                                        "address change ",
                                        e.target.value
                                      );
                                      setInput({
                                        ...input,
                                        address: e.target.value,
                                      });
                                      setInputError({
                                        ...inputError,
                                        address: false,
                                      });
                                    }}
                                    onPlaceSelected={(place) => {
                                      console.log(place);
                                      // input.address = place?.formatted_address;
                                      // let address = place?.formatted_address;
                                      // console.log("address: ", address);
                                      // input.address = address;
                                      // console.log(
                                      //   "address",
                                      //   place?.formatted_address
                                      // );
                                      // setInput({
                                      //   ...input,
                                      //   address: address,
                                      // });
                                      // setInputError({
                                      //   ...inputError,
                                      //   address: false,
                                      // });
                                      let address = "";

                                      place?.address_components?.map((data) => {
                                        if (
                                          data?.types?.includes("street_number")
                                        ) {
                                          address =
                                            address + data?.long_name + " ";
                                        }
                                        if (data?.types?.includes("route")) {
                                          address =
                                            address + data?.long_name + " ";
                                        }
                                        input.address = address;
                                        setInput({
                                          ...input,
                                          address: address,
                                        });
                                        setInputError({
                                          ...inputError,
                                          address: false,
                                        });
                                        /* COUNTRY */
                                        if (data?.types?.includes("country")) {
                                          input.country = data?.long_name;
                                          // setInput(input);
                                          console.log("input: ", input);
                                          setInputError({
                                            ...inputError,
                                            country: false,
                                          });
                                          console.log(
                                            "Country",
                                            data?.long_name
                                          );
                                        }

                                        /* STATE */
                                        if (
                                          data?.types?.includes(
                                            "administrative_area_level_1"
                                          )
                                        ) {
                                          setInput({
                                            ...input,
                                            state: data?.long_name,
                                          });
                                          setInputError({
                                            ...inputError,
                                            state: false,
                                          });
                                          input.state = data?.long_name;
                                          console.log("State", data?.long_name);
                                        }

                                        /* CITY */
                                        if (data?.types?.includes("locality")) {
                                          setInputError({
                                            ...inputError,
                                            city: false,
                                          });
                                          input.city = data?.long_name;
                                        }

                                        /* ZIPCODE */
                                        if (
                                          data?.types?.includes("postal_code")
                                        ) {
                                          setInput({
                                            ...input,
                                            zipCode: data?.long_name,
                                          });
                                          setInputError({
                                            ...inputError,
                                            zipCode: false,
                                          });
                                          input.zipCode = data?.long_name;
                                        }

                                        /* ADDRESS LINE 1 */
                                        /* if (
                                            data?.types?.includes(
                                              "administrative_area_level_3"
                                            ) ||
                                            data?.types?.includes(
                                              "administrative_area_level_2"
                                            )
                                          ) {
                                            setInputError({
                                              ...inputError,
                                              addressLine1: false,
                                            });
                                            input.addressLine1 =
                                              data?.long_name;
                                          } */
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {/* {inputError?.address
                                    ? "Address line 1 is required"
                                    : ""} */}
                                </small>
                              </Col>
                              <Col xl="12" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={input?.address}
                                    className="form-control"
                                    placeholder="Address Line 1"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setInputError({
                                        ...inputError,
                                        [e.target.name]: false,
                                      });
                                      setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.address
                                    ? "Address line 1 is required"
                                    : ""}
                                </small>
                              </Col>
                              <Col xl="12" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="addressLine1"
                                    name="addressLine1"
                                    value={input?.addressLine1}
                                    className="form-control"
                                    placeholder="Address Line 2"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setInputError({
                                        ...inputError,
                                        [e.target.name]: false,
                                      });
                                      setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.addressLine1
                                    ? "Address line 2 is required"
                                    : ""}
                                </small>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={input?.city}
                                    className="form-control"
                                    placeholder="City"
                                    onChange={(e) => {
                                      setInputError({
                                        ...inputError,
                                        [e.target.name]: false,
                                      });
                                      setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.city ? "city 1 is required" : ""}
                                </small>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="state"
                                    name="state"
                                    value={input?.state}
                                    className="form-control"
                                    placeholder="State"
                                    onChange={(e) => {
                                      setInputError({
                                        ...inputError,
                                        [e.target.name]: false,
                                      });
                                      setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.state ? "state is required" : ""}
                                </small>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    id="zipCode"
                                    name="zipCode"
                                    value={input?.zipCode}
                                    className="form-control"
                                    placeholder="Zip code"
                                    onChange={(e) => {
                                      setInputError({
                                        ...inputError,
                                        [e.target.name]: false,
                                      });
                                      setInput({
                                        ...input,
                                        [e.target.name]: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.zipCode
                                    ? "zip Code is required"
                                    : ""}
                                </small>
                              </Col>
                              <Col xl="6" sm="12">
                                <div className="form-group">
                                  <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    loadingMessage="Select Country"
                                    placeholder="Select Country"
                                    value={{
                                      label: input?.country,
                                      value: state.filter(
                                        (obj) => obj?.label === input.country
                                      )[0]?.value,
                                    }}
                                    options={state}
                                    name="country"
                                    onChange={(e) => {
                                      setInputError({
                                        ...inputError,
                                        ["country"]: false,
                                      });
                                      setInput({
                                        ...input,
                                        country: e.label,
                                      });
                                    }}
                                  />
                                </div>
                                <small
                                  className="validation-error-select"
                                  style={{ color: "red" }}
                                >
                                  {inputError?.country
                                    ? "country 1 is required"
                                    : ""}
                                </small>
                              </Col>
                            </Row>
                          </form>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="add-address-btn"
                            style={{
                              backgroundColor: "#000",
                              borderColor: "#000",
                            }}
                            onClick={addAdress}
                          >
                            Save Address
                          </Button>{" "}
                          {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
                        </Modal.Footer>
                      </Modal>
                    </Tab.Pane>
                    <Tab.Pane eventKey="account-information">
                      <div className="user-acc-info">
                        <h3>Account Information</h3>

                        <div className="user-details mt-2">
                          <Row>
                            <Col xl="10" sm="12">
                              <Row>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <label htmlFor="firstName">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={userProfile?.name}
                                      name="name"
                                      onChange={onProfileChange}
                                    />
                                  </div>
                                </Col>

                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <label htmlFor="dob">Date of Birth</label>
                                    {/* <input
                                      type="date"
                                      className="form-control"
                                      value={userProfile?.dob}
                                      name="dob"
                                      onChange={onProfileChange}
                                    /> */}
                                    {userProfile?.dob ? console.log(userProfile) : 'undefeined'}
                                    <DatePicker
                                      selected={userProfile?.dob}
                                      onChange={date => setuserProfile({ ...userProfile, ['dob']: date })}
                                      maxDate={new Date()}
                                    />
                                  </div>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <PhoneInput
                                      placeholder="Phone"
                                      name="mobile"
                                      defaultCountry="US"
                                      className="phone-number-select"
                                      value={userProfile?.mobile}
                                      onChange={(e) =>
                                        setuserProfile({
                                          ...userProfile,
                                          mobile: e,
                                        })
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col xl="6" sm="12">
                                  <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input
                                      disabled
                                      type="email"
                                      className="form-control"
                                      value={userProfile?.email}
                                      name="email"
                                      onChange={onProfileChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl="2" sm="12">
                              <Row>
                                <Col xl="12" sm="12">
                                  <div className="form-group">
                                    <label htmlFor="email">Profile Image</label>
                                    <div className="add-file">
                                      <input
                                        type="file"
                                        id="file-upload"
                                        onChange={handleFileChange}
                                        accept="image/*"
                                        multiple={false}
                                        style={{ display: "none" }}
                                      />
                                      <label htmlFor="file-upload">
                                        <p
                                          style={{
                                            padding: "40px 20px",
                                            border: "1px solid #E5E5E5",
                                            borderRadius: "7px",
                                            marginBottom: "0px",
                                            fontSize: "13px",
                                            color: "#000",
                                          }}
                                        >
                                          Add File
                                        </p>
                                      </label>
                                    </div>

                                    <div className="image-item">
                                      {file && (
                                        <div className="image-item">
                                          {previewUrl?.startsWith(
                                            "user-profile"
                                          ) ? (
                                            <div>
                                              <img
                                                src={`${IMG_URL}${previewUrl}`}
                                                alt={file.name}
                                              />
                                              <div className="delete"  onClick={() => {
                                                    setFile(null);
                                                    URL.revokeObjectURL(
                                                      previewUrl
                                                    );
                                                  }}>
                                                <AiFillDelete
                                                  className="delete-icon"
                                                 
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <div>
                                              <img
                                                src={previewUrl}
                                                alt={file.name}
                                              />
                                              <div className="delete">
                                                <AiFillDelete
                                                  className="delete-icon"
                                                  onClick={() => {
                                                    setFile(null);
                                                    URL.revokeObjectURL(
                                                      previewUrl
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col xl="12" sm="12">
                              <Button
                                className="save-btn"
                                onClick={saveProfile}
                                disabled={profileLoad ? true : false}
                              >
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="my-returns">
                      <div className="orders-info">
                        <h3>Return Orders</h3>
                        <div className="table-responsive">
                          <table className="orders-table">
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Date</th>
                                <th>Product Name</th>
                                <th>Payment Details</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {returnOrder?.length === 0 ? <tr>
                                <td colSpan={5} >No Return Orders </td>
                              </tr> : ''}
                              {returnOrder?.map((oData) => {
                                return (
                                  <tr>
                                    <td>#{oData?.orderId}</td>
                                    <td>
                                      {moment(oData?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )}
                                    </td>

                                    <td>{oData?.order[0]?.product?.name}</td>
                                    <td>{oData?.amount?.toFixed(2)}</td>
                                    <td>
                                      <div
                                        style={{
                                          textDecoration: "none",
                                          color: "#005CB9",
                                          cursor: "pointer",
                                          fontWeight: "800",
                                          display: "block",
                                        }}
                                      >
                                        <span
                                          onClick={() => {
                                            navigate(
                                              "/order-detail/" + oData?._id
                                            );
                                          }}
                                        >
                                          View order
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div
                          className="d-flex mt-3"
                          style={{
                            width: "100%",
                            justifyContent: "flex-end",
                            marginBottom: "1px",
                          }}
                        >
                          <TablePagination
                            rowsPerPageOptions={[rowsOPerPage]}
                            count={returnTotalOResults}
                            rowsPerPage={returnRowsOPerPage}
                            page={returnOPage}
                            onPageChange={handleChangeReturnPage}
                          />
                        </div>
                      </div>
                    </Tab.Pane>
                    {/* </Tabs> */}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default UserDashboard;