/* eslint-disable no-unused-vars */
/* eslint-disable new-parens */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { postNewsLetter } from "../../service/api";
import { useSnackbar } from "notistack";

function emailValidation(body) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (regex.test(body) === false) {
    return false;
  }
  return true;
}

const Footer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [newenrolled, setNewenrolled] = useState("");
  let state = true;
  const handlenews = () => {
    // console.log(newenrolled)
    if (newenrolled === "") {
      state = false;
      enqueueSnackbar("Please Enter Email.", { variant: "error" });
      return;
    }
    if (!emailValidation(newenrolled)) {
      state = false;
      enqueueSnackbar("Invalid Email Entered", { variant: "error" });
      return;
    }
    const newsdata = new FormData();
    newsdata.append("email", newenrolled);

    postNewsLetter(newsdata)
      .then((res) => {
        // console.log("res--->", res)
        enqueueSnackbar(res?.message, { variant: "success" });
        setNewenrolled("");
      })
      .catch((e) => {
        console.log("error--->", e?.response?.data?.message);
        enqueueSnackbar(
          e?.response?.data?.message
            ? e?.response?.data?.message
            : "Something went wrong. Please try it again.",
          { variant: "error" }
        );
      })
      .finally((res) => {});
    // console.log("successfully enrolled");
  };

  const { category } = useSelector((state) => state.products);
  return (
    <footer className="footer">
      {/* FOOTER INFO LINKS */}
      <div className="footer-links">
        <div className="container">
          <Row className="justify-content-between align-items-center">
            <Col lg="3" sm="12">
              <div className="become-member">
                <h5>BECOME A BBR VIP MEMBER</h5>
                <p>
                  Receive email updates on stuff you’ll probably want to know
                  about, including products, launches, and events.
                  <br />
                  Access 30% off your first order!
                </p>
                <div className="newsletter-section">
                  <input
                    type="text"
                    value={newenrolled}
                    onChange={(e) => setNewenrolled(e.target.value)}
                    placeholder="Your Email"
                    className="form-control mb-0"
                  />
                  <Button className="submit-btn" onClick={handlenews}>
                    Submit
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="7" sm="12">
              <Row className="justify-content-between">
                <Col lg="4" sm="12">
                  <div className="links-list">
                    <h5>ABOUT BROWBAR BY REEMA</h5>
                    <Link to="/about" className="link-list-item">
                      About Us
                    </Link>
                    <Link
                      to={`products/${
                        category?.find(
                          (obj) => obj?.name.toLowerCase() === "new"
                        )?._id
                      }`}
                      className="link-list-item"
                    >
                      New Arrivals
                    </Link>
                    <Link
                      to={`products/${
                        category?.find((obj) =>
                          obj?.name.includes("Best Sellers")
                        )?._id
                      }`}
                      className="link-list-item"
                    >
                      Bestsellers
                    </Link>
                    <a
                      href="https://wholesale.browbarbyreema.com/"
                      target={"_blank"}
                      className="link-list-item"
                      rel="noreferrer"
                    >
                      Wholesale
                    </a>
                    {/* <Link
                      to={`/sales-rep`}
                      smooth={true}
                      scroll
                      className="link-list-item"
                    >
                      Sales Rep.
                    </Link> */}
                    <a href="/sales-rep" className="link-list-item">
                      Sales Rep
                    </a>
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="links-list">
                    <h5>HELP</h5>
                    <Link to="/contact-us" className="link-list-item">
                      Contact
                    </Link>
                    <Link to="/returns" className="link-list-item">
                      Returns &amp; Exchanges
                    </Link>
                    <Link to="/press" className="link-list-item">
                      Press
                    </Link>
                    {/* <Link to="/affiliate-sign-up" className="link-list-item">
                      Affiliates
                    </Link> */}
                  </div>
                </Col>
                <Col lg="4" sm="12">
                  <div className="links-list">
                    <h5>GET SOCIAL</h5>
                    <a
                      href="https://www.facebook.com/browbarbyreema/"
                      target="_blank"
                      className="link-list-item"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                    <a
                      href="https://www.instagram.com/browbarbyreema/"
                      target="_blank"
                      className="link-list-item"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://twitter.com/browbarbyreema"
                      target="_blank"
                      className="link-list-item"
                      rel="noreferrer"
                    >
                      Twitter
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCDyuaCwqDU30tR6Dmt-Yhow"
                      target="_blank"
                      className="link-list-item"
                      rel="noreferrer"
                    >
                      Youtube
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>

      {/* COPYRIGHT INFORMATION */}
      <div className="copyright-info">
        <div className="container">
          <div className="copyright-links">
            <div className="info">
              <p>
                © 2024, Browbar by Reema. All rights reserved. Powered by{" "}
                <a
                  href="https://www.roundtechsquare.com"
                  target="_blank"
                  style={{ color: "#000" }}
                  rel="noreferrer"
                >
                  RoundTechSquare
                </a>
              </p>
              <div className="d-flex links">
                <a href="/privacy-policy">Privacy Policy</a>
                <a href="/terms-of-use">Terms of use</a>
                <a href="/cookie-settings">Cookie Settings</a>
              </div>
            </div>
            <div className="d-flex security-verifications">
              <img
                src={require("../../assets/security/authorize-net.webp")}
                alt="Authorize.net"
              />
              <img
                src={require("../../assets/security/paypal-badge.webp")}
                alt="Paypal Badge"
              />
              <img
                src={require("../../assets/security/badge_google.webp")}
                alt="Google Badge"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
