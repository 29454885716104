/* FACE COLLECTION */
import HeartbreakerBack from "../assets/products/face-01-back.webp";
import HeartBreakerFront from "../assets/products/face-01-front.webp";
import GoldenGiftBag from '../assets/products/face-08.webp';
import HollywoodIconBack from "../assets/products/eye-03-back.webp";
import HollywoodIconFront from "../assets/products/eye-03-front.webp";
import HollywoodIconSapphireBack from "../assets/products/eye-04-back.webp";
import HollywoodIconSapphireFront from "../assets/products/eye-04-front.webp";
import HollywoodIconRodeoBack from "../assets/products/eye-05-back.webp";
import HollywoodIconRodeoFront from "../assets/products/eye-05-front.webp";
import HollywoodIconDramaBack from "../assets/products/eye-06-back.webp";
import HollywoodIconDramaFront from "../assets/products/eye-06-front.webp";
import HollywoodIconBHNudeBack from "../assets/products/eye-07-back.webp";
import HollywoodIconBHNudeFront from "../assets/products/eye-07-front.webp";
import HollywoodIconBerryBack from "../assets/products/eye-08-back.webp";
import HollywoodIconBerryFront from "../assets/products/eye-08-front.webp";

const shopFace = [
  {
    id: 1,
    productBackImg: HeartbreakerBack,
    productFrontImg: HeartBreakerFront,
    isOutOfStock: false,
    productName: "Heartbreaker Highlighter Palette",
    productPrice: "$40.00",
  },
  {
    id: 2,
    productBackImg: HollywoodIconBack,
    productFrontImg: HollywoodIconFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - Sugar Daddy",
    productPrice: "$40.00",
  },
  {
    id: 3,
    productBackImg: HollywoodIconSapphireBack,
    productFrontImg: HollywoodIconSapphireFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - Sapphire Siren",
    productPrice: "$40.00",
  },
  {
    id: 4,
    productBackImg: HollywoodIconRodeoBack,
    productFrontImg: HollywoodIconRodeoFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - Rodeo Vamp",
    productPrice: "$40.00",
  },
  {
    id: 5,
    productBackImg: HollywoodIconDramaBack,
    productFrontImg: HollywoodIconDramaFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - Drama Queen",
    productPrice: "$40.00",
  },
  {
    id: 6,
    productBackImg: HollywoodIconBHNudeBack,
    productFrontImg: HollywoodIconBHNudeFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - BH Nude",
    productPrice: "$40.00",
  },
  {
    id: 7,
    productBackImg: HollywoodIconBerryBack,
    productFrontImg: HollywoodIconBerryFront,
    isOutOfStock: false,
    productName: "Hollywood Icon Face Kit - Berry DIva",
    productPrice: "$50.00",
  },
  {
    id: 8,
    productBackImg: GoldenGiftBag,
    productFrontImg: GoldenGiftBag,
    isOutOfStock: false,
    productName: "Golden Gift Bag",
    productPrice: "$40.00",
  },
];

export default shopFace;
