const SERVER_URL =
  // "https://moody-sheep-act-152-58-37-38.loca.lt/retail/user";
  "https://browbarbyreema-backend-nodejs-xekollbnzq-uc.a.run.app/retail/user/";
  // "http://192.168.29.179:8080/retail/user/";
const IMG_URL = "https://storage.googleapis.com/cdn-browbarbyreema/";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw";

module.exports = {
  SERVER_URL,
  IMG_URL,
  YOUR_GOOGLE_MAPS_API_KEY
};
