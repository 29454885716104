/* EYE COLLECTION */
import LoveBombGlossCollection from "../assets/products/lips-01.webp";
import HotChocolateCollection from "../assets/products/lips-02.webp";
import RodeoRougePornstarBack from "../assets/products/lips-03-back.webp";
import RodeoRougePornstarFront from "../assets/products/lips-03-front.webp";
import RodeoRougeBombshellBack from "../assets/products/lips-04-back.webp";
import RodeoRougeBombshellFront from "../assets/products/lips-04-front.webp";
import RodeoRougeRougeBack from "../assets/products/lips-05-back.webp";
import RodeoRougeRougeFront from "../assets/products/lips-05-front.webp";
import TrueShineMidnightBack from "../assets/products/lips-06-back.webp";
import TrueShineMidnightFront from "../assets/products/lips-06-front.webp";
import TrueShineLustMeBack from "../assets/products/lips-07-back.webp";
import TrueShineLustMeFront from "../assets/products/lips-07-front.webp";
import TrueShineLimelightBack from "../assets/products/lips-08-back.webp";
import TrueShineLimelightFront from "../assets/products/lips-08-front.webp";

const shopLips = [
  {
    id: 1,
    productBackImg: LoveBombGlossCollection,
    productFrontImg: LoveBombGlossCollection,
    isOutOfStock: false,
    productName: "Love Bomb Lit Hydrating Lip Gloss Collection",
    productPrice: "$33.00",
  },
  {
    id: 2,
    productBackImg: HotChocolateCollection,
    productFrontImg: HotChocolateCollection,
    isOutOfStock: false,
    productName: "Hot Chocolate Mocha Lipstick Collection",
    productPrice: "$35.00",
  },
  {
    id: 3,
    productBackImg: RodeoRougePornstarBack,
    productFrontImg: RodeoRougePornstarFront,
    isOutOfStock: false,
    productName: "Rodeo Rouge Collection - Porn Star",
    productPrice: "$17.00",
  },
  {
    id: 4,
    productBackImg: RodeoRougeBombshellBack,
    productFrontImg: RodeoRougeBombshellFront,
    isOutOfStock: false,
    productName: "Rodeo Rouge Collection - BH Bombshell",
    productPrice: "$17.00",
  },
  {
    id: 5,
    productBackImg: RodeoRougeRougeBack,
    productFrontImg: RodeoRougeRougeFront,
    isOutOfStock: false,
    productName: "Rodeo Rouge Collection - Rodeo Rouge",
    productPrice: "$17.00",
  },
  {
    id: 6,
    productBackImg: TrueShineMidnightBack,
    productFrontImg: TrueShineMidnightFront,
    isOutOfStock: false,
    productName: "True Shine Chrome Lipstick - Midnight Blues",
    productPrice: "$15.00",
  },
  {
    id: 7,
    productBackImg: TrueShineLustMeBack,
    productFrontImg: TrueShineLustMeFront,
    isOutOfStock: false,
    productName: "True Shine Chrome Lipstick - Lust Me",
    productPrice: "$15.00",
  },
  {
    id: 8,
    productBackImg: TrueShineLimelightBack,
    productFrontImg: TrueShineLimelightFront,
    isOutOfStock: false,
    productName: "True Shine Chrome Lipstick - Limelight",
    productPrice: "$15.00",
  },
];

export default shopLips;
