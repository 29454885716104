import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Returns = () => {
    return (
      <section className="returns ptb-30">
        <Helmet>
          <title>Returns | Brow Bar by Reema</title>
        </Helmet>

        <div className="container">
          <h2>RETURNS</h2>
          <Row>
            <Col lg="12" sm="12">
              <div className="returns-content">
                <p>
                  We are sorry your order didn’t work out! Browbarbyreema offers
                  a 15-day money-back guarantee. We want you to love our
                  products, but understand if you need a different style.
                </p>
                <p>
                  Items returned more than 15 days from purchase will receive a
                  store credit if within returned 30 days. Exchanges cannot be
                  processed at this time. Please note shipping costs will be
                  deducted from original purchase cost for return shipment.
                </p>
                <p>Damaged or defective item customer can free Return.</p>
                <p>
                  You can start your return here:{" "}
                  <Link to='/return-orders'>returns.browbar.com</Link>
                </p>
              </div>
              <div className="returns-content ptb-20">
                <h5>NEW! Browbar now offers FREE Return ??</h5>
                <p>
                  If you received a damaged or defective item please{" "}
                  <a href="/contact-us">contact us</a> within 30 days of
                  receiving your order.
                </p>
                <p>
                  Please note gift sets may not be returned as separate items.
                </p>
                <p>
                  *International orders must be returned at the expense of the
                  customer. Please email customer support for assistance.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
};

export default Returns;