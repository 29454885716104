/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllProducts } from "../../service/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_URL } from "../../config";
import SpinnerComponent from "../../components/Spinner";
import ProductLoading from "../../components/loading-product";
import { BsArrowRight } from 'react-icons/bs';

const defaultImg = "category-image/1672941733498.gif"
const options = [
  { value: "DATE", label: "Position" },
  { value: "ALPHABET", label: "Product Name" },
  { value: "AMOUNT", label: "Price" },
];

const ShopProducts = () => {
  const navigate = useNavigate();
  const [placeholder, setPlaceholder] = useState("Position");
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const { category } = useSelector((state) => state.products);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { id } = useParams();
  const [optionSelected, setOptionSelected] = useState("DATE");

  /* GET PRODUCTS LIST */
  const getProductList = () => {
    let params = {
      page: 1,
      sizePerPage: pageSize,
      category: [id],
      sortBy: optionSelected
    };
    getAllProducts(params)
      .then((res) => {
        if (res?.status) {
          let newArray = []
          res?.data[0]?.docs?.forEach((element) => {
            if (element?.quantity?.find((e) => e.color === element?.productImage.color)?.quantity > 0) {
              element.isOutOfStock = false
            } else {
              element.isOutOfStock = true
            }
            newArray.push(element)
          })

          setAllProducts(newArray);
          setTotalResults(res?.data[0]?.metadata[0]?.total);
          // console.log("total results", res?.data[0]?.metadata[0]?.total)
        }
        // console.log("products--->", res?.data[0]?.docs);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });
  };

  /* USEEFFECT */
  useEffect(() => {
    setLoading(true)
    getProductList();
    setSelectedCategory(category?.filter((data) => data?._id === id)[0]);
    setPage(1)
  }, [id, optionSelected]);

  const onChange = React.useCallback((event) => {
    const value = event.value;
    // console.log(value)
    setOptionSelected(value);
    setPlaceholder(event.label);

  }, []);

  /* USEEFFECT */
  useEffect(() => {
    // console.log("page", page)
    setLoading(true)
    if (page > 1) {
      let params = {
        page: page,
        sizePerPage: pageSize,
        category: [id],
        sortBy: optionSelected
      };
      getAllProducts(params)
        .then((res) => {
          if (res?.status && res?.data[0]?.docs?.length !== 0) {
            // console.log("find more-->", res?.data[0]?.docs)
            // allProducts.push(res?.data[0]?.docs)

            let newArray = []
            res?.data[0]?.docs?.forEach((element) => {
              if (element?.quantity?.find((e) => e.color === element?.productImage.color)?.quantity > 0) {
                element.isOutOfStock = false
              } else {
                element.isOutOfStock = true
              }
              newArray.push(element)
            })

            setAllProducts([...allProducts, ...newArray]);
          }
          // console.log("products--->", res?.data[0]?.docs);
          setLoading(false);
        })
        .catch((err) => {
          // console.log("err", err);
        })
        .finally((err) => {
          setLoading(false);
        })
    }
  }, [page])

  console.log(allProducts);

  return (
    <section className="new-arrivals ptb-30">
      <Helmet>
        <title>Shop All | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2 style={{ textTransform: "uppercase" }}>
          {selectedCategory?.name ? selectedCategory?.name : "Shop"} PRODUCTS
        </h2>
        <Row className="justify-content-center">
          {/* BANNER IMAGE */}
          <Col lg="12" sm="12">
            <div className="banner">
              {selectedCategory?.categoryImage ? (
                <img
                  src={IMG_URL + selectedCategory?.categoryImage}
                  alt={selectedCategory?.name + " Products | Brow Bar by Reema"}
                />
              ) : (
                <img
                  src={IMG_URL + defaultImg}
                  alt={"Shop" + " Products | Brow Bar by Reema"}
                />
              )}
            </div>
          </Col>

          {/* BANNER TEXT */}
          <Col lg="8" sm="12">
            <div className="heading ptb-10">
              <p>{selectedCategory?.description}</p>
              <p style={{display:"none"}}>
                <b >
                  For a limited time enjoy FREE gifts, including the luxe{" "}
                  <a
                    href="/"
                    style={{ textDecoration: "underline", color: "#000" }}
                  >
                    Bombshell Brow & Lash Serum
                  </a>
                  , with orders $40 and up PLUS a FREE{" "}
                  <a
                    href="/"
                    style={{ textDecoration: "underline", color: "#000" }}
                  >
                    Golden Gift Bag
                  </a>{" "}
                  with orders $50 and up!
                </b>
              </p>
            </div>
          </Col>
        </Row>

        {/* PRODUCTS LIST */}
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            {!loading ? allProducts?.length === 0 ? (
              <div className="no-match-found">
                <h5>No products found</h5>
                <p>
                  Try a different search term or check our some of our
                  suggestions below.
                </p>
                <Row className="mt-1">
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          style={{ height: "351px" }}
                          src={require("../../assets/banners/shop-all.webp")}
                          alt="SHOP ALL"
                        />
                        <Link

                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Shop"))?._id}`
                          }}
                          className="collection-link"
                          style={{ textDecoration: "none" }}
                        >
                          SHOP ALL <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-brow.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Brow"))?._id}`
                          }}
                          className="collection-link">
                          SHOP BROW <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-new-arrivals.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("New"))?._id}`
                          }}
                          className="collection-link">

                          SHOP NEW ARRIVALS{" "}
                          <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-bestsellers.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Best Sellers"))?._id}`
                          }}

                          className="collection-link">

                          SHOP BEST SELLERS{" "}
                          <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div className="product-stats">
                  <p>We have total {totalResults} products</p>
                  <Select
                    options={options}
                    classNamePrefix="select-control"
                    placeholder={placeholder}
                    name="sort-by"
                    id="sort-by"
                    onChange={onChange}
                  />
                </div>
                <Row>
                  <InfiniteScroll
                    dataLength={allProducts?.length}
                    next={() => setPage(page + 1)}
                    hasMore={allProducts?.length < totalResults}
                    loader={loading && <ProductLoading />}
                    style={{
                      overflowX: "hidden",
                      overflowY: "hidden",
                    }}
                  >
                    <Row>
                      {allProducts?.map((product) => {
                        return (
                          <Col lg="3" sm="12" key={product.id}>
                            {console.log("product", product.isOutOfStock)}
                            <ProductBox
                              isOutOfStock={product.isOutOfStock}
                              productFrontImg={product.productImage.front[0]}
                              productBackImg={product.productImage.back[0]}
                              productName={product.name + " - " + product?.color.filter((prd) => (prd?._id === product?.productImage?.color))?.[0]?.name}
                              productPrice={product.amount}
                              productId={product?._id}
                              colorId={product?.productImage?.color}
                              wishlistObj={product?.WishlistProduct?._id}
                              refresh={false}
                              product={product}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </InfiniteScroll>
                </Row>
              </>
            ) : <ProductLoading />}
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ShopProducts;
