/* eslint-disable array-callback-return */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { IMG_URL } from "../config";

const ShopCollection = () => {
  const { category } = useSelector((state) => state.products);

  return (
    <section className="shopping-collection border-top-section ptb-30">
      <div className="container">
        <h2>SHOP COLLECTION</h2>
        {category && category.length > 0 && (
          <Row className="ptb-20">
            <Col lg="12" sm="12">
              <Link className="collection-card">
                <img
                  src={
                    category?.filter((obj) => obj.name === "Shop").length > 0
                      ? IMG_URL +
                        category?.filter((obj) => obj.name === "Shop")[0]
                          .categoryImage
                      : require("../assets/banners/shop-all.webp")
                  }
                  alt="SHOP ALL"
                />
                <Link
                  to={
                    "/products/" +
                    category?.filter((obj) => obj.name === "Shop")[0]?._id
                  }
                  className="collection-link"
                >
                  SHOP ALL <BsArrowRight className="right-icon" />
                </Link>
              </Link>
            </Col>
            {category.map((obj, i) => {
              if (i >= 3) {
                return;
              }
              return (
                <>
                  <Col lg="4" sm="12" className="ptb-20">
                    <Link className="collection-card">
                      <img
                        src={IMG_URL + obj?.categoryImage}
                        alt={"SHOP" + obj?.name}
                      />
                      <Link
                      style={{textTransform: 'uppercase'}}
                        to={"/products/" + obj?._id}
                        className="collection-link"
                      >
                        {"SHOP " + obj?.name}{" "}
                        <BsArrowRight className="right-icon" />
                      </Link>
                    </Link>
                  </Col>
                </>
              );
            })}
          </Row>
        )}
      </div>
    </section>
  );
};

export default ShopCollection;
