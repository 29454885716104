/* SETS */
import LoveBombGlossCollection from '../assets/products/sets-01.webp';
import HotChocolateCollection from '../assets/products/sets-02.webp';
import RoyalRubyDreamsKit from '../assets/products/sets-03.webp';
import StarfireFuschiaKit from '../assets/products/sets-04.webp';
import DiamondsInTheSnow from '../assets/products/sets-05.webp';
import BadassBrowlightsShimmer from '../assets/products/sets-06.webp';
import BadassSummerBrowKitBack from '../assets/products/sets-07-back.webp';
import BadassSummerBrowKitFront from '../assets/products/sets-07-front.webp';
import MensBrowGroomingKit from '../assets/products/sets-08.webp';

const sets = [
  {
    id: 1,
    productBackImg: LoveBombGlossCollection,
    productFrontImg: LoveBombGlossCollection,
    isOutOfStock: true,
    productName: "Love Bomb Lit Hydrating Lip Gloss Collection",
    productPrice: "$33.00",
  },
  {
    id: 2,
    productBackImg: HotChocolateCollection,
    productFrontImg: HotChocolateCollection,
    isOutOfStock: true,
    productName: "Hot Chocolate Mocha Lipstick Collection",
    productPrice: "$35.00",
  },
  {
    id: 3,
    productBackImg: RoyalRubyDreamsKit,
    productFrontImg: RoyalRubyDreamsKit,
    isOutOfStock: true,
    productName: "Royal Ruby Dreams Kit",
    productPrice: "$31.00",
  },
  {
    id: 4,
    productBackImg: StarfireFuschiaKit,
    productFrontImg: StarfireFuschiaKit,
    isOutOfStock: true,
    productName: "Starfire Fuchsia Kit",
    productPrice: "$31.00",
  },
  {
    id: 5,
    productBackImg: DiamondsInTheSnow,
    productFrontImg: DiamondsInTheSnow,
    isOutOfStock: true,
    productName: "Diamonds in the Snow",
    productPrice: "$35.00",
  },
  {
    id: 6,
    productBackImg: BadassBrowlightsShimmer,
    productFrontImg: BadassBrowlightsShimmer,
    isOutOfStock: false,
    productName: "Badass and Browlights Shape & Shimmer Set",
    productPrice: "$40.00",
  },
  {
    id: 7,
    productBackImg: BadassSummerBrowKitBack,
    productFrontImg: BadassSummerBrowKitFront,
    isOutOfStock: false,
    productName: "Badass Summer Brow Kit",
    productPrice: "$66.00",
  },
  {
    id: 8,
    productBackImg: MensBrowGroomingKit,
    productFrontImg: MensBrowGroomingKit,
    isOutOfStock: false,
    productName: "Men's Brow Grooming Kit",
    productPrice: "$50.00",
  },
];

export default sets;
