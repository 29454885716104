import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

/* COMPONENT IMPORTS */
import ShopCollection from '../../components/shop-collection';

const Press = () => {
    return (
      <section className="press">
        <Helmet>
          <title>Press | Brow Bar by Reema</title>
        </Helmet>

        {/* BANNER */}
        <section className="ptb-30">
          <div className="container">
            <div className="banner">
              <img
                src={require("../../assets/banners/press-banner.webp")}
                alt="Press | Browbar by Reema"
              />
            </div>
          </div>
        </section>

        {/* PRESS CONTENT */}
        <section>
          <div className="container">
            <Row className="justify-content-center">
              <Col lg="6" sm="12">
                <h5 className="press-content">
                  From breaking world records for most eyebrows threaded to
                  breaking into a million dollar business, Reema has done it
                  all. Watch what the press has to say about Reema Khan and her
                  growing brow empire!
                </h5>
              </Col>
            </Row>
          </div>
        </section>

        {/* PRESS ARTICLES */}
        <Container className="ptb-20">
          <div className="container">
          <h3>As Seen On </h3>
            <Row className="ptb-10 content-row">
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/daily-mall.webp")}
                    className="image"
                    alt="Daily Mall"
                  />
                  <Link
                    to={{pathname:"https://www.dailymail.co.uk/femail/article-9522085/Brow-Bar-Reema-DIY-kits-helped-women-perfect-brows-lockdown.html"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Daily Mail
                    {/* <BsArrowRight className="right-icon" /> */}
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/gma-deals-and-steals.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://player.vimeo.com/544439244"}}
                    target="_blank"
                    className="article-link"
                  >
                    GMA Deals & Steals  
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/jills-deals-and-steals.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://deals.today.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Jill's Deals & Steals
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/gma-deals-and-steals-2.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://www.goodmorningamerica.com/shop"}}
                    target="_blank"
                    className="article-link"
                  >
                    GMA Deals & Steals
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <div className='frame-wrapper'>
                    <iframe
                      className="video-frame"
                      height="420"
                      src="https://www.youtube.com/embed/ALkzfKnoXNw"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <Link
                    to={{pathname:"https://www.nbclosangeles.com/california-live/how-to-set-up-your-own-brow-beauty-bar-at-home/2477583/"}}
                    target="_blank"
                    className="article-link"
                  >
                    NBC CA Live
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/press-lady-gang.webp")}
                    className="image"
                    alt="The Lady Gang Subscription Box"
                  />
                  <Link
                    to={{pathname:"https://shop.theladygang.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Lady Gang Subscription Box
                  </Link>
                </div>
              </Col>
            </Row>
            <h3>Media By Brow Bar</h3>
            <Row className='ptb-10 content-row'>
            <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/daytime-emmys-brand-lounge.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://theemmys.tv/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Daytime Emmys Brand Lounge
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/evertalk-tv.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://www.evertalktv.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Evertalk TV
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/daily-mail.webp")}
                    className="image"
                    alt="The Daily Mail"
                  />
                  <Link
                    to={{pathname:"https://www.dailymail.co.uk/tvshowbiz/article-8152459/Rachel-McCord-partners-Brow-Bar-Reema-brand-offers-free-bottles-hand-sanitizer.html"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Daily Mail
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-real.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://thereal.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Real
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/daytime-emmy-awards.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://theemmysawardlounge.tv/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Daytime Emmy Awards
                  </Link>
                </div>
              </Col>
            </Row>
            <h3>Tutorials</h3>
            <Row className='ptb-10'>
            <Col lg="4" sm="12">
                <div className="articles-content">
                  <div className='frame-wrapper'>
                    <video controls style={{ height: "420px" }}>
                      <source
                        src={require("../../assets/banners/kelly-clarkson-show.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                  <Link
                    to={{pathname:"https://www.kellyclarksonshow.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Kelly Clarkson Show
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-talk.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://www.cbs.com/shows/the_talk/"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Talk
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-doctors.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://www.thedoctorstv.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    The Doctor's
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/la-la-land.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <Link
                    to={{pathname:"https://goodmorninglalaland.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Good Morning La La Land
                  </Link>
                </div>
              </Col>
              <Col lg="4" sm="12">
                <div className="articles-content">
                  <div className='frame-wrapper'>
                    <iframe
                      className="video-frame"
                      height="420"
                      src="https://www.youtube.com/embed/oD92DqzfpOo"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <Link
                    to={{pathname:"https://www.boxycharm.com/"}}
                    target="_blank"
                    className="article-link"
                  >
                    Boxycharm
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        {/* SHOPPING COLLECTION */}
        <ShopCollection />
      </section>
    );
};

export default Press;