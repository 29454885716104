/* NEW ARRIVALS */
import BadassSummerBrowKitBack from "../assets/products/brow-01-back.webp";
import BadassSummerBrowKitFront from "../assets/products/brow-01-front.webp";
import ProbowPremadeKit from "../assets/products/brow-02.webp";
import QuickFixBrowKit from "../assets/products/brow-03.webp";
import RedCarpetBrowKitBack from "../assets/products/brow-04-back.webp";
import RedCarpetBrowKitFront from "../assets/products/brow-04-front.webp";
import BipolarBrowSetBack from "../assets/products/brow-05-back.webp";
import BipolarBrowSetFront from "../assets/products/brow-05-front.webp";
import BombshellBrowGel from "../assets/products/brow-06.webp";
import BombshellBrowEsssentialsNightBack from "../assets/products/brow-07-back.webp";
import BombshellBrowEsssentialsNightFront from "../assets/products/brow-07-front.webp";
import BombshellBrowEsssentialsDayBack from "../assets/products/brow-08-back.webp";
import BombshellBrowEsssentialsDayFront from "../assets/products/brow-08-front.webp";

const shopBrow = [
  {
    id: 1,
    productBackImg: BadassSummerBrowKitBack,
    productFrontImg: BadassSummerBrowKitFront,
    isOutOfStock: false,
    productName: "Badass Summer Brow Kit",
    productPrice: "$66.00",
  },
  {
    id: 2,
    productBackImg: ProbowPremadeKit,
    productFrontImg: ProbowPremadeKit,
    isOutOfStock: false,
    productName: "PROBROW Pomade Kit (Select Shade)",
    productPrice: "$28.00",
  },
  {
    id: 3,
    productBackImg: QuickFixBrowKit,
    productFrontImg: QuickFixBrowKit,
    isOutOfStock: true,
    productName: "Quick Fix Brow Kit (Select Shade)",
    productPrice: "$28.00",
  },
  {
    id: 4,
    productBackImg: RedCarpetBrowKitBack,
    productFrontImg: RedCarpetBrowKitFront,
    isOutOfStock: true,
    productName: "Red Carper Brow Kit",
    productPrice: "$36.00",
  },
  {
    id: 5,
    productBackImg: BipolarBrowSetBack,
    productFrontImg: BipolarBrowSetFront,
    isOutOfStock: true,
    productName: "BiPOLAR Brow Set",
    productPrice: "$32.00",
  },
  {
    id: 6,
    productBackImg: BombshellBrowGel,
    productFrontImg: BombshellBrowGel,
    isOutOfStock: false,
    productName: "Bombshell Brow Gel",
    productPrice: "$9.00",
  },
  {
    id: 7,
    productBackImg: BombshellBrowEsssentialsNightBack,
    productFrontImg: BombshellBrowEsssentialsNightFront,
    isOutOfStock: false,
    productName: "Bombshell Brow Essentials - Night Kit",
    productPrice: "$56.00",
  },
  {
    id: 8,
    productBackImg: BombshellBrowEsssentialsDayBack,
    productFrontImg: BombshellBrowEsssentialsDayFront,
    isOutOfStock: false,
    productName: "Bombshell Brow Essentials - Day Kit",
    productPrice: "$56.00",
  },
];

export default shopBrow;
