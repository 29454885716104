/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Select from "react-select";
import countries from "countries-list";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { affiliateProgram } from "../../service/api";
import { useSnackbar } from "notistack";

function emailValidation(body) {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (regex.test(body) === false) {
    return false;
  }
  return true;
}

const Affiliate = () => {
  // Use State for the lead data
  const [lead, setLead] = useState({
    fName: "",
    lName: "",
    username: "",
    phone: "",
    referralID: "",
    parentAffiliate: "",
    webURL: "",
    companyName: "",
    streetName: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
  });
  console.log('lead: ', lead);

  const [leadError, setLeadError] = useState({
    fName:false,
    lName:false,
    username:false,
    phone:false,
    referralID:false,
    parentAffiliate:false,
    webURL:false,
    companyName:false,
    streetName:false,
    city:false,
    state:false,
    country:false,
    zipcode:false,
    });

  let name, value;
  const { enqueueSnackbar } = useSnackbar();

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setLead({ ...lead, [name]: value });
    setLeadError({ ...leadError, [name]: false })
  };

  // Function to submit the data
  const submit = () => {
    console.log(lead);
    if (!lead.fName) {
      // enqueueSnackbar("Please Enter First name.", { variant: "error" });
      setLeadError({ ...leadError, fName: true })
      return;
    }
    if (!lead.lName) {
      // enqueueSnackbar("Please Enter  last name.", { variant: "error" });
      setLeadError({ ...leadError, lName: true })
      return;
    }
    if (!lead.username) {
      // enqueueSnackbar("Please Enter Email.", { variant: "error" });
      setLeadError({ ...leadError, username: true })
      return;
    }
    if (!emailValidation(lead.username)) {
      // enqueueSnackbar("Invalid Email Entered", { variant: "error" });
      setLeadError({ ...leadError, username: true })
      return;
    }
    if (!lead.phone) {
      // enqueueSnackbar("Please enter mobile no.", { variant: "error" });
      setLeadError({ ...leadError, phone: true })
      return;
    }
    if (!lead.referralID) {
      // enqueueSnackbar("Please enter Referral ID.", { variant: "error" });
      setLeadError({ ...leadError, referralID: true })
      return;
    }
    if (!lead.parentAffiliate) {
      // enqueueSnackbar("Please enter Parent Affiliate.", { variant: "error" });
      setLeadError({ ...leadError, parentAffiliate: true })
      return;
    }
    if (!lead.webURL) {
      // enqueueSnackbar("Please enter website URL.", { variant: "error" });
      setLeadError({ ...leadError, webURL: true })
      return;
    }
    if (!lead.companyName) {
      // enqueueSnackbar("Please enter website URL.", { variant: "error" });
      setLeadError({ ...leadError, companyName: true })
      return;
    }
    if (!lead.streetName) {
      // enqueueSnackbar("Please enter Street Name.", { variant: "error" });
      setLeadError({ ...leadError, streetName: true })
      return;
    }
    if (!lead.city) {
      // enqueueSnackbar("Please enter city.", { variant: "error" });
      setLeadError({ ...leadError, city: true })
      return;
    }
    if (!lead.state) {
      // enqueueSnackbar("Please enter state.", { variant: "error" });
      setLeadError({ ...leadError, state: true })
      return;
    }

    if (!lead.country.label) {
      // enqueueSnackbar("Please enter Country.", { variant: "error" });
      setLeadError({ ...leadError, country: true })
      return;
    }
    if (!lead.zipcode) {
      // enqueueSnackbar("Please enter Zip code.", { variant: "error" });
      setLeadError({ ...leadError, zipcode: true })
      return;
    }


    const submitForm = new FormData();
    submitForm.append("firstName", lead.fName);
    submitForm.append("lastName", lead.lName);
    submitForm.append("email", lead.username);
    submitForm.append("mobile", lead.phone);
    submitForm.append("referralID", lead.referralID);
    submitForm.append("companyName", lead.companyName);
    submitForm.append("websiteURL", lead.webURL);
    submitForm.append("streetName", lead.streetName);
    submitForm.append("city", lead.city);
    submitForm.append("state", lead.state);
    submitForm.append("country", lead.country.label);
    submitForm.append("pinCode", lead.zipcode);
    submitForm.append("parentAffiliate", lead.parentAffiliate);
    affiliateProgram(submitForm).then((res) => {
      console.log(res);
      if (res?.status) {
        setLead({
          ...lead,
          fName: "",
          lName: "",
          username: "",
          phone: "",
          referralID: "",
          parentAffiliate: "",
          webURL: "",
          companyName: "",
          streetName: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
        });
        enqueueSnackbar(res?.message, { variant: "success" });
      } else {
        enqueueSnackbar(res?.message, { variant: "error" });
      }
    });
  };

  const countriesList = [];
  Object.entries(countries?.countries).map((key) => {
    countriesList.push({ value: key[0], label: key[1].name });
  });

  return (
    <section className="affiliate ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Affiliate Sign Up | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>AFFILIATE SIGN UP</h2>
        <Row className="justify-content-center">
          <Col lg="8" sm="12">
            <form className="affiliate-form">
              <Row>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="fName">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={lead.fName}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="First Name"
                    name="fName"
                    id="fName"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.fName ? "First name is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="lName">
                    Last Name
                  </label>
                  <input
                    type="email"
                    value={lead.lName}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Last Name"
                    name="lName"
                    id="lName"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.lName ? "Last name is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="username">
                    Username (Email)
                  </label>
                  <input
                    type="email"
                    value={lead.username}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Username"
                    name="username"
                    id="username"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.username ? "Enter valid email" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="username">
                    Phone
                  </label>
                  <PhoneInput
                    placeholder="Phone"
                    value={lead.phone}
                    onChange={(e) => {
                      handleInputs({ target: { name: "phone", value: e } });
                    }}
                    defaultCountry="US"
                    name="phone"
                    className="phone-number-select"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red", position:'initial', marginLeft:'80px' }}
                    >
                      {leadError?.phone ? "Enter valid phone no." : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="referralID">
                    Referral ID
                  </label>
                  <input
                    type="text"
                    value={lead.referralID}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Referral ID"
                    name="referralID"
                    id="referralID"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.referralID ? "Referral Id is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="parentAffiliate">
                    Parent Affiliate
                  </label>
                  <input
                    type="email"
                    value={lead.parentAffiliate}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Parent Affiliate"
                    name="parentAffiliate"
                    id="parentAffiliate"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.parentAffiliate ? "Parental affliate is required" : ""}
                    </small>
                </Col>

                {/* ADDITIONAL INFORMATION */}
                <Col lg="12" sm="12">
                  <div className="affiliate-form-heading mb-2">
                    <h5>ADDITIONAL INFORMATION</h5>
                    <div className="divider-line" />
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="webURL">
                    Web URL
                  </label>
                  <input
                    type="text"
                    value={lead.webURL}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Web URL"
                    name="webURL"
                    id="webURL"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.webURL ? "Website URL is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="companyName">
                    Company Name
                  </label>
                  <input
                    type="email"
                    value={lead.companyName}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Company Name"
                    name="companyName"
                    id="companyName"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.companyName ? "Company is required" : ""}
                    </small>
                </Col>
                <Col lg="12" sm="12">
                  <label className="form-label" htmlFor="streetName">
                    Street Name
                  </label>
                  <input
                    type="email"
                    value={lead.streetName}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="Street Name"
                    name="streetName"
                    id="streetName"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.streetName ? "Street Name is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="city">
                    City
                  </label>
                  <input
                    type="text"
                    value={lead.city}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="City"
                    name="city"
                    id="city"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.city ? "City is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="state">
                    State
                  </label>
                  <input
                    type="text"
                    value={lead.state}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="State"
                    name="state"
                    id="state"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.state ? "State is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="country">
                    Country
                  </label>
                  <Select
                    options={countriesList}
                    value={lead.country}
                    onChange={(e) => {
                      handleInputs({ target: { name: "country", value: e } });
                    }}
                    classNamePrefix="select-control"
                    placeholder="Country"
                    name="country"
                    id="country"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red",position:'initial' }}
                    >
                      {leadError?.country ? "Country is required" : ""}
                    </small>
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="zipcode">
                    ZIP Code
                  </label>
                  <input
                    type="text"
                    value={lead.zipcode}
                    onChange={handleInputs}
                    className="form-control"
                    placeholder="ZIP"
                    name="zipcode"
                    id="zipcode"
                  />
                   <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.zipcode ? "Zip code is required" : ""}
                    </small>
                </Col>
                <Col lg="12" sm="12">
                  <Button className="submit-btn" onClick={submit}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Affiliate;
