/* NEW ARRIVALS */
import LoveBombLitGlossCollection from '../assets/products/new-arrivals-01.webp';
import HotChocolateMochaLipstickCollection from '../assets/products/new-arrivals-02.webp';
import RoyalRubyDreamsKit from "../assets/products/new-arrivals-03.webp";
import StarfireFuschiaKit from "../assets/products/new-arrivals-04.webp";
import DiamondsintheSnow from '../assets/products/new-arrivals-05.webp';
import BadassBrowlightsShimmer from '../assets/products/new-arrivals-06.webp';
import BadassSummerBrowKitFront from '../assets/products/new-arrivals-07-front.webp';
import BadassSummerBrowKitBack from "../assets/products/new-arrivals-07-back.webp";
import BrowGroomingKit from "../assets/products/new-arrivals-08.webp";

const newArrivals = [
  {
    id: 1,
    productBackImg: LoveBombLitGlossCollection,
    productFrontImg: LoveBombLitGlossCollection,
    isOutOfStock: true,
    productName: "Love Bomb Lit Hydrating Lip Gloss Collection",
    productPrice: "$33.00",
  },
  {
    id: 2,
    productBackImg: HotChocolateMochaLipstickCollection,
    productFrontImg: HotChocolateMochaLipstickCollection,
    isOutOfStock: true,
    productName: "Hot Chocolate Mocha Lipstick Collection",
    productPrice: "$35.00",
  },
  {
    id: 3,
    productBackImg: RoyalRubyDreamsKit,
    productFrontImg: RoyalRubyDreamsKit,
    isOutOfStock: true,
    productName: "Royal Ruby Dreams Kit",
    productPrice: "$31.00",
  },
  {
    id: 4,
    productBackImg: StarfireFuschiaKit,
    productFrontImg: StarfireFuschiaKit,
    isOutOfStock: true,
    productName: "Starfire Fuchsia Kit",
    productPrice: "$31.00",
  },
  {
    id: 5,
    productBackImg: DiamondsintheSnow,
    productFrontImg: DiamondsintheSnow,
    isOutOfStock: true,
    productName: "Diamonds in the Snow",
    productPrice: "$35.00",
  },
  {
    id: 6,
    productBackImg: BadassBrowlightsShimmer,
    productFrontImg: BadassBrowlightsShimmer,
    isOutOfStock: false,
    productName: "Badass and Browlights Shape & Shimmer Set",
    productPrice: "$40.00",
  },
  {
    id: 7,
    productBackImg: BadassSummerBrowKitBack,
    productFrontImg: BadassSummerBrowKitFront,
    isOutOfStock: false,
    productName: "Badass Summer Brow Kit",
    productPrice: "$66.00",
  },
  {
    id: 8,
    productBackImg: BrowGroomingKit,
    productFrontImg: BrowGroomingKit,
    isOutOfStock: false,
    productName: "Men's Brow Grooming Kit",
    productPrice: "$50.00",
  },
];

export default newArrivals;