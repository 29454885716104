import React from "react";
import Skeleton from 'react-loading-skeleton';
import { Button, Row, Col } from "react-bootstrap";
import { AiOutlineHeart } from "react-icons/ai";
import 'react-loading-skeleton/dist/skeleton.css';

const ProductLoading = () => {
    return (
      <Row>
        <Col lg="3" sm="12">
          <div className="product-loading">
            <div className="product-box">
              <div className="product-img">
                <Skeleton className="img-loading" />
              </div>
            </div>
            <div className="product-desc">
              <Skeleton className="product-name-loader" />
              <Skeleton className="ratings-loading" />
              <Button className="add-to-bag">
                <h5>Add To Bag</h5>
                <Skeleton className="price-loading" />
              </Button>
            </div>
            <div className="wishlist-option">
              <AiOutlineHeart className="wishlist-icon" />
            </div>
          </div>
        </Col>
        <Col lg="3" sm="12">
          <div className="product-loading">
            <div className="product-box">
              <div className="product-img">
                <Skeleton className="img-loading" />
              </div>
            </div>
            <div className="product-desc">
              <Skeleton className="product-name-loader" />
              <Skeleton className="ratings-loading" />
              <Button className="add-to-bag">
                <h5>Add To Bag</h5>
                <Skeleton className="price-loading" />
              </Button>
            </div>
            <div className="wishlist-option">
              <AiOutlineHeart className="wishlist-icon" />
            </div>
          </div>
        </Col>
        <Col lg="3" sm="12">
          <div className="product-loading">
            <div className="product-box">
              <div className="product-img">
                <Skeleton className="img-loading" />
              </div>
            </div>
            <div className="product-desc">
              <Skeleton className="product-name-loader" />
              <Skeleton className="ratings-loading" />
              <Button className="add-to-bag">
                <h5>Add To Bag</h5>
                <Skeleton className="price-loading" />
              </Button>
            </div>
            <div className="wishlist-option">
              <AiOutlineHeart className="wishlist-icon" />
            </div>
          </div>
        </Col>
        <Col lg="3" sm="12">
          <div className="product-loading">
            <div className="product-box">
              <div className="product-img">
                <Skeleton className="img-loading" />
              </div>
            </div>
            <div className="product-desc">
              <Skeleton className="product-name-loader" />
              <Skeleton className="ratings-loading" />
              <Button className="add-to-bag">
                <h5>Add To Bag</h5>
                <Skeleton className="price-loading" />
              </Button>
            </div>
            <div className="wishlist-option">
              <AiOutlineHeart className="wishlist-icon" />
            </div>
          </div>
        </Col>
      </Row>
    );
};

export default ProductLoading;