/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { State, Country } from "country-state-city";
import Select from 'react-select';
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from 'react-redux';
import { registrationForm } from "../../service/api";

/* ICON IMPORTS */
import { AiFillEye, AiFillEyeInvisible, AiOutlineInfoCircle } from "react-icons/ai";
import { useSnackbar } from "notistack";

const ProductDistributor = () => {
  const statesList = [];
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /* Tooltip */
  const tooltip = (
    <Tooltip id="tooltip">
      Please fill out this section only if you like to use company information
    </Tooltip>
  );

  /* STATES */
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [stepBtn, setStepBtn] = useState('step-1');
  const [loading, setLoading] = useState(false)


  /* STATES LIST */
  Object.entries(
    State.getStatesOfCountry("US").map((key) => {
      statesList.push({ value: key.name, label: key.name });
    })
  );

  /* COUNTRIES LIST */
  const [countriesList, setCountriesList] = useState([{ value: 'USA', label: 'USA' }]);

  useEffect(() => {
    const countries = Country.getAllCountries().map((country) => ({
      value: country.name,
      label: country.name,
    }));
    const tempCount = [...countriesList, ...countries]

    tempCount.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    setCountriesList(tempCount);
  }, []);

  /* BUSINESS ACTIVITY OPTIONS */
  const businessActivityOptions = [
    {
      value: "Promotional Products Distributor",
      label: "Promotional Products Distributor",
    },
    { value: "Decorator", label: "Decorator" },
    { value: "Brand", label: "Brand" },
  ];
  /* GENDER OPTIONS */
  const genderOptions = [{
    value: "Male",
    label: "Male"
  }, {
    value: "Female",
    label: "Female"
  }, {
    value: "Not to mention",
    label: "Not to mention"
  }]

  /* YEARS IN BUSINESS OPTIONS */
  const yearsInBusinessOptions = [{
    value: "1",
    label: "1"
  }, {
    value: "2",
    label: "2"
  }, {
    value: "3",
    label: "3"
  }, {
    value: "4",
    label: "4"
  }, {
    value: "5",
    label: "5"
  }]

  const { registrationDetails } = useSelector(state => state.user)

  const [input, setInput] = useState({
    title: "",
    companyCountry: "",

    firstName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    socialSecurityNumber: "",
    yearsOfExperience: "",
    personalAddress: "",
    personalAddressLine1: "",
    personalAddressCity: "",
    personalAddressState: "",
    personalAddressZipCode: "",
    personalAddressCountry: "",
    email: "",
    emailConfirm: "",
    password: "",
    passwordConfirm: "",

    //Company Information
    companyName: "",
    websiteURL: "",
    yearsInBusiness: "",
    companyEmailAddress: "",
    companyEmailAddressConfirm: "",
    primaryBusiness: "",
    federalTaxId: "",
    address: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    referralCode: "",
    passwordHint: "",
  })

  const [inputError, setInputError] = useState({
    title: false,
    companyCountry: false,

    firstName: false,
    lastName: false,
    phoneNumber: false,
    gender: false,
    socialSecurityNumber: false,
    yearsOfExperience: false,
    personalAddress: false,
    personalAddressLine1: false,
    personalAddressCity: false,
    personalAddressState: false,
    personalAddressZipCode: false,
    personalAddressCountry: false,
    email: false,
    emailConfirm: false,
    password: false,
    passwordConfirm: false,

    //Company Information
    companyName: false,
    websiteURL: false,
    yearsInBusiness: false,
    companyEmailAddress: false,
    companyEmailAddressConfirm: false,
    primaryBusiness: false,
    federalTaxId: false,
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,

    referralCode: false,
    passwordHint: false,
    documentFile: false,
  });

  // eslint-disable-next-line no-unused-vars
  const [companyInformation, setCompanyInformation] = useState(false);

  const onInputChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
    setInputError({ ...inputError, [e.target.name]: false })

    if (e.target.name === 'companyName') {

      if (e.target.value.trim().length !== 0) {
        setCompanyInformation(true)
        setInputError({
          ...inputError, companyName: false,
          websiteURL: false,
          yearsInBusiness: false,
          companyEmailAddress: false,
          companyEmailAddressConfirm: false,
          primaryBusiness: false,
          federalTaxId: false,
          address: false,
          addressLine1: false,
          city: false,
          state: false,
          zipCode: false,
        })
      } else {
        setCompanyInformation(false)
        setInputError({
          ...inputError, companyName: false,
          websiteURL: false,
          yearsInBusiness: false,
          companyEmailAddress: false,
          companyEmailAddressConfirm: false,
          primaryBusiness: false,
          federalTaxId: false,
          address: false,
          addressLine1: false,
          city: false,
          state: false,
          zipCode: false,
        })
        console.log("inputError", inputError)
      }
    }

  }

  useEffect(() => {
    setInput({ ...input, ...registrationDetails })
  }, [registrationDetails])

  useEffect(() => {
    console.log("input", input)
    console.log("registrationDetails", registrationDetails)

    // if(registrationDetails?.registered)
    // {
    //   dispatch(actions.setRegistraionDetails({ ...registrationDetails, ...input, registered: false}))
    // }
  }, [input, registrationDetails])

  function isEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isUrl(url) {
    // Regular expression for URL validation
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return urlRegex.test(url);
  }

  function isValidPhoneNumber(number) {
    const pattern = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    return pattern.test(number);
  }

  const callApiFun = () => {
    // Create a copy of the input and inputError objects
    const updatedInput = { ...input };
    const updatedInputError = { ...inputError };

    if (companyInformation === true) {
      // Check if the primaryBusiness field is empty
      if (!updatedInput.primaryBusiness) {
        updatedInputError.primaryBusiness = true;
      } else {
        updatedInputError.primaryBusiness = false;
      }

      // Check if the companyName field is empty
      if (!updatedInput.companyName) {
        updatedInputError.companyName = true;
      } else {
        updatedInputError.companyName = false;
      }
      console.log(isUrl(updatedInput.websiteURL))

      // Check if the websiteURL field is a valid URL
      if (!updatedInput.websiteURL || !isUrl(updatedInput.websiteURL)) {
        updatedInputError.websiteURL = true;
      } else {
        updatedInputError.websiteURL = false;
      }
      // Check if the companyEmailAddress field is a valid email address
      if (!updatedInput.companyEmailAddress || !isEmail(updatedInput.companyEmailAddress)) {

        updatedInputError.companyEmailAddress = true;
      } else {
        updatedInputError.companyEmailAddress = false;
      }

      // Check if the companyEmailAddressConfirm field matches the companyEmailAddress field
      if (updatedInput.companyEmailAddressConfirm !== updatedInput.companyEmailAddress) {
        updatedInputError.companyEmailAddressConfirm = true;
      } else {
        updatedInputError.companyEmailAddressConfirm = false;
      }

      updatedInputError.resaleLicenseNumber = false;
      updatedInputError.federalTaxId = false;


      // Check if the addressLine1 field is empty
      if (!updatedInput.address) {
        updatedInputError.address = true;
      } else {
        updatedInputError.address = false;
      }

      // Check if the country field is empty
      if (!updatedInput.country) {
        updatedInputError.country = true;
      } else {
        updatedInputError.country = false;
      }

      // Check if the state field is empty
      if (!updatedInput.state) {
        updatedInputError.state = true;
      } else {
        updatedInputError.state = false;
      }

      // Check if the city field is empty
      if (!updatedInput.city) {
        updatedInputError.city = true;
      } else {
        updatedInputError.city = false;
      }

      // Check if the zipCode field is empty
      if (!updatedInput.zipCode) {
        updatedInputError.zipCode = true;
      } else {
        updatedInputError.zipCode = false;
      }
    }


    if (documents.length === 0) {
      updatedInputError.documentFile = true;
    } else {
      updatedInputError.documentFile = false;
    }



    // Check if the firstName field is empty
    if (!updatedInput.firstName) {
      updatedInputError.firstName = true;
    } else {
      updatedInputError.firstName = false;
    }

    // Check if the lastName field is empty
    if (!updatedInput.lastName) {
      updatedInputError.lastName = true;
    } else {
      updatedInputError.lastName = false;
    }

    // Check if the phoneNumber field is empty
    if (!updatedInput.phoneNumber) {
      updatedInputError.phoneNumber = true;
    } else {
      updatedInputError.phoneNumber = false;
    }


    // Check if the email field is a valid email address
    if (!updatedInput.email || !isEmail(updatedInput.email)) {
      updatedInputError.email = true;
    } else {
      updatedInputError.email = false;
    }

    // Check if the emailConfirm field matches the email field
    if (updatedInput.emailConfirm !== updatedInput.email) {
      updatedInputError.emailConfirm = true;
    } else {
      updatedInputError.emailConfirm = false;
    }

    // Check if the password field is at least 8 characters long
    if (!updatedInput.password) {
      updatedInputError.password = true;
    } else {
      updatedInputError.password = false;
    }

    // Check if the password field is at least 8 characters long
    if (updatedInput.passwordConfirm !== updatedInput.password) {
      updatedInputError.passwordConfirm = true;
    } else {
      updatedInputError.passwordConfirm = false;
    }



    // Check if there are any errors in the updatedInputError object
    const hasErrors = Object.values(updatedInputError).some(error => error === true);

    // If there are errors, don't call the API
    if (hasErrors) {
      console.log("updatedInputError", updatedInputError)
      console.log("input", input)
      enqueueSnackbar("Please fill required Details", {
        variant: "error",
      });
      setInputError(updatedInputError);
      return;
    }
    setInputError(updatedInputError);
    console.log("input", input)
    console.log(registrationDetails)
    if ((!registrationDetails?.registered || !registrationDetails?.registrationId)) {
      const formData = new FormData();

      formData.append("firstName", input.firstName);
      formData.append("lastName", input.lastName);
      formData.append("phoneNumber", input.phoneNumber);
      formData.append("socialSecurityNumber", input?.socialSecurityNumber)
      formData.append("email", input.email);
      formData.append("password", input.password);
      if (input?.title) {
        formData.append("title", input.title);
      }
      if (input?.yearsOfExperience) {
        formData.append("yearsOfExperience", input?.yearsOfExperience);
      }
      if (input?.personalAddress) {
        formData.append("personalAddress", input.personalAddress);
      }
      if (input?.personalAddressLine1) {
        formData.append("personalAddressLine1", input.personalAddressLine1);
      }
      if (input?.personalAddressCity) {
        formData.append("personalAddressCity", input.personalAddressCity);
      }
      if (input?.personalAddressState) {
        formData.append("personalAddressState", input.personalAddressState);
      }
      if (input?.personalAddressZipCode) {
        formData.append("personalAddressZipCode", input.personalAddressZipCode);
      }
      if (input?.personalAddressCountry) {
        formData.append("personalAddressCountry", input.personalAddressCountry);
      }

      if (input?.passwordHint) {
        formData.append("passwordHint", input.passwordHint);
      }

      if (companyInformation) {
        formData.append("companyName", input.companyName);
        formData.append("websiteURL", input.websiteURL);
        formData.append("companyEmailAddress", input.companyEmailAddress);
        if (input?.yearsInBusiness)
          formData.append("yearsInBusiness", input.yearsInBusiness);
        if (input?.resaleLicenseNumber)
          formData.append("resaleLicenseNumber", input.resaleLicenseNumber);
        if (input?.federalTaxId)
          formData.append("federalTaxId", input.federalTaxId);
        formData.append("primaryBusiness", input.primaryBusiness);
        formData.append("address", input.address);
        if (input?.addressLine1)
          formData.append("addressLine1", input.addressLine1);
        formData.append("city", input.city);
        formData.append("state", input.state);
        formData.append("zipCode", input.zipCode);
        formData.append("country", input.country);
      }




      formData.append("documentFile", documents);


      setLoading(true);
      registrationForm(formData).then(res => {
        console.log("res?.data", res)
        if (res?.status) {
          enqueueSnackbar(res?.message, {
            variant: "success",
          });
          setStepBtn("step-2")
        }
        else {
          enqueueSnackbar(res?.message, {
            variant: "error",
          });
        }

        // dispatch(actions.setLoggedIn(true))

      })
        .catch((e) => {
          console.log("e", e)
          if (e?.response?.data?.message?.details?.length > 0)
            enqueueSnackbar(e?.response?.data?.message?.details[0]?.message, {
              variant: "error",
            });
          else
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
        })
        .finally((res) => {
          setLoading(false);
        });
    }
    else {
      // setStepBtn("step-2")
    }

  }

  //FILE UPLOAD





  const allowedFileTypes = ["jpg", "jpeg", "png", "webp", "gif", "doc", "pdf"];

  const handle1099FormFileUploadChange = (event) => {
    const file = event.target.files[0];
    console.log("file", file)

    const fileName = file.name.toLowerCase();
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);


    if (!allowedFileTypes.includes(fileType)) {
      enqueueSnackbar("Invalid file type. Please upload a file of type: " + allowedFileTypes.join(", "), {
        variant: "error",
      });
      const updatedInputError = { ...inputError };
      updatedInputError.documentFile = true;
      return;
    }

    if (file) {
      const updatedInputError = { ...inputError };
      console.log(updatedInputError)
      updatedInputError.documentFile = false;
      const reader = new FileReader();
      reader.onloadend = () => {
        setDocuments(file);
      };
      reader.readAsDataURL(file);
    }
  };




  return (
    <section className="wholesale-page">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Sales Representative    Registration | Browbar By Reema</title>
      </Helmet>

      <div className="container">
        <div className="page-wrap">
          <h1>SALES REPRESENTATIVE APPLICATION</h1>

          {/* FORM STEPS */}
          <div className="col-xl-12 col-sm-12">
            <div className="row mt-3">
              <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div
                    className={`step-number ${stepBtn === "step-1" ? "active" : ""
                      }`}
                  >
                    <h5>1</h5>
                  </div>
                  <div className="step-details">
                    <h5>Fill out Application</h5>
                    <p>Complete the form below.</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-sm-12">
                    <div className="step-box">
                      <div
                        className={`step-number ${stepBtn === "step-2" ? "active" : ""
                          }`}
                      >
                        <h5>2</h5>
                      </div>
                      <div className="step-details">
                        <h5>Provide Resale Certificate</h5>
                        <p>Send in required documents.</p>
                        <span>
                          (You will need a{" "}
                          <Link to="/resale-certificates">resale certificate</Link>{" "}
                          <br />
                          and valid sales tax license)
                        </span>
                      </div>
                    </div>
                  </div> */}
              <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div
                    className={`step-number ${stepBtn === "step-2" ? "active" : ""
                      }`}
                  >
                    <h5>2</h5>
                  </div>
                  <div className="step-details">
                    <h5>Account Review</h5>
                    <p>
                      You'll hear back from us in
                      <br />
                      1-2 business days.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* CRITERIA STEPS */}
          {/* <div className="col-xl-12 col-sm-12">
            <div className="details">
              <div className="row">
                <div className="col-xl-12 col-sm-12">
                  <h5>YOU MUST MEET THE FOLLOWING CRITERIA</h5>
                  <ol className="requirements-list">
                    <li>
                      You are a product distributor or salon (If this is not
                      you? You may still qualify, but need to apply
                      <Link to="">here</Link>).
                    </li>
                     <li>You are a US or Canadian resident.</li> 
                    <li>You have a valid sales tax license.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div> */}

          {/* APPLICATION FORM */}
          <div
            className={`application-form ${stepBtn === "step-1" ? "d-block" : "d-none"
              }`}
          >
            <div className="col-xl-12 col-sm-12">
              <div className="details mt-0">
                <form>
                  <div className="row">

                    <div className="col-xl-12 col-sm-12">
                      <div className="form">
                        <h5>PERSONAL INFORMATION</h5>
                        <div className="row">
                          {/* FIRST NAME */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                First Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="fName"
                                placeholder="First Name"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.firstName && <small className="validation-error">
                                First Name is required
                              </small>}
                            </div>
                          </div>
                          {/* LAST NAME */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Last Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                id="lName"
                                placeholder="Last Name"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.lastName && <small className="validation-error">
                                Last Name is required
                              </small>}
                            </div>
                          </div>
                          {/* PHONE NUMBER */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Phone <span style={{ color: "red" }}>*</span>
                              </label>
                              <PhoneInput
                                value={input?.phoneNumber}
                                onChange={(e) => {
                                  setInput({ ...input, 'phoneNumber': e })
                                  setInputError({ ...inputError, 'phoneNumber': false })
                                }}
                                placeholder="Phone Number"
                                defaultCountry="US"
                                className="phone-number-select"
                              />
                              {inputError?.phoneNumber && <small className="validation-error">
                                Phone number should be valid
                              </small>}
                            </div>
                          </div>
                          {/* GENDER */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Gender{" "}
                              </label>
                              <Select
                                options={genderOptions}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Gender"
                                isSearchable
                                value={genderOptions.find(option => option.value === input.gender)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    gender: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    gender: false
                                  });
                                }}
                              />
                              {inputError?.gender && <small className="validation-error">
                                Please select at least one option
                              </small>}
                            </div>
                          </div>
                          {/* SOCIAL SECURITY NUMBER */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Social Security Number <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="socialSecurityNumber"
                                id="socialSecurityNumber"
                                placeholder=" Social Security Number"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.socialSecurityNumber && <small className="validation-error">
                                SSN is required
                              </small>}
                            </div>
                          </div>
                          {/* YEARS OF EXPERIENCE */}
                          <div className="col-xl-2 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Years of Experience
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="yearsOfExperience"
                                id="yearsOfExperience"
                                placeholder="Years of Experience"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.yearsOfExperience && <small className="validation-error">
                                Years of Experience is required
                              </small>}
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS  */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address
                                {/* <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.personalAddress}
                                name="personalAddress"
                                onChange={(e) => onInputChange(e)}
                                id="personalAddress"
                                placeholder="Address"
                              />
                              {inputError?.personalAddress && <small className="validation-error">
                                Address is required
                              </small>}
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS LINE 1 */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address (Unit #)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.personalAddressLine1}
                                name="personalAddressLine1"
                                onChange={(e) => onInputChange(e)}
                                id="personalAddressLine1"
                                placeholder="Address (Unit #)"
                              />
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS COUNTRY */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Country
                                {/* <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span> */}
                              </label>
                              <Select
                                options={countriesList}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Country"
                                isSearchable
                                value={countriesList.find(option => option.value === input?.personalAddressCountry)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    personalAddressCountry: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    personalAddressCountry: false
                                  });
                                }}

                              />
                              {inputError?.personalAddressCountry && <small className="validation-error">
                                Please select atleast one country
                              </small>}
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS STATE */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Province/State{" "}
                                {/* <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.personalAddressState}
                                name="personalAddressState"
                                onChange={(e) => onInputChange(e)}
                                id="personalAddressState"
                                placeholder="State"
                              />
                              {/* <Select
                                options={statesList}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Province/State"
                                isSearchable
                                value={statesList.find(option => option.value === input.state)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    state: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    state: false
                                  });
                                }}
                              /> */}
                              {inputError?.personalAddressState && <small className="validation-error">
                                Please entered province/state
                              </small>}
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS CITY */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                City
                                {/* <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.personalAddressCity}
                                name="personalAddressCity"
                                onChange={(e) => onInputChange(e)}
                                id="personalAddressCity"
                                placeholder="City"
                              />
                              {inputError?.personalAddressCity && <small className="validation-error">
                                City is required
                              </small>}
                            </div>
                          </div>
                          {/* PERSONAL ADDRESS ZIPCODE */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Zip/Postal{" "}
                                {/* <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={input?.personalAddressZipCode}
                                name="personalAddressZipCode"
                                onChange={(e) => onInputChange(e)}
                                id="personalAddressZipCode"
                                placeholder="Zip/Postal Code"
                              />
                              {inputError?.personalAddressZipCode && <small className="validation-error">
                                ZIP/Postal Code is required
                              </small>}
                            </div>
                          </div>
                          {/* EMAIL ADDRESS */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email Address (this will be your account login
                                ID, or username){" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                id="accountEmail"
                                placeholder="placeholder@domain.com"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.email && <small className="validation-error">
                                Email Address is required
                              </small>}
                            </div>
                          </div>
                          {/* CONFIRM EMAIL ADDRESS */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Confirm Email Address{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                name="emailConfirm"
                                id="accountEmail"
                                placeholder="placeholder@domain.com"
                                onChange={(e) => onInputChange(e)}
                              />
                              {inputError?.emailConfirm && <small className="validation-error">
                                The email address doesn't match
                              </small>}
                            </div>
                          </div>
                          {/* PASSWORD */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Password <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type={showLoginPassword ? "text" : "password"}
                                className="form-control"
                                name="password"
                                id="accountPassword"
                                placeholder="Password"
                                onChange={(e) => onInputChange(e)}
                              />
                              <Button
                                className="password-icon"
                                onClick={() =>
                                  setShowLoginPassword(!showLoginPassword)
                                }
                              >
                                {showLoginPassword ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </Button>
                              {inputError?.password && <small className="validation-error">
                                Password is required
                              </small>}
                            </div>
                          </div>
                          {/* CONFIRM PASSWORD */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Confirm Password{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type={showLoginPassword ? "text" : "password"}
                                className="form-control"
                                name="passwordConfirm"
                                id="accountPassword"
                                placeholder="Password"
                                onChange={(e) => onInputChange(e)}
                              />
                              <Button
                                className="password-icon"
                                onClick={() =>
                                  setShowLoginPassword(!showLoginPassword)
                                }
                              >
                                {showLoginPassword ? (
                                  <AiFillEyeInvisible />
                                ) : (
                                  <AiFillEye />
                                )}
                              </Button>
                              {inputError?.passwordConfirm && <small className="validation-error">
                                The passwords do not match
                              </small>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* COMPANY INFORMATION */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="form">
                        <h5>COMPANY INFORMATION (Optional)<span> <OverlayTrigger placement="right" overlay={tooltip}>
                          <Button style={{ background: 'transparent', border: 'none', color: 'black', padding: '0px', marginTop: '-8px' }} ><AiOutlineInfoCircle /></Button>
                        </OverlayTrigger></span></h5>
                        <div className="row">
                          {/* COMPANY NAME */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Company Name{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.companyName}
                                name="companyName"
                                onChange={(e) => onInputChange(e)}
                                id="companyName"
                                placeholder="Company Name"

                              />
                              {inputError?.companyName && <small className="validation-error">
                                Company Name is required
                              </small>}
                            </div>
                          </div>
                          {/* WEBSITE URL */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Website
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.websiteURL}
                                name="websiteURL"
                                onChange={(e) => onInputChange(e)}
                                id="website"
                                placeholder="www.domain.com"
                              />
                              {inputError?.websiteURL && <small className="validation-error">
                                websiteURL is required
                              </small>}
                            </div>
                          </div>
                          {/* YEARS IN BUSINESS */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Years in Business{" "}
                              </label>
                              <Select
                                options={yearsInBusinessOptions}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Years in Business"
                                isSearchable
                                value={yearsInBusinessOptions.find(option => option.value === input.yearsInBusiness)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    yearsInBusiness: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    yearsInBusiness: false
                                  });
                                }}
                              />
                              {inputError?.yearsInBusiness && <small className="validation-error">
                                Please select at least one option
                              </small>}
                            </div>
                          </div>
                          {/* COMPANY EMAIL */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Company Email{" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                value={input?.companyEmailAddress}
                                name="companyEmailAddress"
                                onChange={(e) => onInputChange(e)}
                                id="companyEmail"
                                placeholder="placeholder@domain.com"
                              />
                              {inputError?.companyEmailAddress && <small className="validation-error">
                                Company Email is required
                              </small>}
                            </div>
                          </div>
                          {/* COMPANY EMAIL CONFIRM */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Confirm Company Email{" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                value={input?.companyEmailAddressConfirm}
                                name="companyEmailAddressConfirm"
                                onChange={(e) => onInputChange(e)}
                                id="companyEmail"
                                placeholder="placeholder@domain.com"
                              />
                              {inputError?.companyEmailAddressConfirm && <small className="validation-error">
                                The email addresses doesn't match
                              </small>}
                            </div>
                          </div>
                          {/* PRIMARY BUSINESS */}
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                What is your primary business activity?{" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                options={businessActivityOptions}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Business Activity"
                                isSearchable
                                value={businessActivityOptions.find(option => option.value === input.primaryBusiness)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    primaryBusiness: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    primaryBusiness: false
                                  });
                                }}
                              />
                              {inputError?.primaryBusiness && <small className="validation-error">
                                Please select atleast one option
                              </small>}
                            </div>
                          </div>
                          {/* RESALE LICENSE */}
                          {/* <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Resale License # (If multiple, use primary
                                state) <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.resaleLicenseNumber}
                                name="resaleLicenseNumber"
                                onChange={(e) => onInputChange(e)}
                                id="resaleLicense"
                                placeholder="Resale License #"
                              />
                              {inputError?.resaleLicenseNumber && <small className="validation-error">
                                Resale License is required
                              </small>}
                            </div>
                          </div> */}
                          {/* FEDERAL TAX ID */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Federal Tax ID (EIN){" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.federalTaxId}
                                name="federalTaxId"
                                onChange={(e) => onInputChange(e)}
                                id="federalTaxID"
                                placeholder="Federal Tax ID"
                              />
                              {inputError?.federalTaxId && <small className="validation-error">
                                Federal Tax ID is required
                              </small>}
                            </div>
                          </div>
                          {/* COMPANY ADDRESS */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.address}
                                name="address"
                                onChange={(e) => onInputChange(e)}
                                id="address"
                                placeholder="Address"
                              />
                              {inputError?.address && <small className="validation-error">
                                Address is required
                              </small>}
                            </div>
                          </div>
                          {/* COMPANY ADDRESS LINE 1 */}
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address (Unit #)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.addressLine1}
                                name="addressLine1"
                                onChange={(e) => onInputChange(e)}
                                id="addressUnit"
                                placeholder="Address (Unit #)"
                              />
                            </div>
                          </div>
                          {/* COMPANY CITY */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                City <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.city}
                                name="city"
                                onChange={(e) => onInputChange(e)}
                                id="city"
                                placeholder="City"
                              />
                              {inputError?.city && <small className="validation-error">
                                City is required
                              </small>}
                            </div>
                          </div>

                          {/* COMPANY STATE */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Province/State{" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={input?.state}
                                name="state"
                                onChange={(e) => onInputChange(e)}
                                id="state"
                                placeholder="State"
                              />
                              {/* <Select
                                options={statesList}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Province/State"
                                isSearchable
                                value={statesList.find(option => option.value === input.state)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    state: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    state: false
                                  });
                                }}
                              /> */}
                              {inputError?.state && <small className="validation-error">
                                Please entered province/state
                              </small>}
                            </div>
                          </div>

                          {/* COMPANY ZIP CODE */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Zip/Postal{" "}
                                <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                value={input?.zipCode}
                                name="zipCode"
                                onChange={(e) => onInputChange(e)}
                                id="zipCode"
                                placeholder="Zip/Postal Code"
                              />
                              {inputError?.zipCode && <small className="validation-error">
                                ZIP/Postal Code is required
                              </small>}
                            </div>
                          </div>
                          {/* COMPANY COUNTRY */}
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Country <span className={companyInformation ? '' : 'd-none'} style={{ color: "red" }}>*</span>
                              </label>
                              <Select
                                options={countriesList}
                                className="react-select mb-15"
                                classNamePrefix="select-control"
                                placeholder="Select Country"
                                isSearchable
                                value={countriesList.find(option => option.value === input.country)}
                                onChange={(selectedOption) => {
                                  setInput({
                                    ...input,
                                    country: selectedOption.value
                                  });
                                  setInputError({
                                    ...inputError,
                                    country: false
                                  });
                                }}

                              />
                              {inputError?.country && <small className="validation-error">
                                Please select atleast one country
                              </small>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ATTACHED 1099 Form */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="form mb-1">
                        <div className="d-flex w-100">
                          <h5>UPLOAD DOCUMENTS</h5><a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" className="cancel-btn " target={'_blank'} style={{ marginLeft: "auto", textDecoration: 'none' }} rel="noreferrer" >Sample W9 Form</a>
                        </div>
                        <div className="row mt-1">
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group mt-15">
                              <label className="form-label">
                                Please Upload your W9 Form
                              </label>
                              <div className="file-input">

                              </div>
                              <input
                                type="file"
                                className="file-control mt-2"
                                name="documentFile"
                                id="documentFile"
                                onChange={(e) => handle1099FormFileUploadChange(e)}
                              />
                              {/* {resellerLoading ?
                                <div style={{ padding: '20px' }}>
                                  <Spinner />
                                </div> :
                                <div style={{ width: '180px' }}>
                                  {resellerPermitPreview && (
                                    <div style={{ marginTop: '10px' }}>
                                      {resellerPermitPreview.endsWith('.pdf') ? (
                                        <iframe src={IMG_URL + resellerPermitPreview} width="120px%" height="120px"></iframe>
                                      ) : (
                                        <img src={IMG_URL + resellerPermitPreview} alt={feinNumber?.name} width={120} height={120} style={{ borderRadius: '2px' }} />
                                      )}
                                    </div>
                                  )}
                                  {resellerPermit && resellerPermitPreview && (
                                    <div>
                                      <a style={{
                                        fontSize: '12px',
                                        color: '#1111eb'
                                      }} href={URL.createObjectURL(resellerPermit)} target="_blank">
                                        {resellerPermit?.name}
                                      </a>{" "}
                                      <Button className="delete-btn-img" onClick={handleResellerPermitDelete}>
                                        <UilTrash color="red" size="18" />
                                      </Button>
                                    </div>
                                  )}
                                </div>} */}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12 download-btn">

                          </div>
                        </div>
                      </div>
                    </div>

                    {/* SUBMISSION */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="d-flex justify-content-between">
                        <Link to="/registration-landing">
                          <Button className="cancel-btn">CANCEL</Button>
                        </Link>
                        <Button
                          disabled={loading}
                          className="proceed-btn"
                          onClick={() => {
                            callApiFun()
                          }}
                        >
                          {loading ? "Loading..." : "AGREE & PROCEED"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* ACCOUNT REVIEW */}
          <div
            className={`account-review ${stepBtn === "step-2" ? "d-block" : "d-none"
              }`}
          >
            <div className="col-xl-12 col-sm-12">
              <div className="details mt-0">
                <form>
                  <div className="row">
                    {/* CONTACT INFORMATION */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="form">
                        <h5>PERSONAL INFORMATION</h5>
                        <div className="row">
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                First Name{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="fName"
                                placeholder="First Name"
                                value={input?.firstName}
                              />
                              {inputError?.firstName && <small className="validation-error">
                                First Name is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Last Name{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="lastName"
                                id="lName"
                                placeholder="Last Name"
                                value={input?.lastName}
                              />
                              {inputError?.lastName && <small className="validation-error">
                                Last Name is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Phone
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                placeholder="Phone Number"
                                className="form-control"
                                value={input?.phoneNumber}
                              />
                              {inputError?.phoneNumber && <small className="validation-error">
                                Phone number should be valid
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Gender
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                placeholder="Title"
                                value={input?.gender}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Social Security Number
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                placeholder="Social Security Number"
                                value={input?.socialSecurityNumber}
                              />
                            </div>
                          </div>
                          <div className="col-xl-2 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Years of Experience
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="title"
                                id="title"
                                placeholder="Years of Experience"
                                value={input?.yearsOfExperience}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Email Address (this will be your account login
                                ID, or username){" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="email"
                                className="form-control"
                                name="email"
                                id="accountEmail"
                                placeholder="placeholder@domain.com"
                                value={input?.email}
                              />
                              {inputError?.email && <small className="validation-error">
                                Email Address is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Social Security Number{" "}
                                {/* <span style={{ color: "red" }}>*</span> */}
                              </label>
                              <input
                                disabled
                                type="email"
                                className="form-control"
                                name="email"
                                id="accountEmail"
                                placeholder="Social Security Number"
                                value={input?.ssn}
                              />
                              {inputError?.email && <small className="validation-error">
                                Email Address is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="address"
                                id="address"
                                placeholder="Address"
                                value={input?.personalAddress}
                              />
                              {inputError?.address && <small className="validation-error">
                                Address is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address (Unit #)
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="addressLine1"
                                id="addressUnit"
                                placeholder="Address (Unit #)"
                                value={input?.personalAddressLine1}
                              />
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Province/State{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="state"
                                id="state"
                                placeholder="State"
                                value={input?.personalAddressState}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                City <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="city"
                                id="city"
                                placeholder="City"
                                value={input?.personalAddressCity}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Zip/Postal{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name="zipCode"
                                id="zipCode"
                                placeholder="Zip/Postal Code"
                                value={input?.personalAddressZipCode}
                              />
                              {inputError?.zipCode && <small className="validation-error">
                                ZIP/Postal Code is required
                              </small>}
                            </div>
                          </div>
                          {/* <Form.Check
                            label="I would like to receive the Terms and Conditions by email"
                            className="conditions-box"
                          checked={checkTerms}
                          onChange={(e) => setCheckTerms(e.target.checked)}
                          /> */}
                          {/* {inputError?.checkTerms && (
                                <small className="validation-error">
                                  You have to accept the terms and contisions
                                </small>
                              )} */}
                        </div>
                      </div>
                    </div>

                    {/* COMPANY INFORMATION */}
                    {companyInformation ? <div className="col-xl-12 col-sm-12">
                      <div className="form">
                        <h5>COMPANY INFORMATION</h5>
                        <div className="row">

                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Company Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="companyName"
                                id="companyName"
                                placeholder="Company Name"
                                value={input?.companyName}

                              />
                              {inputError?.companyName && <small className="validation-error">
                                Company Name is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Website <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="websiteURL"
                                id="website"
                                placeholder="www.domain.com"
                                value={input?.websiteURL}
                              />
                              {inputError?.websiteURL && <small className="validation-error">
                                websiteURL is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Years In Business <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="websiteURL"
                                id="website"
                                placeholder="Years in Business"
                                value={input?.yearsInBusiness}
                              />
                            </div>
                          </div>
                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Company Email{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="email"
                                className="form-control"
                                name="companyEmailAddress"
                                id="companyEmail"
                                placeholder="placeholder@domain.com"
                                value={input?.companyEmailAddress}
                              />
                              {inputError?.companyEmailAddress && <small className="validation-error">
                                Company Email is required
                              </small>}
                            </div>
                          </div>

                          <div className="col-xl-4 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                What is your primary business activity?{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                value={input?.primaryBusiness}
                              />
                              {inputError?.primaryBusiness && <small className="validation-error">
                                Please select atleast one option
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Resale License # (If multiple, use primary
                                state)
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="resaleLicenseNumber"
                                id="resaleLicense"
                                placeholder="Resale License #"
                                value={input?.resaleLicenseNumber}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Federal Tax ID (EIN){" "}

                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="federalTaxId"
                                id="federalTaxID"
                                placeholder="Federal Tax ID"
                                value={input?.federalTaxId}
                              />
                            </div>
                          </div>


                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="address"
                                id="address"
                                placeholder="Address"
                                value={input?.address}
                              />
                              {inputError?.address && <small className="validation-error">
                                Address is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Address (Unit #)
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="addressLine1"
                                id="addressUnit"
                                placeholder="Address (Unit #)"
                                value={input?.addressLine1}
                              />
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Province/State{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="state"
                                id="state"
                                placeholder="State"
                                value={input?.state}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                City <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control"
                                name="city"
                                id="city"
                                placeholder="City"
                                value={input?.city}
                              />
                              {inputError?.city && <small className="validation-error">
                                City is required
                              </small>}
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-12">
                            <div className="form-group">
                              <label className="form-label">
                                Zip/Postal{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name="zipCode"
                                id="zipCode"
                                placeholder="Zip/Postal Code"
                                value={input?.zipCode}
                              />
                              {inputError?.zipCode && <small className="validation-error">
                                ZIP/Postal Code is required
                              </small>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> : ''}





                  </div>




                  {/* SUBMISSION */}
                  <div className="col-xl-12 col-sm-12 mt-2">
                    <div className="d-flex justify-content-between">

                      <Button className="cancel-btn" onClick={() => navigate('/')}>BACK</Button>

                      <Button
                        className="proceed-btn"
                        onClick={() => {
                          enqueueSnackbar("Form Submitted Successfully", {
                            variant: "success",
                          });
                          setStepBtn("step-3")
                        }}

                      >
                        SUBMIT
                      </Button>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>

          {/* SUCCESS */}
          <div
            className={`account-review ${stepBtn === "step-3" ? "d-block" : "d-none"
              }`}
          >

            <div className="col-xl-12 col-sm-12">

              <div className="details mt-3">
                <div className="form">
                  <p className="justify-content-center text-center align-content-center" ><img width={'60px'} src={require("../../assets/icons/checked.png")} alt={'success icon'} /></p>
                  <h3 className="justify-content-center text-center align-content-center">Your Details have been saved successfully.</h3>
                  <p className="justify-content-center text-center align-content-center">You'll hear back from us in
                    1-2 business days.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div >
    </section >
  );
};

export default ProductDistributor;