/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import actions from "../redux/products/actions";
import { cartList } from "../service/api";
// import actions from "../redux/products/actions";
// import { cartList } from "../service/api";

export const useUpdateCart = () => {
  const [updateCart, setUpdateCart] = useState(false);
  // console.log('updateCart: ', updateCart);
  const dispatch = useDispatch();
  const getCartData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
    };
    cartList(params).then((res) => {
      console.log("callAPi", res);
        setUpdateCart(false);

      if (res?.status) {
        dispatch(actions.setCartData(res?.data?.docs));
      }
    });
  };
  useEffect(() => {
    if (updateCart) {
      getCartData();
    }
  }, [updateCart]);
  return {
    updateCart,
    setUpdateCart,
  };
};
