/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React,{useState, useEffect} from "react";
import { Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import newArrivals from '../../data/new-arrivals';
import Select from "react-select";
import { getAllProducts } from "../../service/api";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerComponent from "../../components/Spinner";
import ProductLoading from "../../components/loading-product";

const options = [
  { value: 'Position', label: 'Position' },
  { value: 'Product Name', label: 'Product Name' },
  { value: 'Price', label: 'Price' }
];

const NewArrivals = () => {
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(true);
  const [totalResults, setTotalResults] = useState(null);

  useEffect(() => {
    
      setLoading(true);

      const obj = {};
      obj.page = page;
      obj.sizePerPage = pageSize;

      // if (products?.search)
      //   obj.search = products?.search

      getAllProducts(obj).then((res) => {
        if (res?.status) {
          setAllProducts([...allProducts, ...res?.data[0]?.docs]);
          setTotalResults(res?.data[0]?.metadata[0]?.total)
          console.log("products--->total pdt", res?.data[0]?.docs)
        }
        setLoading(false)
      })
        .catch(err => {
          console.log("err", err)
        })
        .finally(err => {
          setLoading(false)
        }) 
  }, []);


  useEffect(() => {
    if (page > 1) {
      setLoading(true);

      const obj = {};
      obj.page = page;
      obj.sizePerPage = pageSize;

      // if (products?.search)
      //   obj.search = products?.search

      getAllProducts(obj).then((res) => {
        if (res?.status) {
          setAllProducts([...allProducts, ...res?.data[0]?.docs]);
          setTotalResults(res?.data[0]?.metadata[0]?.total)
          console.log("products--->total pdt", res?.data[0]?.docs)
        }
        setLoading(false)
      })
        .catch(err => {
          console.log("err", err)
        })
        .finally(err => {
          setLoading(false)
        })
    }
  }, [page]);

  return (
    <section className="new-arrivals ptb-30">
      <Helmet>
        <title>New Arrivals | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>NEW ARRIVALS</h2>
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <div className="banner">
              <img
                src={require("../../assets/banners/new-arrivals.gif")}
                alt="New Arrivals | Brow Bar by Reema"
              />
            </div>
          </Col>
          <Col lg="6" sm="12">
            <div className="heading">
              <p>
                Just in! Browse through Brow Bar by Reema's newest arrivals
                made to bring out your best features! From brow, lip, to face,
                we've got you covered to glam and shine. Discover how to best
                glow with our latest collections.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            <div className="product-stats">
              <p>We have total 68 products</p>
              {/* <Select
                options={options}
                classNamePrefix="select-control"
                placeholder="Sort by"
                name="sort-by"
                id="sort-by"
              /> */}
            </div>
            <InfiniteScroll
                dataLength={allProducts.length}
                next={() => setPage(page + 1)}
                hasMore={allProducts.length < totalResults}
                loader={loading && <ProductLoading />}
              >
            <Row>
              {newArrivals.map((product) => {
                return (
                  <Col lg="3" sm="12" key={product.id}>
                    <ProductBox
                      isOutOfStock={product.isOutOfStock}
                      productFrontImg={product.productFrontImg}
                      productBackImg={product.productBackImg}
                      productName={product.productName}
                      productPrice={product.productPrice}
                      productId={product?._id}
                      colorId={product?.productImage?.color}
                    />
                  </Col>
                );
              })}
            </Row>
            </InfiniteScroll>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default NewArrivals;