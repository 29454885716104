import React from 'react';
import { Button, Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

const VideoBox = (props) => {
  console.log(props.videoLinks);
  return (
    <section className='video-box-section'>
      {props?.videoLinks?.length > 0 ?

        <Swiper
          modules={[Scrollbar, Navigation, Pagination]}
          navigation={true}
          slidesPerView={1}
          spaceBetween={5}
        >
          {props?.videoLinks?.map((link, index) => {
            return (
              <SwiperSlide key={index}>
                <Card className="diy-brows-product-card">
                  <Card.Body className="p-0">
                    <div className="product-img" style={{ height: '310px' }}>
                      <img
                        src={link?.imageURL}
                        alt="browquiz"
                        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                      />
                    </div>
                    <div className="product-desc">
                      <h5 style={{ fontSize: '20px' }}>{link?.heading}</h5>
                      <p>{link?.paragraph}</p>
                    </div>
                  </Card.Body>
                  <Card.Footer
                    className="p-0"
                    style={{ borderTop: "0px solid transparent" }}
                  >
                    <a href={link?.url} >
                      <div className="product-desc">
                        <Button className="add-to-bag">
                          <h5>Watch Now</h5>
                        </Button>
                      </div>
                    </a>
                  </Card.Footer>
                </Card>
              </SwiperSlide>
            )
          })}


        </Swiper>

        : <p>No Videos</p>}


    </section>
  );
}

export default VideoBox;
