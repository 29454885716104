/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from 'react-bootstrap';
import ProductBox from '../../components/product-box';
import { useDispatch } from "react-redux";
import productActions from "../../redux/products/actions";
import { getWishList } from "../../service/api";
import InfiniteScroll from "react-infinite-scroll-component";
import ProductLoading from "../../components/loading-product";

const Wishlist = () => {
  const dispatch = useDispatch();
  const [wishList, setwishList] = useState([])
  const [totalResults, setTotalResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1)
  useEffect(() => {
    setLoading(true)
    getWishList({
      page: page,
      sizePerPage: 12
    }).then(res => {

      if (res?.status) {
        setwishList([...wishList, ...res?.data?.docs])
        setTotalResults(res?.data?.totalDocs)
        dispatch(productActions.setWishList(res?.data?.totalDocs));
        console.log(res?.data?.docs)
        setLoading(false)
      }
    })
    .finally(res =>{
      setLoading(false)
    })

  }, [page])

  const reload = () => {
    setLoading(true)
    setwishList([])
    getWishList({
      page: 1,
      sizePerPage: 12,
    }).then((res) => {
      if (res?.status) {
        setwishList(res?.data?.docs)
        setTotalResults(res?.data?.totalDocs);
        dispatch(productActions.setWishList(res?.data?.totalDocs));
        console.log(res?.data?.docs);
      }
    })
    .finally(res =>{
      setLoading(false)
    })
  }

  return (
    <section className="wishlist ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Wishlist | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>WISHLIST</h2>
        {loading ? <ProductLoading/> :
          wishList?.length === 0 ?
            <Row className="mt-12">
              <h6 style={{ textAlign: 'center', marginTop: '5px' }}>You wishlist is empty</h6>
            </Row> :
            <InfiniteScroll
              dataLength={wishList?.length}
              next={() => {
                console.log("wishlistlength---->", wishList?.length)
                console.log("wishlistlength--totalResults-->", totalResults)
                setPage(page + 1)
              }}
              hasMore={wishList?.length < totalResults}
              loader={loading && <ProductLoading />}
              style={{
                overflowX: 'hidden',
                overflowY: 'hidden'
              }}
            >
              <Row className="mt-3">
                {wishList?.map((product) => {
                  return (
                    <Col lg="3" sm="12" key={product.id}>
                      <ProductBox
                        isOutOfStock={product.isOutOfStock}
                        productFrontImg={product?.product?.productImage?.filter(
                          (x) => x?.color === product?.color?._id
                        )[0]?.front[0]}
                        productBackImg={product?.product?.productImage?.filter(
                          (x) => x?.color === product?.color?._id
                        )[0]?.back[0]}
                        productName={product?.product?.name}
                        productPrice={product.product?.amount}
                        productId={product?.product?._id}
                        colorId={product?.color?._id}
                        wishlistObj={product?.color?._id}
                        reload={reload}
                        refresh={true}
                        product={product?.product}
                        ratings={product?.productReview?.length}
                      />
                    </Col>
                  );
                })}
              </Row>
            </InfiniteScroll>
        }
      </div>
    </section>
  );
};

export default Wishlist;