import React from "react";
import Header from "./header/new-index";
import Footer from "./footer/index";

const Layout = ({ children }) => {
    return (
        <main>
            <Header />
            <div className="main">{children}</div>
            <Footer />
        </main>
    );
};

export default Layout;