import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import bestsellers from "../../data/bestsellers";

const ShopBestsellers = () => {
  return (
    <section className="bestsellers ptb-30">
      <Helmet>
        <title>Bestsellers | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>BESTSELLERS COLLECTION</h2>
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <div className="banner">
              <img
                src={require("../../assets/banners/bestsellers-banner.jpg")}
                alt="Face Collection | Brow Bar by Reema"
              />
            </div>
          </Col>
          <Col lg="6" sm="12">
            <div className="heading">
              <p>
                Shop Brow Bar by Reema's best sellers. Discover which products
                customers are raving about from our best selling Bombshell Brow
                Kit, Brow Makeover Kit, Microblade Pen, to more!
              </p>
            </div>
          </Col>
        </Row>
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            <div className="product-stats">
              <p>We have total 17 products</p>
              {/* <Select
                options={options}
                classNamePrefix="select-control"
                placeholder="Sort by"
                name="sort-by"
                id="sort-by"
              /> */}
            </div>
            <Row>
              {bestsellers.map((product) => {
                return (
                  <Col lg="3" sm="12" key={product.id}>
                    <ProductBox
                      isOutOfStock={product.isOutOfStock}
                      productFrontImg={product.productFrontImg}
                      productBackImg={product.productBackImg}
                      productName={product.productName}
                      productPrice={product.productPrice}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ShopBestsellers;
