/* eslint-disable new-parens */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { postContact } from '../../service/api';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useSnackbar } from 'notistack';
import SpinnerComponent from "../../components/Spinner";

function emailValidation(body) {
  const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (regex.test(body) === false) {

    return false;
  }
  return true;
}

function phoneValidation(body) {
  const regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
  if (regex.test(body) === false) {
    return false;
  }
  return true;
}

const ContactUs = () => {
  const [lead, setLead] = useState({
    fName: "", lName: "", email: "", phone: "", message: ""
  });
  const [leadError, setLeadError] = useState({
    fName: false, lName: false, email: false, phone: false, message: false
  });

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)
  let name, value;

  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setLead({ ...lead, [name]: value });
    setLeadError({ ...leadError, [name]: false })
    // console.log(lead)
  }

  const submit = () => {
    if (lead.fName === "") {
      // enqueueSnackbar("Please enter your first name", {variant:"error"})
      setLeadError({ ...leadError, fName: true })
      return;
    }
    if (lead.lName === "") {
      // enqueueSnackbar("Please enter your Last Name", {variant:"error"})
      setLeadError({ ...leadError, lName: true })
      return;
    }
    else if (lead.email === "") {
      // enqueueSnackbar("Please enter your email", {variant:"error"})
      setLeadError({ ...leadError, email: true })
      return;
    }

    else if (!emailValidation(lead.email)) {
      // enqueueSnackbar("Invalid Email Entered", { variant: "error" });
      setLeadError({ ...leadError, email: true })
      return;
    }
    else if (lead.phone === "") {
      // enqueueSnackbar("Please enter Phone number", {variant:"error"})
      setLeadError({ ...leadError, phone: true })
      return;
    }
    else if (!phoneValidation(lead.phone)) {
      // enqueueSnackbar("Invalid Phone Number entered", {variant: "error"})
      setLeadError({ ...leadError, phone: true })
      return;
    }
    else if (lead.message === "") {
      // enqueueSnackbar("Please enter the message", {variant:"error"})
      setLeadError({ ...leadError, message: true })
      return;
    }


    const submitForm = new FormData;
    submitForm.append("firstName", lead.fName);
    submitForm.append("lastName", lead.lName);
    submitForm.append("email", lead.email);
    submitForm.append("mobile", lead.phone);
    submitForm.append("message", lead.message);
    console.log(lead)

    setLoading(true)
    postContact(submitForm).then(res => {

      console.log("res--->", res)
      enqueueSnackbar(res?.message, { variant: "success" });
      setLead({ fName: "", lName: "", email: "", phone: "", message: "" })

    })
      .catch(e => {
        console.log("error--->", e)
        enqueueSnackbar("Something went wrong. Please try it again.", { variant: 'error' });
      })
      .finally(res => {
        setLoading(false)
      })
  }
  return (
    <section className="contact-us ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Contact Us | Brow Bar by Reema</title>
      </Helmet>
      {loading ?
        <SpinnerComponent /> :
        <div className="container">
          <h2>CONTACT US</h2>
          <p>
            Email us for any queries at{" "}
            <a
              href="mailto:hello@browbarbyreema.com"
              style={{ textDecoration: "underline", color: "black" }}
            >
              hello@browbarbyreema.com
            </a>
          </p>
          <p>
            For S.H.A.P.E.S Membership inquiries and requests, please email us{" "}
            <a
              href="mailto:membership@shapesbrowbar.com"
              style={{ textDecoration: "underline", color: "black" }}
            >
              here
            </a>
            .
          </p>
          <Row className="justify-content-center">
            <Col lg="8" sm="12">
              <form className="contact-us-form">
                <Row>
                  <Col lg="6" sm="12">
                    <label className="form-label" htmlFor="fName">
                      First Name
                    </label>
                    <input
                      // value={input?.firstname}
                      type="text"
                      value={lead.fName}
                      onChange={handleInputs}
                      className="form-control"
                      placeholder="First Name"
                      name="fName"
                      id="fName"
                    />
                    <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.fName ? "First name is required" : ""}
                    </small>
                  </Col>
                  <Col lg="6" sm="12">
                    <label className="form-label" htmlFor="lName">
                      Last Name
                    </label>
                    <input
                      // value={input?.lastname}
                      type="text"
                      value={lead.lName}
                      onChange={handleInputs}
                      className="form-control"
                      placeholder="Last Name"
                      name="lName"
                      id="lName"
                    />
                     <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.lName ? "Last name is required" : ""}
                    </small>
                  </Col>
                  <Col lg="6" sm="12">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      // value={input?.email}
                      type="email"
                      value={lead.email}
                      onChange={handleInputs}
                      className="form-control"
                      placeholder="Email"
                      name="email"
                      id="email"
                    />
                     <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.email ? "Enter valid email" : ""}
                    </small>
                  </Col>
                  <Col lg="6" sm="12">
                    <label className="form-label" htmlFor="username">
                      Phone
                    </label>
                    <PhoneInput
                      value={lead.phone}
                      onChange={(e) => { handleInputs({ target: { name: "phone", value: e } }) }}
                      placeholder="Phone"
                      defaultCountry="US"
                      name="phone"
                      className="phone-number-select"
                    />
                     <small
                      className="validation-error"
                      style={{ color: "red", position:'initial', marginLeft:'80px' }}
                    >
                      {leadError?.phone ? "Enter valid phone no." : ""}
                    </small>
                  </Col>
                  <Col lg="12" sm="12">
                    <label className="form-label" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      rows='5'
                      value={lead.message}
                      onChange={handleInputs}
                      name='message'
                      id="message"
                      className="textarea-control"
                      placeholder="Message"
                    />
                     <small
                      className="validation-error"
                      style={{ color: "red" }}
                    >
                      {leadError?.message ? "Message is required" : ""}
                    </small>
                  </Col>
                  <Col lg="12" sm="12">
                    <Button className="submit-btn" onClick={submit}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>}
    </section>
  );
};

export default ContactUs;