/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";
import { getAllProductsCombined } from "../../../service/api";

/* COMPONENT IMPORTS */
import ProductBox from "../../../components/product-box";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

const Bestsellers = () => {

  const [allProducts, setAllProducts] = useState([]);
  console.log("allProducts: ", allProducts);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const { category } = useSelector((state) => state.products);
  const [selectedCategory, setSelectedCategory] = useState(null);
  console.log('selectedCategory: ', selectedCategory);

  const getProductList = () => {
    let params = {
      page: 1,
      sizePerPage: pageSize,
    };
    getAllProductsCombined(params)
      .then((res) => {
        if (res?.status) {
          setAllProducts(res?.data[0]?.docs);
          setTotalResults(res?.data[0]?.metadata[0]?.total);
          console.log("total results", res?.data[0]?.metadata[0]?.total)
        }
        console.log("products--->", res?.data[0]?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getProductList();
  }, []);


  return (
    <section className="bestsellers ptb-30">
      <div className="container">
        <div className="section-heading">
          <h2>SHOP BESTSELLERS</h2>
          <Link to="/shop" className="shop-products">
            Shop All Products
          </Link>
        </div>

        <Row>
          <Swiper
            modules={[Scrollbar, Navigation, Pagination]}
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 5,
              },
            }}
            navigation={true}
            slidesPerView={5}
            spaceBetween={30}
          >
            {allProducts?.map((product) => {
              return (
                <Col lg="3" sm="12" key={product.id}>
                  <SwiperSlide>
                    <ProductBox
                      isOutOfStock={product}
                      productFrontImg={product?.productImage?.[0]?.front[0]}
                      productBackImg={product?.productImage?.[0]?.back[0]}
                      productName={product.name + ' - ' + product?.color?.[0]?.name}
                      productPrice={product.amount}
                      productId={product?._id}
                      colorId={product?.productImage?.[0].color}
                      wishlistObj={product?.WishlistProduct?._id}
                      refresh={false}
                      product={product}
                      ratings={product?.productReview?.length}
                    />
                  </SwiperSlide>
                </Col>
              );
            })}
          </Swiper>
        </Row>
      </div>
    </section>
  );
};

export default Bestsellers;
