import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { IMG_URL } from "../../../config";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
let video1URL;
let video2URL;
const Banners = ({ moduleData }) => {
  for (let i = 0; i < moduleData?.length; i++) {
    if (moduleData[i]?.modelID?.isDeleted === false && moduleData[i]?.modelID?.moduleName === 'First Video') {
      video1URL = moduleData[i]?.modelID?.source[0]?.url;
    }
    if (moduleData[i]?.modelID?.isDeleted === false && moduleData[i]?.modelID?.moduleName === 'Second Video') {
      video2URL = moduleData[i]?.modelID?.source[0]?.url;
    }
  }


  return (
    <section className="banners">
      {
        moduleData?.length > 0 ?
          <Swiper
            modules={[Scrollbar, Navigation, Pagination]}
            navigation={true}
            slidesPerView={1}
            spaceBetween={30}
          >
            {/* <SwiperSlide>
              <div className="banner-video">
                <img
                
                  src={'https://storage.googleapis.com/cdn-browbarbyreema/content-management/banner-image-homepage.jpg'}
                  alt="banner"
                  className="banner-image"
                />
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              <div className="banner-video">
                <video controls muted autoPlay loop>

                  <source
                    src={`${IMG_URL + video1URL}`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="banner-video">
                <video controls muted autoPlay loop>

                  <source
                    src={`${IMG_URL + video2URL}`}
                    type="video/mp4"
                  />
                </video>
              </div>
            </SwiperSlide>
          </Swiper> :
          <div className="banner-video">
            <video controls muted autoPlay loop>
              <source
                src={require("../../../assets/banners/banner-video-01.mp4")}
                type="video/mp4"
              />
            </video>
          </div>
      }

    </section>
  );
};

export default Banners;