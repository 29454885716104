/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useSnackbar } from 'notistack';
import { getQuizDetails,postQuiz } from '../../service/api';
import SpinnerComponent from "../../components/Spinner";


const BrowQuiz = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [questionsArray, setQuestionsArray] = useState([]);
  const [quizHeader, setQuizHeader] = useState('');
  const [quizParagraph, setQuizParagraph] = useState('');
  const [quizImage, setQuizImage] = useState('');
  const id = "63bec7f5888fdf6e06500e25"
  useEffect(() => {
    getQuizDetails(id).then(res => {
      if (res?.data) {
        console.log()
        setQuizHeader(res?.data.quizList[0].title)
        setQuizImage(res?.data.quizList[0].quizImg)
        setQuizParagraph(res?.data.quizList[0].description)
        setQuestionsArray(res?.data.quizList[0].questions)
      }
    })
  }, [])

  const [loading, setLoading] = useState(false)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState({})
  const [answers, setAnswers] = useState([{}]);
  let [userEmail, setUserEmail] = useState('');

  function emailValidation(body) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  function loadQuestions() {
    setQuestions(questionsArray)
  }

  function handleNext() {
    setLoading(true)

    setTimeout(() => {
      setLoading(false)

    }, 1250)
    let incrementCurrentQuestionIndex = currentQuestionIndex + 1
    setCurrentQuestionIndex(incrementCurrentQuestionIndex)

  }

  function onChangeOption(value) {
    let newAnswers = [...answers]
    newAnswers[currentQuestionIndex] = {
      questionId: questions[currentQuestionIndex]._id,
      answerId: value

    }
    setAnswers(newAnswers)
  }

  const handleInputs = (e) => {
    setUserEmail(e.target.value);
  }
  const submit = () => {
    console.log("answers",answers)
    const submitForm = new FormData();
    submitForm.append("email", userEmail);
    submitForm.append("quizId", id)
    submitForm.append("sheet", JSON.stringify(answers));

    if (!emailValidation(userEmail)) {
      enqueueSnackbar("Invalid Email Entered", { variant: "error" });
      return;
    }
    setLoading(true)
    console.log(submitForm)

    postQuiz(submitForm).then(res => {
      console.log("res--->", res)
      enqueueSnackbar(res?.message, { variant: "success" });
     

      setTimeout(()=>{
        window.location.reload();
        setUserEmail("")
      }, 1500)
    })
      .catch(e => {
        console.log("error--->", e)
        enqueueSnackbar("Something went wrong. Please try it again.", { variant: 'error' });
      })
      .finally(res => {
        setLoading(false)
      })
  }

  const imageSrcUrl = "https://storage.googleapis.com/cdn-browbarbyreema/";
  if (currentQuestionIndex >= questions.length) {
    return (
      <section className="brow-quiz">
        <Helmet>
          <title>Brow Quiz | Browbar By Reema</title>
        </Helmet>

        <div className="container">
          <Row className="brow-quiz align-items-center">
            <Col lg="6" sm="12" className="p-0">
              <img
                src={imageSrcUrl + quizImage}
                className="quiz-banner"
                alt="Brow Quiz"
              />
            </Col>
            <Col lg="6" sm="12">
              <div className="quiz-sections">
                {loading ?
                  <SpinnerComponent /> : <div
                    className={
                      "section"
                    }
                  >
                    <div>
                      <h4>
                        SUBMIT YOUR EMAIL TO FIND OUT YOUR BROW QUIZ RESULTS!
                      </h4>
                      <input
                        type="email"
                        className="form-control mt-2"
                        placeholder="Email"
                        onChange={handleInputs}
                        name="email"
                        id="email"
                      />
                      <Button className="take-quiz" onClick={submit}>Submit</Button>
                      <p className="small-text">
                        * Your are signing up to receive our emails and can
                        unsubscribe anytime
                      </p>
                    </div>
                  </div>
                }

              </div>
            </Col>
          </Row>

        </div>
      </section>
    )
  }
  if (!questions.length) {
    return <section className="brow-quiz">
      <Helmet>
        <title>Brow Quiz | Browbar By Reema</title>
      </Helmet>

      <div className="container">
        <Row className="brow-quiz align-items-center">
          <Col lg="6" sm="12" className="p-0">
            <img
              src={imageSrcUrl + quizImage}
              className="quiz-banner"
              alt="Brow Quiz"
            />
          </Col>
          <Col lg="6" sm="12">
            <div className="quiz-sections">
              <div
                className={
                  "section"
                }
              >
                <div>
                  <h4>{quizHeader}</h4>
                  <p>
                    {quizParagraph}
                  </p>
                  <Button
                    className="take-quiz"
                    onClick={() => loadQuestions()}
                  >
                    Take Quiz
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  } else {
    const selectedQuestion = questions[currentQuestionIndex]

    console.log(answers)
    return (

      <section className="brow-quiz">
        <Helmet>
          <title>Brow Quiz | Browbar By Reema</title>
        </Helmet>

        <div className="container">
          <Row className="brow-quiz align-items-center">
            <Col lg="6" sm="12" className="p-0">
              <img
                src={imageSrcUrl + quizImage}
                className="quiz-banner"
                alt="Brow Quiz"
              />
            </Col>
            <Col lg="6" sm="12">
              <div className="quiz-sections">
                {loading ?
                  <SpinnerComponent /> :
                  <div
                    className={
                      "section"
                    }
                  >

                    <h4>{selectedQuestion.body}</h4>
                    <Row className="option-buttons justify-content-center">
                      {selectedQuestion.options.map((option, optionIndex) => (
                        <Col lg="3">
                          <div
                            className={
                              answers[currentQuestionIndex]?.answerId === option._id
                                ? "option-img selected"
                                : "option-img"

                            }
                            onClick={() => onChangeOption(option._id)}
                          >
                            <div>
                              <img
                                src={imageSrcUrl + option.optionImg}
                                width="90"
                                alt={option.optionBody}
                              />
                              <h5>{option.optionBody}</h5>
                            </div>
                            <img
                              src={imageSrcUrl + 'quiz-source/check.png'}
                              className="selected-icon"
                              width="20"
                              alt="Selected"
                            />
                          </div>
                        </Col>
                      ))}
                      <Col lg="12" sm="12">
                        <Button
                          className="take-quiz mt-2"
                          onClick={() => handleNext()}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </div>
                }

              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }

};

export default BrowQuiz;