import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
    return (
      <section className="terms-of-use ptb-30">
        <Helmet>
          <title>Terms of Use | Brow Bar by Reema</title>
        </Helmet>

        <div className="container">
          <h2>TERMS OF USE</h2>
          <Row>
            <Col lg="12" sm="12">
              <div className="terms-of-use-content">
                <p>
                  BROW BAR BY REEMA (“Company”) provides the content and
                  products available on this website (“Website”) subject to the
                  terms and conditions of use set forth below, the Privacy
                  Policy and any other terms and conditions which may exist
                  otherwise on the Website or in relation to promotions,
                  contests or sweepstakes conducted on the Website (“Terms”).
                  This Website is offered and available to users who are 13
                  years of age or older. By using this Website, you represent
                  and warrant that you are of legal age to form a binding
                  contract with the Company and meet all of the foregoing
                  eligibility requirements. If you do not meet all of these
                  requirements, you must not access or use the Website.
                </p>
                <h5 className="mt-3">Acceptance Of Terms</h5>
                <p>
                  You acknowledge that you have read the terms and conditions of
                  use and that you accept the terms thereof. YOU AGREE TO READ
                  THESE TERMS AND CONDITIONS OF USE CAREFULLY BEFORE USING THIS
                  WEBSITE. If you do not agree to these terms and conditions of
                  use, you may not access or otherwise use this Website.
                </p>
                <h5 className="mt-3">Right To Modify Terms</h5>
                <p>
                  We may revise and update these Terms of Use from time to time
                  in our sole discretion. All changes are effective immediately
                  when we post them, and apply to all access to and use of the
                  Website thereafter. However, any changes to the dispute
                  resolution provisions set forth in Governing Law and
                  Jurisdiction will not apply to any disputes for which the
                  parties have actual notice [on or] prior to the date the
                  change is posted on the Website. Your continued use of the
                  Website following the posting of revised Terms of Use means
                  that you accept and agree to the changes. You are expected to
                  check this page frequently so you are aware of any changes, as
                  they are binding on you.
                </p>
                <h5 className="mt-3">Account Security</h5>
                <p>
                  To access the Website or some of the resources it offers, you
                  may be asked to provide certain registration details or other
                  information. It is a condition of your use of the Website that
                  all the information you provide on the Website is correct,
                  current and complete. You agree that all information you
                  provide to register with this Website or otherwise, including
                  but not limited to through the use of any interactive features
                  on the Website, is governed by our Privacy Policy and you
                  consent to all actions we take with respect to your
                  information consistent with our Privacy Policy.
                </p>
                <p>
                  If you choose, or are provided with, a user name, password or
                  any other piece of information as part of our security
                  procedures, you must treat such information as confidential,
                  and you must not disclose it to any other person or entity.
                  You also acknowledge that your account is personal to you and
                  agree not to provide any other person with access to this
                  Website or portions of it using your user name, password or
                  other security information.
                </p>
                <p>
                  You agree to notify us immediately of any unauthorized access
                  to or use of your user name or password or any other breach of
                  security. You should use particular caution when accessing
                  your account from a public or shared computer so that others
                  are not able to view or record your password or other personal
                  information. We have the right to disable any user name,
                  password or other identifier, whether chosen by you or
                  provided by us, at any time in our sole discretion for any or
                  no reason, including if, in our opinion, you have violated any
                  provision of these Terms of Use.
                </p>
                <h5 className="mt-3">Intellectual Property Rights</h5>
                <p>
                  The Website is protected by copyright as a collective work
                  and/or compilation, pursuant to U.S. copyright laws,
                  international conventions, and other copyright laws. All
                  information and content contained on the Website, including
                  but not limited to trademarks, graphics, images, and logos are
                  protected by US and international intellectual property laws,
                  and are owned or controlled by BROW BAR BY REEMA or the party
                  credited as the provider of the content. You will abide by any
                  and all additional copyright notices, information, or
                  restrictions contained in any content on the Website. Copying
                  or storing of any content for other than personal,
                  noncommercial use is expressly prohibited without the prior
                  written permission from BROW BAR BY REEMA or the copyright
                  holder identified in the individual content's copyright
                  notice. Use of trademarks for commercial purposes without
                  authorization from BROW BAR BY REEMA is strictly prohibited.
                </p>
                <h5 className="mt-3">Changes To Website</h5>
                <p>
                  BROW BAR BY REEMA may change, suspend or discontinue any
                  aspect of the Website at any time, including the availability
                  of any Website feature, database, or content. BROW BAR BY
                  REEMA may also impose limits on certain features and services
                  or restrict your access to parts or the entire Website without
                  notice or liability.
                </p>
                <h5 className="mt-3">Limited Use License</h5>
                <p>
                  BROW BAR BY REEMA grants you a limited, revocable, and
                  non-exclusive license to access and make personal,
                  non-commercial use of the Website. This limited license does
                  not include the right to:
                </p>
                <ol>
                  <li>Frame or enclose the Website or any portion thereof</li>
                  <li>
                    Republish, distribute, or license the Website or any content
                    thereon
                  </li>
                  <li>
                    Create any derivative works based upon either the Website or
                    any content thereon.
                  </li>
                </ol>
                <p>
                  This limited license may be terminated immediately upon any
                  unauthorized use by you of the Website or any and/or all of
                  BROW BAR BY REEMA’s content.
                </p>
                <h5 className="mt-3">Sweetstakes, Contests, And Promotions</h5>
                <p>
                  BROW BAR BY REEMA may promote, via the Website, sweepstakes,
                  contests or other types of promotions that will be governed by
                  special rules in addition to these Terms. Your participation
                  in such promotions constitutes acceptance of all applicable
                  terms.
                </p>
                <h5 className="mt-3">Technical Issues</h5>
                <p>
                  Given the unpredictability of technology and the online
                  environment, BROW BAR BY REEMA does not warrant that function
                  or operation of this Web site will be uninterrupted or
                  error-free, that defects will be corrected, or that this site
                  or the server that makes it available will be free of viruses
                  or other harmful elements. As a visitor to and user of this
                  Web site, you must assume full responsibility for any costs
                  associated with servicing of equipment used in connection with
                  use of our site. As a visitor to and a user of this Web site,
                  you, in effect, agree that your access will be subject to the
                  terms and conditions set forth in this legal notice and that
                  access is undertaken at your own risk. BROW BAR BY REEMA shall
                  not be liable for damages of any kind related to your use of
                  or inability to access this site.
                </p>
                <h5 className="mt-3">Errors And Omissions</h5>
                <p>
                  We endeavor to present the most recent, most accurate, and
                  most reliable information on our Web site at all times.
                  However, there may be occasions when some of the information
                  featured on our Web site may contain incomplete data,
                  typographical errors, or inaccuracies. Errors are wholly
                  unintentional and we apologize if erroneous information is
                  reflected in price, or availability of goods or services. We
                  present our content “as is” and make no claims to its
                  accuracy, either expressed or implied. We reserve the right to
                  amend errors or to update information at any time without
                  prior notice.
                </p>
                <h5 className="mt-3">Encrypted Transmissions</h5>
                <p>
                  At BROW BAR BY REEMA we utilize the industry’s highest
                  standard of data encryption to ensure that your online
                  shopping experience is safe and secure. Security measures are
                  triggered automatically with your order.
                </p>
                <h5 className="mt-3">Protection Of Children</h5>
                <p>
                  BROW BAR BY REEMA is concerned about the safety of children
                  when they use the Internet. We will never knowingly request
                  personally identifiable information from anyone under the age
                  of 13 without prior verifiable parental consent. If we receive
                  actual knowledge that a subscriber to our Web site is under
                  the age of 13, we will remove from our files all identifiable
                  information about that person. If you are under 13 years old,
                  please exit this site.
                </p>
                <h5 className="mt-3">Usage Tracking</h5>
                <p>
                  BROW BAR BY REEMA tracks user traffic patterns throughout Web
                  site and this information is correlated with data about
                  individual users. Overall usage statistics, according to a
                  user’s domain name, browser type and MIME type gotten by
                  reading this information from the browser string (information
                  contained in every user’s browser), are also broken down.
                </p>
                <h5 className="mt-3">Email</h5>
                <p>
                  Using e-mail addresses provided at registration or otherwise,
                  BROW BAR BY REEMA periodically sends out e-mail newsletters
                  and promotional e-mail to our users about services and
                  products offered by BROW BAR BY REEMA. Users can indicate that
                  they do not wish to receive e-mail information from BROW BAR
                  BY REEMA.
                </p>
                <p>
                  Liability IN NO EVENT SHALL BROW BAR BY REEMA, INC. BE LIABLE
                  FOR ANY INJURY, LOSS, CLAIM, DAMAGE OR ANY SPECIAL, EXEMPLARY,
                  PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND,
                  WHETHER BASED IN CONTRACT, TORT OR OTHERWISE, WHICH ARISES OUT
                  OF OR IS ANY WAY CONNECTED WITH ANY USE OF THE SITE.
                </p>
                <h5 className="mt-3">Links</h5>
                <p>
                  LINKED WEBSITES ARE NOT UNDER THE CONTROL OF BROW BAR BY REEMA
                  AND BROW BAR BY REEMA IS NOT RESPONSIBLE FOR THE CONTENT OF
                  ANY LINKED WEBSITE. IF YOU DECIDE TO ACCESS ANY OF THE THIRD
                  PARTY WEBSITES LINKED TO THIS WEBSITE, YOU DO SO ENTIRELY AT
                  YOUR OWN RISK.
                </p>
                <h5 className="mt-3">Severability</h5>
                <p>
                  If any term or provision of these Terms is invalid, illegal or
                  unenforceable in any jurisdiction, such invalidity, illegality
                  or unenforceability shall not affect any other term or
                  provision of these Terms or invalidate or render unenforceable
                  such term or provision in any other jurisdiction.
                </p>
                <h5 className="mt-3">Copyrights</h5>
                <p>
                  BROW BAR BY REEMA values its trademarks, product names,
                  company names and corporate identities represented on its
                  Internet site, and these items are the property of BROW BAR BY
                  REEMA.
                </p>
                <h5 className="mt-3">Third Party Links</h5>
                <p>
                  The Website may contain links and pointers to the other
                  related World Wide Web Internet sites, resources, and sponsors
                  of the Website. Links to and from Website to other third party
                  sites, maintained by third parties, do not constitute an
                  endorsement by Company or any of its subsidiaries or
                  affiliates of any third party resources, or their contents. If
                  you decide to access any of the third-party sites linked to
                  the Website, you do so entirely at your own risk. All such
                  websites are subject to the policies and procedures of the
                  owner of such websites. Because we have no control over such
                  websites or resources or the individuals who make such
                  contributions, you acknowledge and agree that Company is not
                  responsible for the availability of such websites or resources
                  or any contributions, neither endorses nor is responsible or
                  liable for any content, advertising, products or other
                  materials on or available from such websites or resources or
                  the content of any contributions and shall not be responsible
                  or liable, directly or indirectly, for any damage or loss
                  caused or alleged to be caused by or in connection with the
                  use of or reliance on any such content, goods or services
                  available on or through any such websites or resource.
                </p>
                <h5 className="mt-3">Digital Millennium Copyright Notice</h5>
                <p>
                  If you believe that specific content on the Website infringes
                  upon your copyrighted materials, please provide BROW BAR BY
                  REEMA with the following information: an electronic or
                  physical signature of the person authorized to act on behalf
                  of the owner of the copyright interest; a description of the
                  copyrighted work that you claim has been infringed; a
                  description of where the allegedly infringing material is
                  located on the Website, including the URL’s of the particular
                  web pages; your address, telephone number, and email address;
                  a written statement by you that you have a good faith belief
                  that the use on the Website is not authorized by the copyright
                  owner, its agent, or the law; a statement by you, made under
                  penalty of perjury, that the foregoing information is accurate
                  and that you are the copyright owner or authorized to act on
                  the copyright owner's behalf. BROW BAR BY REEMA Designated
                  Agent for copyright claims can be contacted as follows:
                </p>
                <p>
                  <b>
                    TRENDZ BEAUTY GROUP ATTN – BROW BAR BY REEMA 11060 E ARTESIA
                    BLVD, STE G CERRITOS, CA 90703
                  </b>
                </p>
                <h5 className="mt-3">Force Majeure</h5>
                <p>
                  BROW BAR BY REEMA shall not be liable for any delay or failure
                  of performance due to conditions or events beyond its
                  reasonable control, including, without limitation, natural
                  disasters, accidents, labor disputes, shortages, ordinances,
                  rules and regulations and inability to obtain material,
                  equipment or transportation. If BROW BAR BY REEMA ’s
                  production of merchandise is curtailed or suspended,
                  deliveries may, at S BROW BAR BY REEMA’S option, be cancelled
                  or be proportionately curtailed and then, at BROW BAR BY
                  REEMA’s option, resumed upon the removal of the cause so
                  interfering with production and continued until the entire
                  quantity purchased hereunder has been delivered. BROW BAR BY
                  REEMA shall have the right to allocate its available supply of
                  goods among its customers and affiliates in such manner as
                  BROW BAR BY REEMA deems fair and equitable.
                </p>
                <h5 className="mt-3">
                  Disclaimer Of Warranties/Limitation Of Liability
                </h5>
                <p>
                  BROW BAR BY REEMA MAKES NO REPRESENTATION OR WARRANTY, EXPRESS
                  OR IMPLIED, WITH RESPECT TO THE GOODS DESCRIBED HEREIN,
                  INCLUDING WITHOUT LIMITATION ANY REPRESENTATION OR WARRANTY
                  CONCERNING THE DESIGN, MANUFACTURE, USE OR PERFORMANCE OF ANY
                  SUCH GOODS OR ANY IMPLIED WARRANTY OF MERCHANTABILITY OR
                  FITNESS FOR A PARTICULAR PURPOSE. BROW BAR BY REEMA’S TOTAL
                  LIABILITY FOR ANY COST, LOSS, DAMAGE OR OTHER POTENTIAL OR
                  ACTUAL EXPENSES ARISING DIRECTLY OR INDIRECTLY OUT OF THE
                  PURCHASE AND SALE OF THE GOODS DESCRIBED HEREIN SHALL NOT
                  EXCEED THE PURCHASE PRICE PAID BY YOU FOR THE GOODS WITH
                  RESPECT TO WHICH ANY CLAIM IS MADE, REGARDLESS OF THE FORM OF
                  THE ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE. IN NO
                  EVENT SHALL BROW BAR BY REEMA BE LIABLE IN ANY WAY FOR ANY
                  CONSEQUENTIAL OR SPECIAL DAMAGES EVEN IF BROW BAR BY REEMA IS
                  INFORMED OR IS OTHERWISE AWARE OR SHOULD BE AWARE OF THEIR
                  LIKELIHOOD.
                </p>
                <h5 className="mt-3">Governing Law</h5>
                <p>
                  All matters arising out of or relating to the purchase of
                  goods are governed by and construed in accordance with the
                  internal laws of the State of Illinois without giving effect
                  to any choice or conflict of law provision or rule (whether of
                  the State of Illinois or any other jurisdiction) that would
                  cause the application of the laws of any jurisdiction other
                  than those of the State of Illinois.
                </p>
                <h5 className="mt-3">Submission To Jurisdiction</h5>
                <p>
                  Any legal suit, action or proceeding arising out of or
                  relating to this Agreement shall be instituted in the federal
                  courts of the United States of America or the courts of the
                  State of Illinois in each case, and each party irrevocably
                  submits to the exclusive jurisdiction of such courts in any
                  such suit, action or proceeding.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    );
};

export default TermsOfUse;