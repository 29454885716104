/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import {
  OverlayTrigger,
  Navbar,
  Offcanvas,
  Nav,
  NavDropdown,
  Popover,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  AiOutlineHeart,
  AiOutlineUser,
  AiOutlineShopping,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IMG_URL } from "../../config";
import actions from "../../redux/products/actions";
import {
  getWishList,
  getAllCategory,
  getCategoryMenu,
  getCategoryMenuParams,
} from "../../service/api";

/* IMAGES */
import Logo from "../../assets/browbar-by-reema-logo.png";

const NewHeader = () => {
  const target = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  /* STATES */
  const [showCart, setShowCart] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state?.user);
  const { isLogin } = useSelector((state) => state.user);
  const [wishListCount, setwishListCount] = useState(0);
  const [headerVisible, setHeaderVisible] = useState(false);

  // Refs

  const refheight = useRef(null);
  const rowheight = useRef(null);

  /* USE EFFFECTS */
  useEffect(() => {
    window?.addEventListener("scroll", () => {
      if (
        window?.scrollY >
        refheight?.current?.clientHeight + rowheight?.current?.clientHeight
      ) {
        setHeaderVisible(true);
      } else setHeaderVisible(false);
    });
    return () => {
      // window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    if (user?.token) {
      getWishList({
        page: 1,
        sizePerPage: 10,
      }).then((res) => {
        dispatch(actions.setWishList(res?.data?.totalDocs));
        setwishListCount(res?.data?.totalDocs);
      });
    } else {
      dispatch(actions.setWishList(0));
      setwishListCount(0);
    }
  }, [user?.token]);

  const [categoryMenue, setCategoryMenue] = useState([]);
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    getAllCategory({
      page: 1,
      sizePerPage: 10,
    }).then((res) => {
      if (res.status) {
        dispatch(actions.setCategory(res?.data));
      }
    });
    getCategoryMenuParams({
      isRoot: true,
      isMenu: true,
      // isWholeSale:false
    }).then((res) => {
      if (res.status) {
        console.log("setMenu", res?.data);
        setMenu(res?.data);
      }
    });
    getCategoryMenu().then((res) => {
      console.log("getCategoryMenu", res);
      setCategoryMenue(res?.data);
    });
  }, [""]);

  useEffect(() => {
    if (products.wishList === undefined) {
      setwishListCount(0);
      return;
    }
    setwishListCount(products.wishList);
  }, [products.wishList]);

  useEffect(() => {
    if (products.wishList === undefined) {
      setwishListCount(0);
      return;
    }
    setwishListCount(products.wishList);
  }, [products.wishList]);

  /* CART OVERLAY */
  const CartOverlay = (
    <Popover>
      <Popover.Body>
        <div className="cart-popup">
          <div className="d-flex justify-content-between">
            <h5>
              MY BAG ({products.cart?.length ? products.cart?.length : 0})
            </h5>
          </div>
          <div className="products-added">
            <Row className="mt-1">
              {products.cart.length > 0 ? (
                <>
                  <Col lg="4" sm="4">
                    <img
                      src={
                        IMG_URL +
                        (products.cart[0]?.product?.productImage?.length > 0
                          ? products.cart[0]?.product?.productImage?.filter(
                              (img) => img.color === products.cart[0].color._id
                            )[0]?.front[0]
                          : products.cart[0]?.product?.productImage?.front[0])
                      }
                      className="product-img-bought"
                      alt="Bombshell Brow Kit - Medium"
                    />
                  </Col>
                  <Col lg="8" sm="8">
                    <h4 className="product-name">
                      {products?.cart[0]?.product?.name}
                    </h4>
                    <div className="d-flex">
                      <p>
                        <b>Color: </b>
                        {products?.cart[0]?.color?.name}
                      </p>
                      <p className="ml-2">
                        <b>Quantity: </b>
                        {products?.cart[0]?.quantity}
                      </p>
                    </div>
                    <p>
                      <b>Price: </b>$ {products?.cart[0]?.amount}
                    </p>
                  </Col>
                </>
              ) : (
                <Row className="mt-1 justify-content-center">
                  <Col
                    lg="12"
                    sm="12"
                    style={{ fontFamily: "Figtree, sans-serif" }}
                  >
                    Your cart is empty.
                  </Col>
                </Row>
              )}
            </Row>
            {products.cart.length > 1 && (
              <Row className="mt-1 justify-content-center">
                <Col lg="12" sm="12">
                  <Link className="items-in-cart-link" to="/cart">
                    +{products.cart.length - 1} more items in cart
                  </Link>
                </Col>
              </Row>
            )}
            <hr />
            <Row>
              <Col lg="6" sm="12">
                {/* <Link to="/checkout">
                  <Button className="checkout-btn">CHECKOUT</Button>
                </Link> */}
                <Button
                  onClick={() =>
                    products.cart.length === 0
                      ? enqueueSnackbar("Your Cart is Empty!", {
                          variant: "error",
                        })
                      : navigate("/checkout")
                  }
                  className="checkout-btn"
                >
                  CHECKOUT
                </Button>
              </Col>
              <Col lg="6" sm="12">
                <Link to="/cart">
                  <Button className="view-cart-btn">VIEW CART</Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const [showOffcanvas, setShowOffcanvas] = useState(false);

  return (
    <div>
      <header className="new-header">
        {/* TOP HEADER */}
        {/* <div className="top-header" ref={refheight}>
          <h5>
            50% off SITEWIDE 
          </h5>
        </div> */}
        <div className="top-header-two">
          <h5>Free shipping for all USA orders $40 plus!</h5>

          {/* TABS SWITCHER */}
          <div className="tabs-switcher">
            <div className="switcher-box active">
              <h5>RETAIL</h5>
            </div>
            <div className="switcher-box">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wholesale.browbarbyreema.com/"
              >
                <h5>WHOLESALE</h5>
              </a>
            </div>
          </div>
        </div>

        {/* HEADER CONTENT */}
        <div className="new-header-wrap">
          <div className="container">
            <div className="header-flex">
              {/* HEADER LEFT */}
              <div className="header-left">
                <Navbar expand="lg" expanded={showOffcanvas}>
                  <Navbar.Toggle
                    aria-controls="menu-expand"
                    onClick={() => setShowOffcanvas(true)}
                  />
                  <Navbar.Offcanvas id="menu-expand" placement="start">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="offcanvas-expand-lg">
                        <Link
                          to="/"
                          onClick={() => {
                            setShowOffcanvas(false);
                          }}
                        >
                          <div className="logo">
                            <img src={Logo} alt="Browbar By Reema" />
                          </div>
                        </Link>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="main-menu">
                        <Nav.Link
                          className="menu-link"
                          onClick={() => {
                            navigate("/");
                            setShowOffcanvas(false);
                            setShowDropdown(false);
                          }}
                        >
                          Home
                        </Nav.Link>
                        <NavDropdown title="SHOP" className="mobile-dropdown">
                          {menu?.map((item) => {
                            return (
                              <NavDropdown.Item
                                title={item?.name}
                                key={item?._id}
                                className="mobile-inner-dropdown"
                                onClick={() => {
                                  console.log(item);
                                  navigate("/products/" + item?._id);
                                  setShowDropdown(!showDropdown);
                                  setShowOffcanvas(false);
                                }}
                              >
                                {item?.name}
                              </NavDropdown.Item>
                            );
                          })}

                          {categoryMenue?.map((item) => {
                            return (
                              <NavDropdown
                                title={item?._id?.name}
                                key={item?._id?._id}
                                className="mobile-inner-dropdown"
                              >
                                {item?.Products?.map((item2) => {
                                  return (
                                    <NavDropdown.Item
                                      key={item2?._id}
                                      onClick={() => {
                                        console.log("item2", item2?._id);
                                        dispatch(
                                          actions.setSelectedProductId(
                                            item2?._id
                                          )
                                        );
                                        dispatch(
                                          actions.setSelectedColorId(
                                            item2?.colorId
                                          )
                                        );
                                        dispatch(
                                          actions.setSelectedProduct(item2)
                                        );
                                        navigate("/product-detail");
                                        setShowDropdown(!showDropdown);
                                        setShowOffcanvas(false);
                                      }}
                                      className="inner-link"
                                    >
                                      {item2?.name}
                                    </NavDropdown.Item>
                                  );
                                })}

                                <NavDropdown.Item
                                  onClick={() => {
                                    console.log(item);
                                    navigate("/products/" + item?._id?._id);
                                    setShowDropdown(!showDropdown);
                                    setShowOffcanvas(false);
                                  }}
                                  className="inner-link"
                                  style={{
                                    color: "#3E3EF4",
                                    fontWeight: "600",
                                  }}
                                >
                                  View All Products
                                </NavDropdown.Item>
                              </NavDropdown>
                            );
                          })}
                        </NavDropdown>
                        <Nav.Link
                          className="menu-link mobile-hide"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          Shop
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => (
                            setShowOffcanvas(false),
                            setShowDropdown(false),
                            navigate("/press")
                          )}
                        >
                          Press
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => (
                            setShowOffcanvas(false),
                            setShowDropdown(false),
                            navigate("/about")
                          )}
                        >
                          About
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => (
                            setShowOffcanvas(false),
                            setShowDropdown(false),
                            navigate("/brow-quiz")
                          )}
                        >
                          Brow Quiz
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Navbar>
              </div>

              {/* WEBSITE LOGO */}
              <Link
                to="/"
                className="logo"
                onClick={() => {
                  setShowOffcanvas(false);
                  setShowDropdown(false);
                }}
              >
                <img src={Logo} alt="Browbar By Reema" />
              </Link>

              {/* HEADER RIGHT */}
              <div className="header-right">
                {/* WISHLIST ICON */}
                <Link
                  to={user?.loggedIn ? "/wishlist" : "/account"}
                  className="header-actions"
                  onClick={() => {
                    setShowOffcanvas(false);
                    setShowDropdown(false);
                  }}
                >
                  <AiOutlineHeart className="action-icon" />
                  <span className="count">{wishListCount}</span>
                </Link>

                {/* ACCOUNT ICON */}
                <Link
                  className="header-actions"
                  to={user?.loggedIn ? "/dashboard" : "/account"}
                  onClick={() => {
                    setShowOffcanvas(false);
                    setShowDropdown(false);
                  }}
                >
                  <AiOutlineUser className="action-icon" />
                </Link>

                {/* CART ICON */}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={CartOverlay}
                >
                  <Link
                    className="header-actions"
                    ref={target}
                    onClick={() => (
                      setShowOffcanvas(false),
                      setShowDropdown(false),
                      setShowCart(!showCart)
                    )}
                  >
                    <AiOutlineShopping className="action-icon" />
                    <span className="count">
                      {products.cart?.length ? products.cart?.length : 0}
                    </span>
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* DROPDOWN */}
      <div className={`new-dropdown ${showDropdown ? "active" : "inactive"}`}>
        <div className="container dropdown-items">
          <div className="column right-border">
            {menu?.map((item) => {
              return (
                <div className="column" key={item?._id}>
                  <div
                    key={item?._id}
                    className="dropdown-link"
                    onClick={() => {
                      console.log(item);
                      navigate("/products/" + item?._id);
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    {item?.name}
                  </div>
                </div>
              );
            })}
            {/* <Link to="/diy-brows" className="small-menu-link" onClick={() => {
              setShowDropdown(!showDropdown)
            }}>
              DIY Brows
            </Link> */}
          </div>

          {categoryMenue?.map((item) => {
            return (
              <div className="column" key={item?._id?._id}>
                <div key={item?._id?.name} className="dropdown-link">
                  {item?._id?.name}
                </div>
                {item?.Products?.map((item2) => {
                  return (
                    <div
                      onClick={() => {
                        console.log("item2", item2?._id);
                        dispatch(actions.setSelectedProductId(item2?._id));
                        dispatch(actions.setSelectedColorId(item2?.colorId));
                        dispatch(actions.setSelectedProduct(item2));
                        navigate("/product-detail");
                        setShowDropdown(!showDropdown);
                      }}
                      key={item2?._id}
                      className="small-menu-link"
                    >
                      {item2?.name}
                    </div>
                  );
                })}
                <div
                  onClick={() => {
                    console.log(item);
                    navigate("/products/" + item?._id?._id);
                    setShowDropdown(!showDropdown);
                  }}
                  key={item?._id?._id}
                  className="small-menu-link"
                  style={{ color: "#3E3EF4", fontWeight: "600" }}
                >
                  View All Products
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewHeader;
