/* BESTSELLERS */
import BrowMakeoverKitDark from '../assets/bestsellers/makeover-kit-dark.webp';
import BrowMakeoverKitMedium from  '../assets/bestsellers/makeover-kit-medium.webp';
import BrowMakeoverKitLight from "../assets/bestsellers/makeover-kit-light.webp";
import BombshellBrowKitDark from "../assets/bestsellers/bombshell-brow-kit-dark.webp";
import BombshellBrowKitMedium from "../assets/bestsellers/bombshell-brow-kit-medium.webp";
import BombshellBrowKitLight from "../assets/bestsellers/bombshell-brow-kit-light.webp";
import BombshellBrowGel from '../assets/bestsellers/bombshell-brow-gel.webp';
import MicrobladePenDark from '../assets/bestsellers/microblade_pen_dark.webp';
import MicrobladePenMedium from "../assets/bestsellers/microblade_pen_medium.webp";
import MicrobladePenLight from "../assets/bestsellers/microblade_pen_light.webp";
import BombshellBrowLashSerum from '../assets/bestsellers/bombshell-brow-serum.webp';
import Kajal from "../assets/bestsellers/kajal.webp";
import NudeLipstickRodeoRaw from '../assets/bestsellers/true-nude-lipstick-rodeoraw.webp';
import NudeLipistick90210 from '../assets/bestsellers/truenude-90210h.webp';
import NudeLipistickBare from "../assets/bestsellers/bhbare.webp";
import GoldenGiftBag from "../assets/bestsellers/gift-bag.webp";
import HighlightPalette from "../assets/bestsellers/heartbreaker-highlighter-palette.webp";

const bestsellers = [
  {
    id: 1,
    productBackImg: BrowMakeoverKitDark,
    productFrontImg: BrowMakeoverKitDark,
    isOutOfStock: false,
    productName: "Brow Makeover Kit - Dark",
    productPrice: "$28.00",
  },
  {
    id: 2,
    productBackImg: BrowMakeoverKitMedium,
    productFrontImg: BrowMakeoverKitMedium,
    isOutOfStock: false,
    productName: "Brow Makeover Kit - Medium",
    productPrice: "$28.00",
  },
  {
    id: 3,
    productBackImg: BrowMakeoverKitLight,
    productFrontImg: BrowMakeoverKitLight,
    isOutOfStock: false,
    productName: "Brow Makeover Kit - Light",
    productPrice: "$28.00",
  },
  {
    id: 4,
    productBackImg: BombshellBrowKitDark,
    productFrontImg: BombshellBrowKitDark,
    isOutOfStock: false,
    productName: "Bombshell Brow Kit - Dark",
    productPrice: "$42.00",
  },
  {
    id: 5,
    productBackImg: BombshellBrowKitMedium,
    productFrontImg: BombshellBrowKitMedium,
    isOutOfStock: false,
    productName: "Bombshell Brow Kit - Medium",
    productPrice: "$42.00",
  },
  {
    id: 6,
    productBackImg: BombshellBrowKitLight,
    productFrontImg: BombshellBrowKitLight,
    isOutOfStock: false,
    productName: "Bombshell Brow Kit - Light",
    productPrice: "$42.00",
  },
  {
    id: 7,
    productBackImg: BombshellBrowGel,
    productFrontImg: BombshellBrowGel,
    isOutOfStock: false,
    productName: "Bombshell Brow Gel",
    productPrice: "$9.00",
  },
  {
    id: 8,
    productBackImg: MicrobladePenDark,
    productFrontImg: MicrobladePenDark,
    isOutOfStock: false,
    productName: "Microblade Pen - Dark",
    productPrice: "$24.00",
  },
  {
    id: 9,
    productBackImg: MicrobladePenMedium,
    productFrontImg: MicrobladePenMedium,
    isOutOfStock: false,
    productName: "Microblade Pen - Medium",
    productPrice: "$24.00",
  },
  {
    id: 10,
    productBackImg: MicrobladePenLight,
    productFrontImg: MicrobladePenLight,
    isOutOfStock: false,
    productName: "Microblade Pen - Light",
    productPrice: "$24.00",
  },
  {
    id: 11,
    productBackImg: BombshellBrowLashSerum,
    productFrontImg: BombshellBrowLashSerum,
    isOutOfStock: false,
    productName: "Bombshell Brow & Lash Serum",
    productPrice: "$25.00",
  },
  {
    id: 12,
    productBackImg: Kajal,
    productFrontImg: Kajal,
    isOutOfStock: false,
    productName: "Sita Kajal Intense Eyeliner",
    productPrice: "$16.00",
  },
  {
    id: 13,
    productBackImg: NudeLipstickRodeoRaw,
    productFrontImg: NudeLipstickRodeoRaw,
    isOutOfStock: false,
    productName: "True Nude Lipstick - Rodeo Raw",
    productPrice: "$17.00",
  },
  {
    id: 14,
    productBackImg: NudeLipistick90210,
    productFrontImg: NudeLipistick90210,
    isOutOfStock: false,
    productName: "True Nude Lipstick - 90210h",
    productPrice: "$25.00",
  },
  {
    id: 15,
    productBackImg: NudeLipistickBare,
    productFrontImg: NudeLipistickBare,
    isOutOfStock: false,
    productName: "True Nude Lipstick - BH Bare",
    productPrice: "$17.00",
  },
  {
    id: 16,
    productBackImg: GoldenGiftBag,
    productFrontImg: GoldenGiftBag,
    isOutOfStock: false,
    productName: "Golden Gift Bag",
    productPrice: "$40.00",
  },
  {
    id: 17,
    productBackImg: HighlightPalette,
    productFrontImg: HighlightPalette,
    isOutOfStock: false,
    productName: "Heartbreaker Highlighter Palette",
    productPrice: "$40.00",
  },
];

export default bestsellers;