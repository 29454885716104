import React from "react";
import { Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import VideoBox from "../../components/video-box";



const NewDiyBrows = () => {

  // Sample Data
  const allProducts = [
    {
      isOutOfStock: "false",
      productFrontImg: "product/1674480075197.png",
      productBackImg: "product/1672946589572.webp",
      productName: "Brow Lift",
      productPrice: "15",
      productId: "63b721cfacf3b17ee9ad9553",
      colorId: "63b72232b1bf55f569670219",
      wishlistObj: "",
      refresh: "false",
      product: "",
      ratings: 1,
      youtubeLink: {
        url: "https://youtu.be/1WlnLII1xIU",
        heading: "The Ultimate Brow ✨Luxury✨ "
      }
    },
    {
      isOutOfStock: "false",
      productFrontImg: "product/1674480075197.png",
      productBackImg: "product/1672946589572.webp",
      productName: "Brow Lift",
      productPrice: "15",
      productId: "63b721cfacf3b17ee9ad9553",
      colorId: "63b72232b1bf55f569670219",
      wishlistObj: "",
      refresh: "false",
      product: "",
      ratings: 1,
      youtubeLink: null
    },
    {
      isOutOfStock: "false",
      productFrontImg: "product/1674480075197.png",
      productBackImg: "product/1672946589572.webp",
      productName: "Brow Lift",
      productPrice: "15",
      productId: "63b721cfacf3b17ee9ad9553",
      colorId: "63b72232b1bf55f569670219",
      wishlistObj: "",
      refresh: "false",
      product: "",
      ratings: 1,
      youtubeLink: null
    },
    {
      isOutOfStock: "false",
      productFrontImg: "product/1674480075197.png",
      productBackImg: "product/1672946589572.webp",
      productName: "Brow Lift",
      productPrice: "15",
      productId: "63b721cfacf3b17ee9ad9553",
      colorId: "63b72232b1bf55f569670219",
      wishlistObj: "",
      refresh: "false",
      product: "",
      ratings: 1,
      youtubeLink: null
    }
  ]

  const videoLinks = [{
    url: "https://youtu.be/1WlnLII1xIU",
    heading: "The Ultimate Brow ✨Luxury✨",
    imageURL: "https://storage.googleapis.com/new-cdn-bbr/category-image/youtube-banner-1.png",
    paragraph: "Get the ultimate bombshell look every single time."
  },
  {
    url: "https://youtu.be/VPSiBOMuMek",
    heading: "Bombshell Brow Kit 🧚🏻‍♀️",
    imageURL: "https://storage.googleapis.com/new-cdn-bbr/category-image/youtube-banner-2.png",
    paragraph: "Get the ultimate bombshell look every single time."
  },
  {
    url: "https://youtu.be/9pk94EisnFM",
    heading: "Badass Brow Wax",
    imageURL: "https://storage.googleapis.com/new-cdn-bbr/category-image/youtube-banner-3.png",
    paragraph: "Get the ultimate bombshell look every single time."
  }];

  return (
    <section className="diy-brows ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>
          Reema's DIY Brow Tutorial Collection | Brow Bar by Reema
        </title>
      </Helmet>

      {/* <Bestsellers/> */}
      <div className="container-fluid p-0">
        <h2>REEMA'S DIY BROW TUTORIAL COLLECTION</h2>
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <div className="banner">
              <img
                src={require("../../assets/banners/diy-brows-banner.webp")}
                alt="DIY Brows | Brow Bar by Reema"
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="container">

        <Row className="justify-content-center mt-3 brow-diy-row">
          <Col lg="6" sm="12" className="brow-diy">
            <h3>JOIN BROW BAR BY REEMA'S DIY BROW REVOLUTION</h3>
            <video controls className="video-frame ptb-10">
              <source
                src={require("../../assets/banners/diy-brow-revolution.mp4")}
                type="video/mp4"
              />
            </video>
            <h2>BECOME YOUR OWN BROW EXPERT!</h2>
            <h5>
              TUTORIALS TO HELP YOU APPLY THE PERFECT BROW/MAKEUP FOR EACH
              BROW KIT & SET!
            </h5>
          </Col>
        </Row>
        {/* <Row className="justify-content-center mt-3">
            <h2>BECOME YOUR OWN BROW EXPERT!</h2>
            <h5>
              TUTORIALS TO HELP YOU APPLY THE PERFECT BROW/MAKEUP FOR EACH BROW
              KIT & SET!
            </h5>
          </Row> */}

        {/* BOMBSHELL BROW KIT */}
        <Row className="mt-3">
          <h4>Brow Lift</h4>
          <Row className="video-row">
            <Col lg="3" sm="12">
              <ProductBox
                calledPage="diy-brows"
                isOutOfStock={allProducts[0].isOutOfStock}
                productFrontImg={allProducts[0].productFrontImg}
                productBackImg={allProducts[0].productBackImg}
                productName={'Brow Lift - Light'}
                productPrice={allProducts[0].productPrice}
                productId={allProducts[0]?.productId}
                colorId={allProducts[0]?.colorId}
                wishlistObj={allProducts[0]?.wishlistObj}
                refresh={false}
                product={allProducts[0]}
                ratings={allProducts[0]?.ratings}
                youtubeLink={allProducts[0]?.youtubeLink}
              />
            </Col>
            <Col lg="3" sm="12">
              <VideoBox videoLinks={videoLinks} />
            </Col>
            <Col lg="3" sm="12">
              <ProductBox
                calledPage="diy-brows"
                isOutOfStock={allProducts[0].isOutOfStock}
                productFrontImg={allProducts[0].productFrontImg}
                productBackImg={allProducts[0].productBackImg}
                productName={'Brow Lift - Medium'}
                productPrice={allProducts[0].productPrice}
                productId={allProducts[0]?.productId}
                colorId={allProducts[0]?.colorId}
                wishlistObj={allProducts[0]?.wishlistObj}
                refresh={false}
                product={allProducts[0]}
                ratings={allProducts[0]?.ratings}
                youtubeLink={allProducts[0]?.youtubeLink}
              />
            </Col>
            <Col lg="3" sm="12">
              <ProductBox
                calledPage="diy-brows"
                isOutOfStock={allProducts[0].isOutOfStock}
                productFrontImg={allProducts[0].productFrontImg}
                productBackImg={allProducts[0].productBackImg}
                productName={'Brow Lift - Dark'}
                productPrice={allProducts[0].productPrice}
                productId={allProducts[0]?.productId}
                colorId={allProducts[0]?.colorId}
                wishlistObj={allProducts[0]?.wishlistObj}
                refresh={false}
                product={allProducts[0]}
                ratings={allProducts[0]?.ratings}
                youtubeLink={allProducts[0]?.youtubeLink}
              />
            </Col>
          </Row>
        </Row>

      </div>
    </section>
  );
};

export default NewDiyBrows;