/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { getAllProducts } from "../../service/api";
import { useSelector } from "react-redux";
/* COMPONENT IMPORTS */
import ProductBox from "../../components/product-box";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

const RelatedProducts = () => {

  const [allProducts, setAllProducts] = useState([]);
  console.log("allProducts: ", allProducts);
  const [pageSize, setPageSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const { category } = useSelector((state) => state.products);
  const [selectedCategory, setSelectedCategory] = useState(null);
  console.log('selectedCategory: ', selectedCategory);

  const getProductList = () => {
    let params = {
      page: 1,
      sizePerPage: pageSize,
    };
    getAllProducts(params)
      .then((res) => {
        if (res?.status) {
          setAllProducts(res?.data[0]?.docs);
          setTotalResults(res?.data[0]?.metadata[0]?.total);
          console.log("total results", res?.data[0]?.metadata[0]?.total)
        }
        console.log("products--->", res?.data[0]?.docs);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getProductList();
  }, []);

  return (
    <section className="bestsellers ptb-30">
      <div className="container">
        <div className="section-heading">
          <h2>YOU MIGHT ALSO LOVE</h2>
          <Link to="/shop" className="shop-products">
            Shop All Products
          </Link>
        </div>

        <Row>
          <Swiper
            modules={[Scrollbar, Navigation, Pagination]}
            breakpoints={{
              300: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 3,
              },
              1000: {
                slidesPerView: 5,
              },
            }}
            navigation={true}
            slidesPerView={5}
            spaceBetween={30}
          >
            {allProducts?.map((product) => {
              return (
                <Col lg="3" sm="12" key={product.id}>
                  <SwiperSlide>
                    <ProductBox
                      isOutOfStock={product.isOutOfStock}
                      productFrontImg={product.productImage.front[0]}
                      productBackImg={product.productImage.back[0]}
                      productName={product.name}
                      productPrice={product.amount}
                      productId={product?._id}
                      colorId={product?.productImage?.color}
                      wishlistObj={product?.WishlistProduct?._id}
                      refresh={false}
                      product={product}
                      ratings={product?.productReview?.length}
                    />
                  </SwiperSlide>
                </Col>
              );
            })}
          </Swiper>
        </Row>
      </div>
    </section>
  );
};

export default RelatedProducts;
