/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';

function BrowKit() {
    return (
        <div>
            <Container>
                <Row className='kit-row'>
                    <Col lg="6" className='kit-image-col'>

                        <img src={require('../../assets/bestsellers/bombshell-brow-kit-dark.webp')}
                            className="kit-image"
                        />
                    </Col>
                    <Col lg="6" className='kit-description px-4 pt-4 pb-4'>
                        <h3>Bombshell Brow Kit <small style={{ float: 'right', fontSize: '42px !important' }}>$240.75</small></h3>
                        <h6>The ultimate kit you can get</h6>
                        <div className='kit-contents'>
                            <div className='content-item'>
                                <div className='left-content'>
                                    <img src={require('../../assets/bestsellers/bombshell-brow-gel.webp')} className="content-img" />
                                    <p>Bombshell Lipstick</p>
                                </div>
                                <div>
                                    <p>x3</p>
                                </div>
                            </div>
                            <div className='content-item'>
                                <div className='left-content'>
                                    <img src={require('../../assets/bestsellers/bombshell-brow-gel.webp')} className="content-img" />
                                    <p>Bombshell Lipstick</p>
                                </div>
                                <div>
                                    <p>x3</p>
                                </div>
                            </div>
                            <div className='content-item'>
                                <div className='left-content'>
                                    <img src={require('../../assets/bestsellers/bombshell-brow-gel.webp')} className="content-img" />
                                    <p>Bombshell Lipstick</p>
                                </div>
                                <div>
                                    <p>x3</p>
                                </div>
                            </div>
                            <div className='content-item'>
                                <div className='left-content'>
                                    <img src={require('../../assets/bestsellers/bombshell-brow-gel.webp')} className="content-img" />
                                    <p>Bombshell Lipstick</p>
                                </div>
                                <div>
                                    <p>x3</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='one-price'>
                            <p>1x Bombshell Brow Kit</p>
                            <p>$10.50</p>
                        </div> */}
                        <div className='pt-3'>
                            <Col lg="6" className='add-to-cart'>

                                <Col lg="5">
                                    <div className='quantity-field float-start' >
                                        <button
                                            className="value-button decrease-button"
                                            onclick="{decreaseValue(this)}"
                                        >-</button>
                                        <div class="number">0</div>
                                        <button
                                            className="value-button increase-button"
                                            onclick="{increaseValue(this)}"
                                        >+
                                        </button>
                                    </div>
                                </Col>
                                <Col lg="7">
                                    <Button className='kit-btn'>
                                        Add to Cart
                                    </Button>
                                </Col>
                            </Col>
                        </div>
                        <div className='pt-4 pb-1'>
                            <h4>Retail Value: <strong>$731.25</strong></h4>
                            <h4>Wholesale Value: <strong>$240.75</strong></h4>
                            <p>
                                PRICE INCLUDES PRINTING, LABELS, & ARTWORK
                            </p>
                        </div>
                        <div className='details pt-2'>
                            <p>
                                <span>Details: </span>Completely customize the display card – your words, photos, layout, etc. We provide proof for your approval. Fast turnaround.
                            </p>
                            <span>Features: </span>
                            <ul>
                                <li>Cosmetic Acrylic Cosmetic Base Display</li>
                                <li>Measures 8 1/2″ long x 8″ high x 3″ deep</li>
                                <li>Your Choice of Baking Card</li>
                                <li>Customized Printing Optional</li>
                            </ul>
                            <p>
                                <span>Purpose: </span>Used to display the brow kits with your branding and messaging/photos.
                            </p>
                            <p>Why it’s great: Choose from 6 BACKING CARD CHOICES:</p>
                            <ol>
                                <li>Elegant</li>
                                <li>Floral</li>
                                <li>Bold</li>
                                <li>Before & After</li>
                                <li>Plum</li>
                                <li>Or Submit your own!</li>
                            </ol>
                            <span>DOES NOT INCLUDE COSMETIC</span>
                            <hr/>
                            <p className=''>
                                <span>Category: </span>Prepacks
                            </p>
                            {/* <p>
                                <span>Details: </span>Completely customize the display card – your words, photos, layout, etc. We provide proof for your approval. Fast turnaround.
                            </p> */}

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BrowKit
