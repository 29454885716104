/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCmsById } from "../../service/api";
import Banners from "./components/banners";
import Bestsellers from "./components/bestsellers";
// import FestivalProducts from "./components/festival-products";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

const NewHomepage = () => {
  const text = {
    SECTION_ONE_BANNER_VIDEOS: "63bec806888fdf6e06500e2f",
    SECTION_TWO_TEXT: "63bec923888fdf6e06500e5d",
    SECTION_TWO_IMAGES: "63bec9de888fdf6e06500f1f",
    SECTION_FOUR_YOUTUBE_LINK: "63beca20888fdf6e06500f63",
    CATEGORY_SECTION_ONE: "63bfbf437f95eeb411f1f268",
  };

  const [cmsData, setcmsData] = useState([]);

  const id = "63bec7f5888fdf6e06500e25";

  useEffect(() => {
    getCmsById(id).then((res) => {
      if (res?.data) {
        setcmsData(res?.data?.sections);
      }
    });
  }, []);

  return (
    <section className="home">
      {/* BANNERS */}
      {cmsData?.map?.((data, index) => {
        if (
          data?._id
            ?.toLowerCase()
            ?.includes(text?.SECTION_ONE_BANNER_VIDEOS?.toLowerCase())
        ) {
          return (
            <Banners
              moduleData={
                data?.sectionID?.components?.length > 0
                  ? data?.sectionID?.components[0]?.modules
                  : null
              }
            />
          );
        }
      })}

      {/* BESTSELLERS */}
      <Bestsellers />

      {/* TWO CATEGORIES */}
      <section className="two-categories">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div className="single-category-box">
                <img
                  src="https://storage.googleapis.com/cdn-browbarbyreema-test/product/1674671400177.webp"
                  alt="Category"
                />
                {/* OVERLAY */}
                <div className="overlay">
                  {/* CATEGORY DETAILS */}
                  <div className="category-box-details">
                    <h4>BROW COLLECTION</h4>
                    <Link to="/products/63b710c11aff5e6328889492">
                      <Button className="link-btn">SHOP NOW</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="single-category-box">
                <img
                  src="https://storage.googleapis.com/cdn-browbarbyreema/product/1674571045067.jpg"
                  alt="Category"
                />
                {/* OVERLAY */}
                <div className="overlay">
                  {/* CATEGORY DETAILS */}
                  <div className="category-box-details">
                    <h4>FACE COLLECTION</h4>
                    <Link to="/products/63b711041aff5e63288894a9">
                      <Button className="link-btn">SHOP NOW</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FESTIVAL BOX */}
      {/* <section className="festival-section ptb-50">
          <div className="container">
            <div className="festival-box">
              <div className="row">
                <div className="col-xl-6 col-sm-12">
                  <img
                    src="https://storage.googleapis.com/cdn-browbarbyreema/product/1674600542707.webp"
                    alt="Spring Season"
                  />
                </div>
                <div className="col-xl-6 col-sm-12">
                  <div className="festival-text">
                    <div>
                      <h4>GET READY FOR SUMMER</h4>
                      <p>Enjoy 25% off on your beauty essentials.</p>
                      <p>Exclusions may apply.</p>
                      <Link to={'/shop'}>
                        <Button className="link-btn">SHOP NOW</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      {/* FESTIVAL PRODUCTS */}
      {/* <FestivalProducts /> */}

      {/* ABOUT BBR */}
      <Container fluid className="about-bbr">
        <Container>
          <Row className="about-row">
            <Col lg="5" className="image-col">
              <img src={require("../../assets/about-us/reema_khan.png")} alt="Reema Khan" className="reema-khan-img" />
            </Col>
            <Col lg="7" className="description-col">
              <div className="des-div">
                <h3>BROW BAR BY REEMA</h3>
                <p>
                  Welcome to Brow Bar by Reema! The Art of the BROW has always been Reema’s specialty. World renowned brow artist Reema Khan knows the power of the brow. A flawless brow says it all.
                </p>
                <p>
                  Reema’s luxurious, precision-like, cruelty-free brow products and glamorous looks have become the favorite of Hollywood’s finest. With 75 salons and 20 years, Reema’s known for having the most luxurious and effective brow, eyes, lip, and face makeup products in the industry.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* SHOP OUR FEED */}
      <section className="shop-feed ptb-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-sm-12">
              <h2>SHOP OUR FEED</h2>
            </div>
            <div className="col-xl-12 col-sm-12">
              <Swiper
                className="instagram-feed-videos"
                modules={[Scrollbar, Navigation, Pagination]}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 5,
                  },
                }}
                navigation={true}
                slidesPerView={5}
                spaceBetween={30}
              >
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-01.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-02.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-03.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-04.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-05.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="video-box">
                    <video muted autoPlay loop>
                      <source
                        src={require("../../assets/reels/reels-01.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default NewHomepage;